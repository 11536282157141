import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  DOMAIN,
  SHORT_NAME,
  TENANT_NAME,
  WINDOWS_DOMAIN,
} from "./tenantSignUpConstants";
import { useDispatch } from "react-redux";
import Input from "../Input";
import Button from "@material-ui/core/Button";
import CreateTenantDBConfig, { DB_CONFIG_ACTION } from "./CreateTenantDBConfig";
import { validateShortName } from "./tenantSignUpUtils";
import TenantLocation from "./TenantLocation";

const ErrorDisplay = ({ errorMessage }) =>
  errorMessage ? (
    <div style={{ color: "red", marginTop: "15px", fontSize: "12px" }}>
      {errorMessage}
    </div>
  ) : null;

const DomainInput = ({
  value,
  index,
  errorObj,
  setErrorObj,
  setDomain,
  UpdateDomainArray,
  DomainplusButtonClickHandler,
  DomainMinusButtonClickHandler,
  classes,
  inputNumber,
}) => {
  const dispatch = useDispatch();

  const handleDomainChange = (value) => {
    dispatch({ type: DOMAIN, payload: value });
    setErrorObj((prevError) => ({ ...prevError, Domain: "" }));
    setDomain(value);
    UpdateDomainArray(value, index);
  };

  return (
    <div className={classes.flex}>
      <Input
        type="text"
        labelName="Domain"
        required
        labelClassName={classes.label}
        containerName={classes.adminSignUp}
        classname={classes.domain}
        value={value}
        eventHandler={handleDomainChange}
      />
      {index === inputNumber.length - 1 && (
        <Button
          variant="contained"
          color="primary"
          className={classes.plus}
          onClick={DomainplusButtonClickHandler}
        >
          +
        </Button>
      )}
      {index !== 0 && (
        <Button
          variant="contained"
          color="primary"
          className={classes.plus}
          onClick={() => DomainMinusButtonClickHandler(index)}
        >
          -
        </Button>
      )}
    </div>
  );
};

const TenantDetails = ({
  classes,
  tenantSignUpObj,
  errorObj,
  setErrorObj,
  inputNumber,
  domainsArr,
  setDomain,
  UpdateDomainArray,
  DomainplusButtonClickHandler,
  DomainMinusButtonClickHandler,
  mdr,
  disableDBConfig,
  imageHandler,
  inputFile,
  onButtonClick,
  file,
  shortNameRef,
  resetPasswordMail,
  tenantId
}) => {
  const dispatch = useDispatch();

  const handleInputChange = (type, value) => {
    setErrorObj((prevError) => ({ ...prevError, tenantName: "" }));
    dispatch({ type, payload: value });
  };

  return (
    <Grid item md={4} sm={4} className="tenantSignUpGridContainer">
      <div className="tenantSignUpGridChildContainer">
        <h1 className="gridHeaders tenantSignUp">Tenant Details</h1>
        <hr className="pageSeparator" />
        <div className="tenantSignUpContainer">
          <Input
            type="text"
            required
            labelName="Tenant Name"
            labelClassName={classes.label}
            value={tenantSignUpObj.tenantName}
            containerName="signUpconatainer platFormSignUp"
            classname={classes.input}
            inputProps={{ maxLength: 3, minLength: 3 }}
            eventHandler={(value) => handleInputChange(TENANT_NAME, value)}
          />
          <ErrorDisplay errorMessage={errorObj.tenantName} />

          {inputNumber.map((val, index) => (
            <DomainInput
              key={index}
              value={domainsArr[index] && domainsArr[index].name ? domainsArr[index].name : domainsArr[index]}
              index={index}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              setDomain={setDomain}
              UpdateDomainArray={UpdateDomainArray}
              DomainplusButtonClickHandler={DomainplusButtonClickHandler}
              DomainMinusButtonClickHandler={DomainMinusButtonClickHandler}
              classes={classes}
              inputNumber={inputNumber}
            />
          ))}
          <ErrorDisplay errorMessage={errorObj.Domain} />

          {mdr !== "" && (
            <Input
              type="text"
              labelName="Short Name"
              required
              labelClassName={classes.label}
              containerName="signUpconatainer platFormSignUp"
              value={tenantSignUpObj.shortName}
              classname={classes.input}
              eventHandler={(value) => {
                validateShortName(value, shortNameRef, errorObj, setErrorObj, resetPasswordMail);
                handleInputChange(SHORT_NAME, value);
              }}
            />
          )}
          <ErrorDisplay errorMessage={errorObj.shortName} />

          <Input
            type="text"
            labelName="Windows Domain"
            labelClassName={classes.label}
            containerName="signUpconatainer platFormSignUp"
            value={tenantSignUpObj.windows_domain}
            classname={classes.input}
            eventHandler={(value) => handleInputChange(WINDOWS_DOMAIN, value)}
          />
          
          <TenantLocation errorObj={errorObj} setErrorObj={setErrorObj} tenantId={tenantId}/>

          <CreateTenantDBConfig
            disableDBConfig={disableDBConfig}
            currentConfig={tenantSignUpObj}
            dispatcher={(payload) =>
              dispatch({ type: DB_CONFIG_ACTION, payload })
            }
            setErrorObj={setErrorObj}
            errorObj={errorObj}
          />

          <p style={{ marginTop: "60px" }}>Tenant Logo</p>
          <div className="imageUploadContainer">
            <input
              type="file"
              id="img"
              name="img"
              accept="image/*"
              onChange={imageHandler}
              ref={inputFile}
              className="img"
            />
            <button type="file" className="imgButton" onClick={onButtonClick}>
              Browse
            </button>
            {file && (
              <img
                className="signUp_uploadedImage"
                src={file}
                alt="Preview"
                onClick={onButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default TenantDetails;
