import React, { useState, useRef, useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./platformTenantSignUp.scss";
import { useParams } from "react-router-dom";
import SignupPageBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/scss/signuppage-styles/TenantSignUp.scss";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import SignUpPageFooter from "../signUpPages/SignUpPageFooter";
import { getTenantDetails } from "../../../redux/actions/getTenantDetailsActions";
import resetPasswordIcon from "../../../assets/vizr_images/Reset_Password-delete.svg";
import {
  ACTIVATE_ACCOUNT_RESET_CLICK,
  GET_FEATURES,
  GET_LICENSES,
  PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE,
  LOGO_FORMAT_ERROR_MESSAGE,
  LOGO_SIZE_ERROR_MESSAGE,
  TENANT_LOGO_SIZE,
  RISK,
  SERVER_ERROR_MESSAGE,
  NETSPACE,
} from "../../../redux/constants/constants";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import { SendLink } from "../../common/buttons";
import { filter, forEach, isEmpty, isNull } from "lodash";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { MDR, DDV } from "../../../redux/constants/constants";
import Toggler from "../../shared/Toggler";
import { ViewTenantConcentratorDetails } from "../MdrConcentrator/viewConcentrator/ViewMdrConcentratorDetails";
import styled from "styled-components";
import MuiGrid from "@material-ui/core/Grid";
import { DB_CONFIG_ACTION } from "./CreateTenantDBConfig";
import {
  ADMIN_EMAIL,
  CLEAR_TENANT_DATA,
  DOMAIN,
  FEATURES_DATA,
  FIRST_NAME,
  LAST_NAME,
  LICENSE_TYPE_DATA,
  LOADED,
  LOGO,
  PLATFORM_SUBDOMAIN_NAME,
  S3DETAILS,
  SELECTED_FEATURES,
  SET_SELECTED_TENANT_REGION,
  SHORT_NAME,
  TENANT_NAME,
  WINDOWS_DOMAIN,
} from "./tenantSignUpConstants";
import SignUpMessages from "./SignUpMessages";
import TenantDetails from "./TenantDetails";
import FeaturesAndDetails from "./FeaturesAndDetails";
import TenantAdmin from "./TenantAdmin";
import { getFeatureName } from "./tenantSignUpUtils";

const useStyles = makeStyles((theme) => ({
  signUpGrid_container: {
    marginLeft: "12.5rem",
    height: "100%",
    borderRadius: "0.2rem",
    paddingLeft: "1rem",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    width: "auto",
  },
  label: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  label2: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
  },
  input: {
    width: "100%",
    marginTop: "5px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
  },
  domain: {
    width: "268px",
    minWidth: "50%",
    maxWidth: "100%",
    marginTop: "5px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
    position: "relative",
  },
  domain2: {
    width: "200px",
    marginTop: "5px",
    boxSizing: "border-box",
    height: "32px",
    border: "1px solid #DFE1E9",
    borderRadius: "4px",
    backgroundColor: " #FFFFFF",
    position: "relative",
  },
  plus: {
    height: "32px",
    width: "32px",
    minWidth: "36px !important",
    borderRadius: "4px !important",
    backgroundColor: "#2947FD !important",
    boxShadow: "0 5px 8px -3px rgba(41,71,253,0.5) !important",
    marginTop: "45px",
    marginLeft: "8px",
    fontSize: "1.7rem",
  },
  plus2: {
    height: "32px !important",
    minWidth: "42px !important",
    borderRadius: "4px !important",
    backgroundColor: "#2947FD !important",
    boxShadow: "0 5px 8px -3px rgba(41,71,253,0.5) !important",
    padding: "10px !important",
    position: "absolute",
    left: "19.5rem",
    top: "20rem",
  },
  tenenantsignup_select_container: {
    marginTop: "1rem",
    postion: "relative",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  adminSignUp: {
    marginTop: "20px",
  },
  selectionLabel: {
    height: "20px",
    width: "8rem",
    color: "#292929",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "20px",
    marginBottom: "0px",
    marginTop: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Grids = styled(MuiGrid)(({ theme }) => ({
  ...theme.typography.body2,
  '& [role="separator"]': {
    margin: theme.spacing(0, 2),
  },
}));

const TenenantSignUp = (props) => {
  const inputFile = useRef(null);
  const classes = useStyles();
  const tenantSignUpObj = useSelector((state) => state.tenantSignUpReducer);
  const tenantSignUpLoader = useSelector((state) => state.tenantSignUpReducer.tenantSignUpLoader);
  const queryParam = useParams();
  const dispatch = useDispatch();
  const [inputNumber, setInputNumber] = useState([1]);
  const [file, setFile] = useState(null);
  const [fileUpload, setfileUpload] = useState("");
  const [signUpactive, makeSignUpAcitive] = useState(false);
  const [privacyPolicy, setprivacyPolicy] = useState(false);
  const [license, setLicense] = useState(false);
  const [isCreate, setIsCreate] = useState(
    window.location.href.indexOf("createTenant") > -1
  );
  const [tenantId, setTenantId] = useState("");
  const [s3ResponseData, setS3ResponseData] = useState(null);
  const [domain, setDomain] = useState("");
  const [domainsArr, setDomainsArr] = useState([]);
  const [shortNameRef, setShortname] = useState("");
  const [mdr, setMdr] = useState("");
  const [errorObj, setErrorObj] = useState({
    tenantName: "",
    Domain: "",
    email: "",
    firstName: "",
    lastName: "",
    shortName: "",
    Host_error: "",
    Username_error: "",
    Password_error: "",
    s3BucketName: "",
    accessKey: "",
    secretKey: "",
    tenantRegion: ""
  });
  const [resetPasswordMail, setresetPasswordMail] = useState("");
  const [passwordToggler, setpasswordToggler] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disableDBConfig, setDisableDBConfig] = useState(false);

  const resetPasswordHandler = () => {
    setpasswordToggler((prevState) => !prevState);
  };
  const [togglers, setTogglers] = useState([
    { name: "Data Vision", state: "active" },
    { name: "Threat Insights", state: "inactive" },
  ]);
  const [logoErrorMessage, setLogoErrorMesage] = useState("");
  let name;
  let conditionFeature = "";
  let routFrom = "";
  let platformSubdomainNameFrom = "";
  if (props.location.state) {
    name = props.location.state.name;
    conditionFeature = props.location.state.features.length ? "exist" : "";
    routFrom = props.location.state.routFrom || "";
    platformSubdomainNameFrom =
      props.location.state.platformSubdomainName || "";
  }

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_TENANT_DATA });
    };
  }, []);


  const resetPassword = () => {
    sendActivationEmail();
  };

  const UpdateDomainArray = (value, index) => {
    let arr = domainsArr;
    arr[index] = value;
    setDomainsArr([...arr]);
  };

  useEffect(() => {
    if (tenantSignUpLoader) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  },[tenantSignUpLoader])

  const DomainplusButtonClickHandler = () => {
    setInputNumber((inputNumber) => [...inputNumber, 1]);
    dispatch({ type: DOMAIN, payload: domain });
  };

  const DomainMinusButtonClickHandler = (index) => {
    let inputNumberArr = inputNumber;
    let arr = domainsArr;
    arr.splice(index, 1);
    inputNumberArr.pop();
    setInputNumber([...inputNumberArr]);
    setDomainsArr([...arr]);
  };

  useEffect(() => {
    if (props.location.state) {
      name = props.location.state.name;
      conditionFeature = props.location.state.features.length ? "exist" : "";
      if (props.location.state.routFrom) {
        setTogglers([
          { name: "Data Vision", state: "inactive" },
          { name: "Threat Insights", state: "active" },
        ]);
      }
    }
    async function getFeaturesAndLicence() {
      let response;
      let responseData;
      await Promise.all([
        getService({
          method: "GET",
          url: `${GET_FEATURES}`,
          headers: getAxiosHeaders(true),
        }),
        getService({
          method: "GET",
          url: `${GET_LICENSES}`,
          headers: getAxiosHeaders(true),
        }),
      ]).then((data) => ((response = data[0]), (responseData = data[1])));
      if (response.status === 200 && responseData.status === 200) {
        dispatch({
          type: FEATURES_DATA,
          payload: response.data.content,
        });
        dispatch({
          type: LICENSE_TYPE_DATA,
          payload: responseData.data.content,
        });
        dispatch({
          type: SELECTED_FEATURES,
          payload: response.data.content.map((feature) => ({
            licenseTypeId: "None",
            featureId: feature.id
          })),
        });
        dispatch({
          type: LOADED,
          payload: true,
        });
      }
    }
    getFeaturesAndLicence();
    setLoader(true);
  }, []);

  useEffect(() => {
    if (
      queryParam.id !== "createTenant" &&
      queryParam.id !== undefined &&
      tenantSignUpObj.loaded
    ) {
      setDisableDBConfig(true);
      getDetails();
    } else {
      setLoader(false);
    }
  }, [tenantSignUpObj.loaded]);

  const setDefaultValues = (feature) => {
    let index;
    forEach(tenantSignUpObj.selectedFeatures, (item, id) => {
      if (item.featureId === feature.id) {
        index = id;
        return;
      }
    });
    let data = tenantSignUpObj.selectedFeatures;
    if (index !== undefined) {
      if (feature.name === MDR) {
        setMdr(feature.licenseType.id);
      }
      data[index].licenseTypeId = feature.licenseType.id;
      dispatch({
        type: SELECTED_FEATURES,
        payload: data,
      });
    }
  };

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleBackdrop = () => {
    setOpenBackdrop(false);
  };

  async function getDetails() {
    const res = await getTenantDetails(queryParam.id);
    let data = tenantSignUpObj.selectedFeatures;
    if (res && res.id) {
      setTenantId(res.id);
      setLoader(false);
      res.features.map((featureData) => setDefaultValues(featureData));
      dispatch({ type: TENANT_NAME, payload: res.name });
      dispatch({ type: FIRST_NAME, payload: res.administrator.firstName });
      dispatch({ type: LAST_NAME, payload: res.administrator.lastName });
      dispatch({ type: ADMIN_EMAIL, payload: res.administrator.email });
      if (!isNull(res.tenantS3Configuration)) {
        setS3ResponseData(res.tenantS3Configuration);
        dispatch({
          type: S3DETAILS,
          payload: {
            s3BucketName: res.tenantS3Configuration.bucketName,
            accessKey: res.tenantS3Configuration.accessKey,
            secretKey: res.tenantS3Configuration.secretKey,
            isNTFSSelected: true,
          },
        });
      }
      dispatch({ type: SET_SELECTED_TENANT_REGION, payload: res.timezone })
      dispatch({
        type: DB_CONFIG_ACTION,
        payload: res,
      });
      if (res.windowsDomain) {
        dispatch({ type: WINDOWS_DOMAIN, payload: res.windowsDomain });
      }
      if (res.shortName) {
        setShortname(res.shortName);
        dispatch({ type: SHORT_NAME, payload: res.shortName });
      }
      dispatch({
        type: PLATFORM_SUBDOMAIN_NAME,
        payload: res.platformSubdomainName,
      });
      if (!isEmpty(res.logo)) {
        // Your base64 string
        const base64String = res.logo;
        //creates a data URI for the image using the 'base64String'
        const imgSrc = `data:image/png;base64,${base64String}`;
        setFile(imgSrc);
        dispatch({
          type: LOGO,
          payload: res.logo,
        });
      }
      setresetPasswordMail(res.administrator.email);

      if (
        res.platformSubdomainName !== "" &&
        res.domains.length &&
        res.domains[0].name
      ) {
        if (tenantSignUpObj.domain.indexOf(res.domains[0].name) === -1) {
          dispatch({ type: DOMAIN, payload: res.domains[0].name });
          let arr = [...res.domains];
          setDomainsArr([...arr]);
        } else {
          dispatch({ type: DOMAIN, payload: res.platformSubdomainName });
          let arr = [res.platformSubdomainName];
          setDomainsArr([...arr]);
        }
      }
    }
  }

  const [errorObject, setErrorMessage] = useState({
    errorMessage: "",
    successMessage: "",
  });

  //used for error handling and closing the backdrop
  const tenantCreation = useSelector(
    (state) => state.tenantCreate.tenantCreation
  );

  useEffect(() => {
    setOpenBackdrop(false);
    if (tenantCreation.errorMessage) {
      let obj = {
        errorMessage: tenantCreation.errorMessage,
        successMessage: "",
      };
      setErrorMessage(obj);
      dispatch({ type: PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE });
    }
  }, [tenantCreation]);

  const sendActivationEmail = async () => {
    setErrorMessage({
      errorMessage: "",
      successMessage: "",
    });
    const res = await getService({
      method: "POST",
      url: ACTIVATE_ACCOUNT_RESET_CLICK(tenantId),
      headers: getAxiosHeaders(true),
      data: {
        email: resetPasswordMail,
      },
    });
    if (res && res.status === 200) {
      let obj = {
        successMessage: "Password link has been sent successfully.",
        errorMessage: "",
      };
      setErrorMessage({
        ...obj,
      });
    } else {
      let obj = {
        successMessage: "",
        errorMessage: res.response?.data?.errorMessage || SERVER_ERROR_MESSAGE,
      };
      setErrorMessage({
        ...obj,
      });
    }
  };

  const onButtonClick = (event) => {
    event.preventDefault();
    inputFile.current.click();
    handleSnackbarClose();
  };
  const imageHandler = (event) => {
    const requiredFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (event.target.files[0]) {
      if (event.target.files[0].size / 1000 > TENANT_LOGO_SIZE) {
        setLogoErrorMesage(LOGO_SIZE_ERROR_MESSAGE);
        setopenError(true);
      } else if (!requiredFileTypes.includes(event.target.files[0].type)) {
        setLogoErrorMesage(LOGO_FORMAT_ERROR_MESSAGE);
        setopenError(true);
      } else {
        setFile(URL.createObjectURL(event.target.files[0]));
        setfileUpload(event.target.files[0]);
      }
    }
  };

  const [commonDomain, setCommonDomain] = useState("");
  const setDomainName = (name) => {
    if (name) {
      const arr = name.split(".");
      const domain1 = arr[arr.length - 2];
      const domain2 = arr[arr.length - 1];
      if (domain1 && domain2) {
        setCommonDomain(domain1 + "." + domain2);
      } else if (domain1 && !domain2) {
        setCommonDomain(domain1);
      } else if (!domain1 && domain2) {
        setCommonDomain("." + domain2);
      }
    } else {
      setCommonDomain("");
    }
  };

  const [openError, setopenError] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLogoErrorMesage("");
    setopenError(false);
  };

  const handleFeatures = (event, feature) => {
    let index;
    forEach(tenantSignUpObj.selectedFeatures, (item, id) => {
      if (item.featureId === feature.id.id) {
        index = id;
        return;
      }
    });
    if (feature.id.name === MDR) {
      if (event.target.value !== "None") {
        setMdr(event.target.value);
      } else {
        setMdr("");
        setErrorObj({
          ...errorObj,
          shortName: "",
        });
      }
    }
    let data = tenantSignUpObj.selectedFeatures;
    data[index].licenseTypeId = event.target.value;
    dispatch({
      type: SELECTED_FEATURES,
      payload: data,
    });
  };

  const renderFeatures = (features, index) => {
    return (
      <>
        <h3 className={classes.selectionLabel}>
          {getFeatureName(features.name)}
        </h3>
        <select
          className="tenenantsignup_select"
          value={
            (tenantSignUpObj.selectedFeatures[index] &&
              tenantSignUpObj.selectedFeatures[index].licenseTypeId) ||
            "None"
          }
          onChange={(event) => handleFeatures(event, { id: features })}
        >
          {tenantSignUpObj.licenceTypeData.map((licenceData) => {
            if (licenceData.name === "None") {
              return <option value="None">{licenceData.name}</option>;
            } else {
              return <option value={licenceData.id}>{licenceData.name}</option>;
            }
          })}
        </select>
      </>
    );
  };

  const noFeaturesSelected = (relation) => {
    const message =
      relation === "create"
        ? "please select any feature to create tenant"
        : "please select any feature to update tenant";
    let obj = {
      successMessage: "",
      errorMessage: message,
    };
    setErrorMessage({
      ...obj,
    });
  };

  const handleToggle = () => {};

  return (
    <div>
      <SignUpMessages
        openError={openError}
        handleSnackbarClose={handleSnackbarClose}
        logoErrorMessage={logoErrorMessage}
        classes={classes}
        openBackdrop={openBackdrop}
        handleBackdrop={handleBackdrop}
        errorObject={errorObject}
      />
      <div
        className={privacyPolicy || license ? "signupPageBlur" : "signUpPage"}
      >
        {loader && (
          <div className="global-loader-holder">
            <img src={Page_Loader} alt="_Loader" className="loader" />
          </div>
        )}
        <SignupPageBackGround
          headerName="Tenant Management"
          header={tenantId ? tenantSignUpObj.tenantName : "Tenant Onboarding"}
          breadcrumbValue={
            tenantId !== ""
              ? [
                  {
                    value: "Tenant Management",
                    link: "/platform/tenantManagement",
                  },
                  { value: "Edit", link: "" },
                ]
              : isCreate
              ? [
                  {
                    value: "Tenant Management",
                    link: "/platform/tenantManagement",
                  },
                  { value: "Create Tenant", link: "" },
                ]
              : ""
          }
        ></SignupPageBackGround>
        <form>
          <div className="tenantSignUpParentContainer">
            {isCreate === false && conditionFeature !== "" && (
              <Toggler
                togglers={togglers}
                setTogglers={setTogglers}
                handleToggle={handleToggle}
              ></Toggler>
            )}
          </div>
          {togglers[0].state === "active" && (
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.signUpGrid_container}
            >
              <TenantDetails
                classes={classes}
                tenantSignUpObj={tenantSignUpObj}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                inputNumber={inputNumber}
                domainsArr={domainsArr}
                setDomain={setDomain}
                UpdateDomainArray={UpdateDomainArray}
                DomainplusButtonClickHandler={DomainplusButtonClickHandler}
                DomainMinusButtonClickHandler={DomainMinusButtonClickHandler}
                mdr={mdr}
                disableDBConfig={disableDBConfig}
                imageHandler={imageHandler}
                inputFile={inputFile}
                onButtonClick={onButtonClick}
                file={file}
                shortNameRef={shortNameRef}
                resetPasswordMail={resetPasswordMail}
                tenantId={tenantId}
              />
              <FeaturesAndDetails
                classes={classes}
                tenantSignUpObj={tenantSignUpObj}
                setErrorObj={setErrorObj}
                errorObj={errorObj}
                renderFeatures={renderFeatures}
                s3ResponseData={s3ResponseData}
              />
              <TenantAdmin
                classes={classes}
                tenantSignUpObj={tenantSignUpObj}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                resetPassword={resetPassword}
                resetPasswordHandler={resetPasswordHandler}
                passwordToggler={passwordToggler}
                resetPasswordMail={resetPasswordMail}
                setresetPasswordMail={setresetPasswordMail}
                resetPasswordIcon={resetPasswordIcon}
              />
              <SignUpPageFooter
                handleBackdrop={handleBackdrop}
                classname="tenantSignUpFooter"
                popClassName="tenantPopup"
                platformAdminTenantOnBoard={isCreate}
                signUpObj={{
                  ...tenantSignUpObj,
                  features: filter(tenantSignUpObj.selectedFeatures, (item) => {
                    if (item.licenseTypeId !== "None") {
                      return item;
                    }
                  }),
                }}
                signUpactive={signUpactive}
                makeSignUpAcitive={makeSignUpAcitive}
                privacyPolicy={privacyPolicy}
                setprivacyPolicy={setprivacyPolicy}
                license={license}
                setLicense={setLicense}
                updateTenantId={tenantId}
                fileUpload={fileUpload}
                commonDomain={commonDomain}
                domainsArr={domainsArr}
                createTenant
                setErrorObj={setErrorObj}
                errorObj={errorObj}
                resetPasswordMail={resetPasswordMail}
                passwordToggler={passwordToggler}
                noFeaturesSelected={noFeaturesSelected}
                mdr={mdr}
                setOpenBackdrop={setOpenBackdrop}
              ></SignUpPageFooter>
            </Grid>
          )}
        </form>
      </div>
      {togglers[0].state === "active" && (
        <div
          className={
            privacyPolicy || license
              ? "signupPageBlur-visible"
              : "signupPageBlur-hidden"
          }
        ></div>
      )}
      {togglers[1].state === "active" && (
        <ViewTenantConcentratorDetails
          id={queryParam.id}
          name={name}
          routFrom={routFrom}
          platformSubdomainNameFrom={platformSubdomainNameFrom}
        ></ViewTenantConcentratorDetails>
      )}
    </div>
  );
};

export default TenenantSignUp;