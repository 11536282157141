import { call, put, takeEvery } from "redux-saga/effects";
import { TENANTSIGNUPPAGE_SUBMIT } from "../constants/TenantSignUpTypes";
import { ApiCall } from "../../../src/components/views/Util/ApiCall";
import {
  TenantSignUpPage_Successful,

  // TenantSIGNUPPAGE_Reset,
  // TENANTSIGNUPPAGE_Submit,
} from "../../../src/redux/actions/TenantSignUpAction";

import { TENANT_SIGN_UP_URL } from "../constants/constants";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import { GET_TENANT_REGIONS, getAllTenantRegionsAPI, SET_ALL_TENANT_REGIONS, SET_NTFS_SNACKBAR_MESSAGE, SET_SELECTED_TENANT_REGION, TENANT_SIGNUP_LOADER } from "../../components/views/TenantSignUp/tenantSignUpConstants";

function* APICALL2(action) {
  const apiCall2 = yield call(() =>
    ApiCall({
      method: "POST",
      url: TENANT_SIGN_UP_URL,
      // url: "http://4c9239663575.ngrok.io/platform/tenants",
      data: action.payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
  // console.log(apiCall2);
  let out;
  if (apiCall2.status !== 201) {
    out = JSON.parse(apiCall2);
    console.log(out.message);
  }
  // console.log(JSON.parse(apiCall2));

  if (apiCall2 && apiCall2.status === 201) {
    // console.log(apiCall2.status);
    alert("Tenant SignUp Successful");
    yield put(TenantSignUpPage_Successful());
    console.log("tenantSignUpSuccessful");
  } else if (out.message === "Request failed with status code 500") {
    alert("User Already Exists");
  } else if (out.message === "Request failed with status code 400") {
    alert("Please fill the Fields");
  } else {
    alert("Please fill all the fields");
  }
}

function* getAllTenantRegions() {
  const errorMessage = "Unable to fetch the Regions, Please try again";
  yield put({ type: TENANT_SIGNUP_LOADER, payload: true });
  const options = {
    method: "GET",
    url: getAllTenantRegionsAPI,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const regions = res.data?.content || [];
      yield put({ type: TENANT_SIGNUP_LOADER, payload: false });
      yield put({ type: SET_ALL_TENANT_REGIONS, payload: regions });
      yield put({ type: SET_SELECTED_TENANT_REGION, payload: regions[0]})
    } else {
      yield put({ type: TENANT_SIGNUP_LOADER, payload: false });
      yield put({ type: SET_NTFS_SNACKBAR_MESSAGE, payload: errorMessage });
    }
  } catch (err) {
    yield put({ type: TENANT_SIGNUP_LOADER, payload: false });
    yield put({ type: SET_NTFS_SNACKBAR_MESSAGE, payload: errorMessage });
  }
}

export function* TenanantsignUpUser(action) {
  yield console.log("Hello from TenantSignUpSaga");
  yield APICALL2(action);
}

export function* TenantSignUpSaga() {
  yield takeEvery(TENANTSIGNUPPAGE_SUBMIT, TenanantsignUpUser);
  yield takeEvery(GET_TENANT_REGIONS, getAllTenantRegions);
}