import { domain_vizr } from "../../../redux/constants/constants";

export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const ADMIN_NAME = "ADMIN_NAME";
export const ADMIN_EMAIL = "ADMIN_EMAIL";
export const TENANT_NAME = "TENANT_NAME";
export const DOMAIN = "DOMAIN";
export const DOMAIN_INIT = "DOMAIN_INIT";
export const LICENSE_TYPES = "LICENSE_TYPES";
export const LICENSE_TYPES_INIT = "LICENSE_TYPES_INIT";
export const PLATFORM_SUBDOMAIN_NAME = "PLATFORM_SUBDOMAIN_NAME";
export const LOGO = "LOGO";
export const MONITOR_VISION = "MONITOR_VISION";
export const CONTROL_SHIELD = "CONTROL_SHIELD";
export const WINDOWS_DOMAIN = "WINDOWS_DOMAIN";
export const SHORT_NAME = "SHORT_NAME";
export const FEATURES_DATA = "FEATURES_DATA";
export const LICENSE_TYPE_DATA = "LICENSE_TYPE_DATA";
export const SELECTED_FEATURES = "SELECTED_FEATURES";
export const LOADED = "LOADED";
export const PROVISION_DB_TYPE = "PROVISION_DB_TYPE";
export const DATABASE_CONFIGURATION = "DATABASE_CONFIGURATION";
export const HOST = "HOST";
export const PORT = "PORT";
export const USERNAME = "USERNAME";
export const PASSWORD = "PASSWORD";
export const INSTANCE_ID = "INSTANCE_ID";
export const SCHEMA = "SCHEMA";
export const NAME = "NAME";
export const S3DETAILS = "S3DETAILS";
export const S3Verified = "S3VERIFIED";
export const S3VerificationApi = `${domain_vizr}/platform/tenants/testS3Connection`;
export const VERIFYS3DATA = "VERIFYS3DATA";
export const SET_NTFS_SNACKBAR_MESSAGE = "SET_NTFS_SNACKBAR_MESSAGE";
export const TENANT_SIGNUP_LOADER = "TENANT_SIGNUP_LOADER";
export const CLEAR_TENANT_DATA = "CLEAR_TENANT_DATA";
export const GET_TENANT_REGIONS = "GET_TENANT_REGIONS";
export const SET_ALL_TENANT_REGIONS = "SET_ALL_TENANT_REGIONS";
export const getAllTenantRegionsAPI = `${domain_vizr}/platform/lookups/tenantTimezone`;
export const SET_SELECTED_TENANT_REGION = "SET_SELECTED_TENANT_REGION";