import { DB_CONFIG_ACTION, DB_MANUAL } from "./CreateTenantDBConfig";
import {
  ADMIN_EMAIL,
  ADMIN_NAME,
  CLEAR_TENANT_DATA,
  DOMAIN,
  DOMAIN_INIT,
  FEATURES_DATA,
  FIRST_NAME,
  LAST_NAME,
  LICENSE_TYPE_DATA,
  LICENSE_TYPES,
  LICENSE_TYPES_INIT,
  LOADED,
  LOGO,
  PLATFORM_SUBDOMAIN_NAME,
  S3DETAILS,
  S3Verified,
  SELECTED_FEATURES,
  SET_ALL_TENANT_REGIONS,
  SET_NTFS_SNACKBAR_MESSAGE,
  SET_SELECTED_TENANT_REGION,
  SHORT_NAME,
  TENANT_NAME,
  TENANT_SIGNUP_LOADER,
  WINDOWS_DOMAIN,
} from "./tenantSignUpConstants";

const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  adminName: "",
  adminEmail: "",
  tenantName: "",
  domain: [],
  licenseTypes: [],
  platformSubdomainName: "",
  logo: "", //for tenant logos's
  monitorVision: "Silver(500)",
  controlShield: "Silver(500)",
  windows_domain: "",
  shortName: "",
  featuresData: [],
  licenceTypeData: [],
  selectedFeatures: [],
  loaded: false,
  provisionDbType: "NEW_RDS",
  databaseConfiguration: {},
  host: "",
  port: "",
  username: "",
  password: "",
  instanceId: "",
  schema: "",
  name: "",
  s3Details: {
    isNTFSSelected: false,
    s3BucketName: "",
    accessKey: "",
    secretKey: "",
  },
  isS3Verified: false,
  ntfsSnackBarMessage: "",
  tenantSignUpLoader: false,
  tenantRegions: [],
  tenantSelectedRegion: "",
};

export const tenantSignUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };

    case ADMIN_NAME:
      return {
        ...state,
        adminName: action.payload,
      };
    case ADMIN_EMAIL:
      return {
        ...state,
        adminEmail: action.payload,
      };
    case TENANT_NAME:
      return {
        ...state,
        tenantName: action.payload,
      };
    case DOMAIN:
      return {
        ...state,
        domain: [...state.domain, action.payload],
      };
    case DOMAIN_INIT:
      return {
        ...state,
        domain: action.payload,
      };
    case LICENSE_TYPES:
      return {
        ...state,
        licenseTypes: [...state.licenseTypes, action.payload],
      };
    case LICENSE_TYPES_INIT:
      return {
        ...state,
        licenseTypes: action.payload,
      };
    case PLATFORM_SUBDOMAIN_NAME:
      return {
        ...state,
        platformSubdomainName: action.payload,
      };
    case LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case SHORT_NAME:
      return {
        ...state,
        shortName: action.payload,
      };
    case WINDOWS_DOMAIN:
      return {
        ...state,
        windows_domain: action.payload,
      };
    case FEATURES_DATA:
      return {
        ...state,
        featuresData: action.payload,
      };
    case LICENSE_TYPE_DATA:
      return {
        ...state,
        licenceTypeData: action.payload,
      };
    case SELECTED_FEATURES:
      return {
        ...state,
        selectedFeatures: action.payload,
      };
    case LOADED:
      return {
        ...state,
        loaded: action.payload,
      };
    case SET_ALL_TENANT_REGIONS: {
      return {
        ...state,
        tenantRegions: action.payload
      }
    }
    case SET_SELECTED_TENANT_REGION: {
      return {
        ...state,
        tenantSelectedRegion: action.payload
      }
    }
    case S3DETAILS:
      return {
        ...state,
        s3Details: {
          s3BucketName: action.payload.s3BucketName,
          accessKey: action.payload.accessKey,
          secretKey: action.payload.secretKey,
          isNTFSSelected: action.payload.isNTFSSelected,
        },
      };
    case S3Verified: {
      return {
        ...state,
        isS3Verified: action.payload,
      };
    }
    case DB_CONFIG_ACTION:
      if (action.payload.provisionDbType === DB_MANUAL) {
        return {
          ...state,
          provisionDbType: action.payload.provisionDbType,
          databaseConfiguration: {
            ...state.databaseConfiguration,
            ...action.payload.databaseConfiguration,
          },
        };
      } else {
        // reset db config object if any other db type other than manual is selected
        return {
          ...state,
          provisionDbType: action.payload.provisionDbType,
          databaseConfiguration: {},
        };
      }
    case SET_NTFS_SNACKBAR_MESSAGE: {
      return {
        ...state,
        ntfsSnackBarMessage: action.payload,
      };
    }
    case TENANT_SIGNUP_LOADER: {
      return {
        ...state,
        tenantSignUpLoader: action.payload,
      };
    }
    case CLEAR_TENANT_DATA: {
      return {
        ...state,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        adminName: "",
        adminEmail: "",
        tenantName: "",
        domain: [],
        licenseTypes: [],
        platformSubdomainName: "",
        logo: "", //for tenant logos's
        monitorVision: "Silver(500)",
        controlShield: "Silver(500)",
        windows_domain: "",
        shortName: "",
        featuresData: [],
        licenceTypeData: [],
        selectedFeatures: [],
        loaded: false,
        provisionDbType: "NEW_RDS",
        databaseConfiguration: {},
        host: "",
        port: "",
        username: "",
        password: "",
        instanceId: "",
        schema: "",
        name: "",
        s3Details: {
          isNTFSSelected: false,
          s3BucketName: "",
          accessKey: "",
          secretKey: "",
        },
        isS3Verified: false,
        ntfsSnackBarMessage: "",
        tenantSignUpLoader: false,
        tenantRegions: [],
        tenantSelectedRegion: "",
      };
    }
    default:
      return state;
  }
};
