import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import { FETCH_ALL_FIELDS_DATA_REQUEST, UPDATE_SENSITIVITY_INFO_CATEGORY } from "../../../redux/constants/constants";
import { MenuItem } from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useDispatch } from "react-redux";
import { saveEditSensitivity } from "../../../redux/actions/editSensitivityAction";
import { renderSensitivityValue } from "../../common/utilities";
import CanAllow from "../CanAllow";
import { ColorButtonForToolBar } from "../../common/buttons";

const severities = [
  { value: "HIGH", display: "HIGH", className: "HIGH" },
  { value: "MEDIUM", display: "MEDIUM", className: "MEDIUM" },
  { value: "LOW", display: "LOW", className: "LOW" },
  { value: "NOT_PROCESSED", display: "NON_CLASSIFIED", className: "NOT_PROCESSED" },
];

const FileSensitivity = ({ filePopUpData }) => {
  const [sensitivity, setSensitivity] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const handleSaveEditSensitivity = () => {
    const requestData = {
      sensitiveFieldsCategories: [{
        id: filePopUpData["Contents.sensitivityId"],
        categoryCode: sensitivity,
      }],
    };
    dispatch(saveEditSensitivity({ data: requestData }));
    dispatch({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
    dispatch({ type: "CLOSE_FILE_POP_UP" });
  };

  const renderEditMode = () => (
    <div className="sensitivity-field">
      <div className="severity-field">
        <span className="field-header">SENSITIVITY TYPE:</span>
        <Select
          value={sensitivity || filePopUpData["Contents.category"]}
          onChange={(e) => setSensitivity(e.target.value)}
          className="InputSelectField file-pop-up"
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
        >
          {severities.map(({ value, display, className }) => (
            <MenuItem key={value} className="severity-selection" value={value}>
              <div className={`inspect-file-severity file-pop-up ${className}`}>
                {display}
              </div>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="severity-field save-buttons">
        <ColorButtonForToolBar
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSaveEditSensitivity}
          disabled={!sensitivity || sensitivity === filePopUpData["Contents.category"]}
          className="min-margin"
        >
          Save
        </ColorButtonForToolBar>
        <ColorButtonForToolBar
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            setIsEditMode(false);
            setSensitivity("");
          }}
          className="min-margin"
        >
          Cancel
        </ColorButtonForToolBar>
      </div>
    </div>
  );

  const renderViewMode = () => (
    <div className="sensitivity-field">
      <div className="severity-field">
        <span className="field-header">SENSITIVITY TYPE:</span>
        <div className={`inspect-file-severity ${filePopUpData["Contents.category"]}`}>
          {renderSensitivityValue(filePopUpData["Contents.category"])}
        </div>
      </div>
      <CanAllow needs={{ permission: [UPDATE_SENSITIVITY_INFO_CATEGORY] }}>
        <ColorButtonForToolBar
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setIsEditMode(true)}
          className="min-margin"
        >
          <EditOutlinedIcon /> Edit Sensitivity
        </ColorButtonForToolBar>
      </CanAllow>
    </div>
  );

  return isEditMode ? renderEditMode() : renderViewMode();
};

export default FileSensitivity;