import SharePointLogo from "../../../assets/icons/SharePointLogo.svg";
import OneDriveLogo from "../../../assets/icons/OneDriveLogo.svg";
import { domain_vizr } from "../../../redux/constants/constants";

export const SHAREPOINT_SERVER = {
  icon: SharePointLogo,
  name: "Sharepoint",
  type: "checkbox",
};
export const ONEDRIVE_SERVER = {
  icon: OneDriveLogo,
  name: "One Drive",
  type: "checkbox",
};
export const DataSourceErrorMessages = {
  INVALID_DOMAIN_NAME: "Invalid Domain Name",
};
export const NTFS = "NTFS";
export const instructionTypes = [
  "SQL Server",
  "Google Drive",
  "PostgreSQL",
  "NTFS",
];
export const unMaskInstruction = {
  "POSTGRESQL": "PostgreSQL",
  "NTFS": "NTFS",
  "MSSQL": "SQL Server"
}
export const DOWNLOAD_NTFS_CONFIGURATION = "DOWNLOAD_NTFS_CONFIGURATION";
export const getNTFSConfiguration = (id) => {
  return `${domain_vizr}/tenant/datasource/${id}/ntfsConfig`;
};
