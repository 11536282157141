import TransactionIcon from "../../assets/vizr_images/ic_transaction.svg";
import { DeleteButtonForToolBar } from "../common/buttons";
import { AddDBButton } from "../common/buttons";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { AddCircleOutlineOutlined, DeleteOutline } from "@material-ui/icons";
import { Tooltip } from "reactstrap";
import { getConnectedStatus, getInProgressStatus, getNotConnectedStatus, getErrorStatus } from "../common/utilities";
import { isEmpty } from "lodash";
import { getTime, CustomSwitch,isDataSourceActive, isCloudFileSystem, renderDataSource } from "../common/utilities";
import { MAXIMUM_NUMBER_OF_REPORTS, reviewDataSource, updateTenantUser } from "../../redux/constants/constants";
import { Checkbox } from "@material-ui/core";
import DeleteIcon from "../../assets/vizr_images/ic_rubbish_bin.png";
import { UTCTooltip } from "../views/GenerateReport/UTCTooltip";
import { NTFS } from "../views/manageDataSource/dataSourceConstants";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#081981",
  },
  inputInput: {
    boxShadow: "0 5px 8px -3px rgba(1,18,121,0.5)",
    borderRadius: "6px",
    marginRight: "10px",
    padding: "8px 20px",
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const alertPolicyHeadCells = [
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    align: "center",
    orderId: "status.code",
  },
  {
    id: "policy",
    numeric: false,
    disablePadding: false,
    label: "Policy Name",
    align: "left",
    orderId: "name",
  },
  {
    id: "alerts",
    numeric: true,
    disablePadding: false,
    label: "Alerts",
    align: "left",
    orderId: "alertsCount",
  },
  {
    id: "dataFlowGroups",
    numeric: false,
    disablePadding: false,
    label: "Data Flow Groups",
    align: "left",
    orderId: "dataFlowGroupsCount",
  },
  {
    id: "alertRule",
    numeric: false,
    disablePadding: false,
    label: "Alert Rule",
    align: "left",
    orderId: "",
  },
  {
    id: "severity",
    numeric: false,
    disablePadding: false,
    label: "Severity",
    align: "left",
    orderId: "severity.code",
  },
];

export const dataFLowGroupHeadCell = [
  {
    id: "DataFlowName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    orderId: "name",
  },
  {
    id: "aliasName",
    numeric: true,
    disablePadding: false,
    label: "Alias Name",
    orderId: "aliasName",
  },
  {
    id: "flowAggregation",
    numeric: true,
    disablePadding: false,
    label: "Flow Aggregation",
    orderId: "flowAggregation",
    // img: TransactionIcon,
  },
  {
    id: "tracesCount",
    numeric: true,
    disablePadding: false,
    img: TransactionIcon,
    orderId: "tracesCount",
  },
  {
    id: "dataSensitivity",
    numeric: true,
    disablePadding: false,
    label: "Data Sensitivity",
    orderId: "sensitivity",
  },
];

export const dataFLowGroupHeadCellForCreateEdit = [
  {
    id: "DataFlowName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    orderId: "name",
  },
  {
    id: "aliasName",
    numeric: true,
    disablePadding: false,
    label: "Alias Name",
    orderId: "aliasName",
  },
  {
    id: "flowAggregation",
    numeric: true,
    disablePadding: false,
    label: "Flow Aggregation",
    orderId: "flowAggregation",
    //img: TransactionIcon,
  },
  {
    id: "tracesCount",
    numeric: true,
    disablePadding: false,
    img: TransactionIcon,
    orderId: "tracesCount",
  },
  {
    id: "dataSensitivity",
    numeric: true,
    disablePadding: false,
    label: "Data Sensitivity",
    orderId: "sensitivity",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];
export const alertHeadCells = [
  {
    id: "level",
    numeric: false,
    disablePadding: false,
    label: "Level",
    orderId: "",
    align: "left",
  },
  {
    id: "risk",
    numeric: true,
    disablePadding: false,
    label: "Risk",
    orderId: "riskScore",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time",
    orderId: "generatedOn",
  },
  {
    id: "policy",
    numeric: false,
    disablePadding: false,
    label: "Policy Name",
    orderId: "",
  },
  {
    id: "alertViolation",
    numeric: false,
    disablePadding: false,
    label: "Alert Violation",
    orderId: "",
  },
  {
    id: "dataSourceName",
    numeric: false,
    disablePadding: false,
    label: "Data Source Name",
    orderId: "",
  },
  {
    id: "dbUserName",
    numeric: false,
    disablePadding: false,
    label: "DB Username",
    orderId: "",
  },
  { id: "monitors", numeric: true, disablePadding: false, label: "Monitors" },
];
export const headCellsForDrawer = [
  {
    id: "risk",
    numeric: true,
    disablePadding: false,
    label: "Risk Score",
    orderId: "riskScore",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time",
    orderId: "",
  },
];

export const headCellsForTenantList = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
    orderId: "name",
  },
  {
    id: "platformSubdomainName",
    numeric: false,
    disablePadding: false,
    label: "Sub Domain",
    orderId: "platformSubdomainName",
  },
  {
    id: "tenantStatus",
    numeric: false,
    disablePadding: false,
    label: "Status",
    orderId: "tenantStatus",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

export const headCellsForUsersList = () => {
  return [
    { id: "firstName", numeric: true, disablePadding: false, label: "Username" },
    {
      id: "",
      numeric: false,
      disablePadding: false,
      label: "Roles of User",
    },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
    },
    { id: "active", numeric: false, disablePadding: false, label: "Active" },
    { id: "action", numeric: true, disablePadding: false, label: "Action", requiredPermission: updateTenantUser },
  ];
}

export const headCellsForRoles = [
  { id: "role", numeric: true, disablePadding: false, label: "Roles" },
  { id: "", numeric: true, disablePadding: false, label: "" },
];

export const headCellsForPermissons = [
  {
    id: "Persmissions",
    numeric: true,
    disablePadding: false,
    label: "Persmissions",
  },
  { id: "Roles", numeric: true, disablePadding: false, label: "Roles" },
  { id: "", numeric: true, disablePadding: false, label: "" },
  { id: "", numeric: true, disablePadding: false, label: "" },
  { id: "", numeric: true, disablePadding: false, label: "" },
];

// export const ReviewDataSourcesHeadCells = [
//   {
//     id: "application",
//     numeric: false,
//     disablePadding: true,
//     label: "Application Name",
//     orderId: "application.name",
//   },
//   {
//     id: "aliasName",
//     numeric: true,
//     disablePadding: false,
//     label: "Data Source Name",
//     orderId: "aliasName",
//   },
//   {
//     id: "databaseName",
//     numeric: false,
//     disablePadding: false,
//     label: "Data Source Type",
//     orderId: "application.databaseName",
//   },
//   {
//     id: "connectionInfo",
//     numeric: false,
//     disablePadding: false,
//     label: "Connection Info",
//     orderId: "",
//   },
//   {
//     id: "action",
//     numeric: true,
//     disablePadding: false,
//     label: "Action",
//     orderId: "",
//   },
// ];
// const classes = useStyles();
const renderActiveStatus = (row, handleActiveStatus) => {
  if (!isEmpty(row.status) && !isEmpty(row.status.value)) {
    return (
      <>
        <CustomSwitch
          checked={isDataSourceActive(row.status.value)}
          onChange={(event) => handleActiveStatus(event, row)}
          title={row.status.value}
        />
      </>
    );
  } else {
    return <>-</>;
  }
};
const renderAction = (row, handleAddClick, handleDataSourceDelete) => {
  if (!isEmpty(row.status) && !isEmpty(row.name) && !isDataSourceActive(row.status.value)) {
    return (
      <>
        <DeleteButtonForToolBar
          variant="contained"
          id="delete"
          size="small"
          color="primary"
          onClick={() => handleDataSourceDelete(row.name, row.id)}
          className="min-margin table-buttons"
        >
          <img className="deleteIconImg" src={DeleteIcon} alt="delete" />
        </DeleteButtonForToolBar>
      </>
    );
  } else if (isCloudFileSystem(row.type.code)) {
    return (
      <DeleteButtonForToolBar
        variant="contained"
        id="delete"
        size="small"
        color="primary"
        onClick={(event) => handleAddClick(event, row)}
        className="min-margin"
      >
        <EditOutlinedIcon />
      </DeleteButtonForToolBar>
    );
  } else {
    return (
      <span>
        {row.connectionUrl !== null ? (
          <DeleteButtonForToolBar
            variant="contained"
            id="delete"
            size="small"
            color="primary"
            onClick={(event) => handleAddClick(event, row)}
            className="min-margin"
          >
            <EditOutlinedIcon />
          </DeleteButtonForToolBar>
        ) : (
          <AddDBButton
            variant="contained"
            color="primary"
            size="small"
            onClick={(event) => handleAddClick(event, row)}
            startIcon={<AddIcon />}
            className="min-margin"
          ></AddDBButton>
        )}
      </span>
    );
  }
};

const renderConnectionURL = (row, classes, handleURLData) => {
  if (isCloudFileSystem(row.type.code)) {
    return <div>-</div>;
  } else {
    return (
      <span>
        {row.connectionUrl !== null ? (
          <div style={{ overflowWrap: "anywhere" }}>{row.connectionUrl}</div>
        ) : (
          <>
            <InputBase
              placeholder="Add URL Location"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInputClass,
              }}
              onChange={(event) => {
                handleURLData(event);
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </>
        )}
      </span>
    );
  }
};

const oneDriveIcons = (connectionStatus) => {
  if (connectionStatus === "Connected") {
    return getConnectedStatus();
  } else if (connectionStatus === "In Progress") {
    return getInProgressStatus();
  } else if (connectionStatus === "Not Connected") {
    return getNotConnectedStatus();
  } else if (connectionStatus === "Error") {
    return getErrorStatus();
  }
}

const renderStatus = (row) => {
  if (isCloudFileSystem(row.type.code) && row.type.code !== NTFS) {
    return (
      <div className="oneDrive-status">
        <div>{oneDriveIcons(row.connectionStatus)}</div>
      </div>
    )
  } else {
    return <div>-</div>;
  };
};

export const ReviewDataSourcesHeadCells = (
  handleURLData,
  handleAddClick,
  classes,
  handleActiveStatus,
  handleDataSourceDelete
) => {
  return [
    {
      title: "Application Name",
      render: (rowData) => {
        return <span>{rowData.application.name}</span>;
      },
    },
    {
      title: "Data Source Name",
      render: (rowData) => {
        return renderDataSource(rowData.type.code, rowData.aliasName);
      }
    },
    {
      title: "Data Source Type",
      render: (rowData) => {
        return <span>{rowData.type.value}</span>;
      },
    },
    {
      title: "Connection Status",
      render: (row) => {
        return <span>{renderStatus(row, classes)}</span>;
      },
    },
    {
      title: "Action",
      render: (row) => {
        return <span>{renderAction(row, handleAddClick, handleDataSourceDelete)}</span>;
      },
      requiredPermission: reviewDataSource,
    },
    {
      title: "Status",
      render: (row) => {
        return <span>{renderActiveStatus(row, handleActiveStatus)}</span>;
      },
      requiredPermission: reviewDataSource,
    },
  ];
};

export const ViewCdcLogConstants = () => {
  return [
    {
      title: "Process Name",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%" },
      render: (rowData) => {
        return <span>{rowData.processName}</span>;
      },
    },
    {
      title: "Last Run Time",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return <span>{getTime(rowData.lastRunTime)}</span>;
      },
    },
    {
      title: "Table Name",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return <span>{rowData.tableName}</span>;
      },
    },
    {
      title: "Cdc Key",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return <span>{rowData.cdcKey}</span>;
      },
    },
    {
      title: "Cdc Value",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return <span>{rowData.cdcValue}</span>;
      },
    },
  ];
};

export const headCellsForSqlStatements = [
  { id: "sql", numeric: true, disablePadding: false, label: "Query" },
];

export const databaseUsersHeadCells = [
  {
    id: "Username",
    numeric: true,
    disablePadding: false,
    label: "Data Source Username",
  },
  {
    id: "DataSourceName",
    numeric: true,
    disablePadding: false,
    label: "Data Source Name",
  },
  {
    id: "AccessRoleName",
    numeric: true,
    disablePadding: false,
    label: "Data Source Access Roles",
  },
  {
    id: "UserStatus",
    numeric: true,
    disablePadding: false,
    label: "User Status",
  },
  {
    id: "AccountStatus",
    numeric: true,
    disablePadding: false,
    label: "Account Status",
  },

  {
    id: "ExpiryDate",
    numeric: true,
    disablePadding: false,
    label: "Expiry Date",
  },
  {
    id: "ExpiryDates",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

export const accessDetailsHeadCells = [
  {
    id: "userName",
    numeric: true,
    disablePadding: false,
    label: "Username",
    orderId: "username",
  },
  {
    id: "roles",
    numeric: true,
    disablePadding: false,
    label: "Data Source Role",
    orderId: "roles",
  },
  {
    id: "dataSourceName",
    numeric: true,
    disablePadding: false,
    label: "Data Source Name",
    orderId: "dataSourceName",
  },
  {
    id: "SensitiveData",
    numeric: true,
    disablePadding: false,
    label: "Has Sensitive Data?",
    orderId: "sensitiveDataAccessed",
  },
  {
    id: "sensitiveDataAccessPercentage",
    numeric: true,
    disablePadding: false,
    label: "Sensitive Data Accessed",
    orderId: "sensitiveDataAccessPercentage",
  },
  {
    id: "noOfAnomalies",
    numeric: true,
    disablePadding: false,
    label: "No of Anomalies",
    // orderId: "sensitiveDataAccessPercentage",
  },
  {
    id: "highestAlert",
    numeric: true,
    disablePadding: false,
    label: "Highest Alert Sensitivity",
    // orderId: "sensitiveDataAccessPercentage",
  },
  {
    id: "firstAccessedOn",
    numeric: true,
    disablePadding: false,
    label: "First  Access Time",
    orderId: "firstAccessedOn",
  },
  {
    id: "lastAccessedOn",
    numeric: true,
    disablePadding: false,
    label: "Last Access Time",
    orderId: "lastAccessedOn",
  },
];

export const accessAnalomy = [
  {
    id: "TimeStamp",
    disablePadding: false,
    label: "Time",
    orderId: "timestamp",
  },
  {
    id: "PolicyViolations",
    disablePadding: false,
    label: "Policy",
    orderId: "policyViolation",
  },
  {
    id: "reason",
    disablePadding: false,
    label: "Alert Violation Details",
    orderId: "reason",
  },
  {
    id: "AlertType",
    disablePadding: false,
    label: "Alert Violation",
    orderId: "alertType",
  },
  {
    id: "Severity",
    disablePadding: false,
    label: "Severity ",
    orderId: "severity",
  },
];
export const AllAccessFilePopUpHeadCells = [
  {
    id: "timeStamp",
    disablePadding: false,
    label: "Time",
    orderId: "timeStamp",
  },
  {
    id: "userName",
    disablePadding: false,
    label: "Users",
    orderId: "userName",
  },
  {
    id: "dbStatement",
    disablePadding: false,
    label: "Access Statement",
    orderId: "dbStatement",
  },
]
export const allAccess = [
  {
    id: "timeStamp",
    disablePadding: false,
    label: "Time Stamp",
    orderId: "timeStamp",
  },
  {
    id: "dbStatement",
    disablePadding: false,
    label: "DataSource Access Statement",
    orderId: "dbStatement",
  },
];

export const sensitiveAccess = [
  {
    id: "timeStamp",
    disablePadding: false,
    label: "Time Stamp",
    orderId: "timeStamp",
  },
  {
    id: "sensitiveData",
    disablePadding: false,
    label: "Sensitive Data",
    orderId: "sensitiveData",
  },
];
//column names in the datasource details table
export const dataSourceDetails = [
  {
    id: "datasourcename",
    disablePadding: false,
    label: "Data Source Name",
    orderId: "datasourceName",
  },
  {
    id: "datasourceroles",
    disablePadding: false,
    label: "Data Source Roles",
    orderId: "datasourceRoles",
  },
  {
    id: "datasourceusername",
    disablePadding: false,
    label: "Data Source Username",
    orderId: "datasourceUsername",
  },
];
export const MDRIncidentTable = [
  {
    id: "IncidentsTable.incidentTime",
    disablePadding: false,
    label: "Created",
    orderId: "IncidentsTable.incidentTime",
  },
  {
    id: "IncidentsTable.incidentId",
    disablePadding: false,
    label: "Incident ID",
    orderId: "IncidentsTable.incidentId",
  },
  {
    id: "IncidentsTable.priorityNum",
    disablePadding: false,
    label: "Priority",
    // orderId: "IncidentsTable.priority",
    orderId: "IncidentsTable.priorityNum",
  },
  {
    id: "IncidentsTable.status",
    disablePadding: false,
    label: "State",
    orderId: "IncidentsTable.status",
  },
  {
    id: "IncidentsTable.name",
    disablePadding: false,
    label: "Description",
    orderId: "IncidentsTable.name",
  },
];

export const MDRAuthenticationTable = [
  {
    id: "MdrAuthentication.creation_time",
    disablePadding: false,
    label: "Time",
    orderId: "MdrAuthentication.creation_time",
  },
  {
    id: "MdrAuthentication.ipAddress",
    disablePadding: false,
    label: "IP Address",
    orderId: "MdrAuthentication.ipAddress",
  },
  {
    id: "MdrAuthentication.authErrorSource",
    disablePadding: false,
    label: "Source",
    orderId: "MdrAuthentication.authErrorSource",
  },
  {
    id: "MdrAuthentication.userName",
    disablePadding: false,
    label: "Username",
    orderId: "MdrAuthentication.userName",
  },
  {
    id: "MdrAuthentication.result",
    disablePadding: false,
    label: "Reason For Failures",
    orderId: "MdrAuthentication.result",
  },
];

export const headCellsForDistinctUsers = [
  {
    id: "MDRAuthenticationDistinctUsers.ipAddress",
    disablePadding: false,
    label: "IP Address",
    orderId: "MDRAuthenticationDistinctUsers.ipAddress",
  },
  {
    id: "MDRAuthenticationDistinctUsers.userName",
    disablePadding: false,
    label: "Username",
    orderId: "MDRAuthenticationDistinctUsers.userName",
  },
  {
    id: "MDRAuthenticationDistinctUsers.loginFailurecount",
    disablePadding: false,
    label: "Login Failures",
    orderId: "MDRAuthenticationDistinctUsers.loginFailurecount",
  },
];

export const headCellsForDistinctIPs = [
  {
    id: "MDRAuthenticationDistinctIP.userName",
    disablePadding: false,
    label: "Username",
    orderId: "MDRAuthenticationDistinctIP.userName",
  },
  {
    id: "DistinctIps",
    disablePadding: false,
    label: "Distinct IPs",
    orderId: "MDRAuthenticationDistinctIP.ipAdressCount",
  },
  {
    id: "LoginFailures",
    disablePadding: false,
    label: "Login Failures",
    orderId: "MDRAuthenticationDistinctIP.loginFailureCount",
  },
];

export const endPointsHeadCells = [
  {
    id: "MDREndPoints.creation_time",
    disablePadding: false,
    label: "Last Seen",
    orderId: "MDREndPoints.creation_time",
  },
  {
    id: "MDREndPoints.hostName",
    disablePadding: false,
    label: "Host Name",
    orderId: "MDREndPoints.hostName",
  },
  {
    id: "MDREndPoints.ipv4",
    disablePadding: false,
    label: "IP Address",
    orderId: "MDREndPoints.ipv4",
  },
  {
    id: "MDREndPoints.score",
    disablePadding: false,
    label: "Score",
    orderId: "MDREndPoints.score",
  },
  {
    id: "MDREndPoints.environment",
    disablePadding: false,
    label: "Environment",
    orderId: "MDREndPoints.environment",
  },
  {
    id: "MDREndPoints.osDescription",
    disablePadding: false,
    label: "Operating System",
    orderId: "MDREndPoints.osDescription",
  },

  {
    id: "MDREndPoints.userName",
    disablePadding: false,
    label: "Username",
    orderId: "MDREndPoints.userName",
  },
];

export const MDRpatchMonitoringHeadCells = [
  {
    id: "MDRPatchManagement.creation_time",
    disablePadding: false,
    label: "Published",
    orderId: "MDRPatchManagement.creation_time",
  },
  {
    id: "MDRPatchManagement.server",
    disablePadding: false,
    label: "Computer",
    orderId: "MDRPatchManagement.server",
  },
  {
    id: "MDRPatchManagement.subscriptionName",
    disablePadding: false,
    label: "Environment",
    orderId: "MDRPatchManagement.subscriptionName",
  },
  {
    id: "MDRPatchManagement.classification",
    disablePadding: false,
    label: "Classification",
    orderId: "MDRPatchManagement.classification",
  },
  {
    id: "MDRPatchManagement.severity",
    disablePadding: false,
    label: "Severity",
    orderId: "MDRPatchManagement.severity",
  },
  {
    id: "MDRPatchManagement.updateState",
    disablePadding: false,
    label: "Update State",
    orderId: "MDRPatchManagement.updateState",
  },
  {
    id: "MDRPatchManagement.kbid",
    disablePadding: false,
    label: "KBID",
    orderId: "MDRPatchManagement.kbid",
  },
  {
    id: "MDRPatchManagement.title",
    disablePadding: false,
    label: "Title",
    orderId: "MDRPatchManagement.title",
  },
];

/**
 * ReportRepositoryHeadCells function generates an array of column configurations
 * for rendering the header cells in the report repository table.
 *
 * @param {Function} renderReportName - Function to render the Report Name column content.
 * @param {Function} renderGeneratedOn - Function to render the Generated On column content.
 * @param {Function} renderTimeRange - Function to render the Report Period column content.
 * @param {Function} renderGeneratedBy - Function to render the Generated By column content.
 * @param {Function} renderContent - Function to render the Content Generated column content.
 * @param {Function} renderNoOfPages - Function to render the No of Rows column content.
 * @param {Function} handleSelectionModelChange - Callback for handling selection model change.
 * @param {Function} handleCheckItem - Callback for handling checkbox item click.
 * @param {Array} reportData - Array of data representing the reports to be displayed.
 * @param {Array} rowSelected - Array representing selected rows in the table.
 * @returns {Array} An array of column configurations for the report repository table.
 */
export const ReportRepositoryHeadCells = (
  renderReportName,
  renderGeneratedOn,
  renderTimeRange,
  renderGeneratedBy,
  renderContent,
  renderReportGenerationStatus,
  renderNoOfPages,
  handleSelectionModelChange,
  handleCheckItem,
  reportData,
  rowSelected
) => {

  // Determines whether the checkbox for a specific row should be disabled based on the report status and selected report length exceeded.
  const reportCheckBoxDisable = (rowData, rowLengthExceeded) => {
    return rowData.status !== "Success" || rowLengthExceeded;
  }
  return [
    // Checkbox column for row selection
    {
      title: () => {
        // Determine if all rows with the status 'Success' are selected
        const successRowsCount = reportData.filter(
          (row) => row.status === "Success"
        ).length;

        const allRowsSelected =
          successRowsCount > 0 && rowSelected.length === successRowsCount;

        return (
          <Checkbox
            title={MAXIMUM_NUMBER_OF_REPORTS}
            onChange={(event) => handleSelectionModelChange(event)}
            inputProps={{ "aria-label": "decorative checkbox" }}
            checked={allRowsSelected}
            checkedIcon={
              allRowsSelected ? (
                <span className="checked-icon" />
              ) : (
                <span className="checkbox-icon" />
              )
            }
            indeterminate={
              rowSelected.length > 0 && rowSelected.length < reportData.length
            }
          />
        );
      },
      render: (rowData) => {
        // Checkbox for individual row selection
        const rowLengthExceeded = rowSelected.length >= 5 && !rowData.checked;
        return (
          <div
            title={rowLengthExceeded ? MAXIMUM_NUMBER_OF_REPORTS : ""}
            className={
              reportCheckBoxDisable(rowData, rowLengthExceeded)
                ? "disabledReportCursor"
                : ""
            }
          >
            <Checkbox
              className= "checkbox-root"
              disableRipple
              color="default"
              disabled={reportCheckBoxDisable(rowData, rowLengthExceeded)}
              onChange={() => handleCheckItem(rowData)}
              onClick={(event) => event.stopPropagation()}
              checkedIcon={
                rowData.checked && rowSelected.length > 0 ? (
                  <span className="checked-icon" />
                ) : (
                  <span className="checkbox-icon" />
                )
              }
              icon={<span className="checkbox-icon" />}
              inputProps={{
                "aria-label": "decorative checkbox",
              }}
              checked={true}
            />
          </div>
        );
      },
      padding: "checkbox",
    },
    {
      title: "Report Name",
      style: { width: "13%" },
      rowBodyCellStyle: { width: "13%" },
      render: (rowData) => {
        return <>{renderReportName(rowData.reportName)}</>;
      },
    },
    {
      title: () => {
        return (
          <div className="header-icon">
            <span>Generated On</span>
            {UTCTooltip("tableHeader")}
          </div>
        );
      },
      style: { width: "13%" },
      rowBodyCellStyle: { width: "13%" },
      render: (rowData) => {
        return <>{renderGeneratedOn(rowData.creationTimestamp)}</>;
      },
    },
    {
      title: () => {
        return (
          <div className="header-icon">
            <span>Report Period</span>
            {UTCTooltip("tableHeader")}
          </div>
        );
      },
      style: { width: "17%" },
      rowBodyCellStyle: { width: "17%" },
      render: (rowData) => {
        return <>{renderTimeRange(rowData.startDate, rowData.endDate)}</>;
      },
    },
    {
      title: "Generated By",
      style: { width: "13%" },
      rowBodyCellStyle: { width: "13%" },
      render: (rowData) => {
        return <>{renderGeneratedBy(rowData.createdBy)}</>;
      },
    },
    {
      title: "Content Generated",
      style: { width: "19%" },
      rowBodyCellStyle: { width: "19%" },
      render: (rowData) => {
        return <>{renderContent(rowData.sectionType)}</>;
      },
    },
    {
      title: "Status",
      style: { width: "8%"},
      rowBodyCellStyle: { width: "8%" },
      render: (rowData) => {
        return <>{renderReportGenerationStatus(rowData.status)}</>
      }
    },
    {
      title: "No of Rows",
      style: { width: "20%" },
      rowBodyCellStyle: { width: "20%" },
      render: (rowData) => {
        return <>{renderNoOfPages(rowData)}</>;
      },
    },
  ];
};