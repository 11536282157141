import { domain_vizr } from "../../../redux/constants/constants";

export const SET_NTFS_LOADER = "SET_NTFS_LOADER";
export const SET_ALL_FOLDERS_DATA = "SET_ALL_FOLDERS_DATA";
export const SET_FOLDER_CURRENT_PAGE = "SET_FOLDER_CURRENT_PAGE";
export const SET_FOLDER_CURRENT_SIZE = "SET_FOLDER_CURRENT_SIZE";
export const CLEAR_ALL_FOLDER_DATA = "CLEAR_ALL_FOLDER_DATA";
export const FETCH_ALL_FOLDERS_DATA = "FETCH_ALL_FOLDERS_DATA";
export const SET_TOTAL_FOLDER_COUNT = "SET_TOTAL_FOLDER_COUNT";
export const fetchAllFoldersAPI = (id, page, size) => {
  return `${domain_vizr}/tenant/dataMap/directoryPermission/${id}?page=${page}&size=${size}`
};