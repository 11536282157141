import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getCubejsApi, redirectToLogin } from '../../components/common/Dashboard/cubejs-util';
import { FETCH_TOTAL_ASSETS_COUNT, SET_TOTAL_ASSETS_COUNT,SET_ONEDRIVE_ASSETS_COUNT, SET_GOOGLEDRIVE_ASSETS_COUNT, SET_DROPBOX_ASSETS_COUNT, SET_NTFS_ASSETS_COUNT } from '../constants/constants';
import { isEmpty } from 'lodash';


//change once we have the drop box data add additional filter for drop box
function* fetchTotalAssetsCount() {
  const cubejsApi = getCubejsApi();
  let response;
  let oneDriveCount, dropBoxCount, googleDriveCount, ntfsCount;
  const totalAssetsCountQuery = {
    "measures": [
      "Assets.count"
    ],
    "filters": [
      {
        "member": "Assets.datasourceType",
        "operator": "equals",
        "values": [
          "ONEDRIVE",
          "DROPBOX",
          "GOOGLEDRIVE",
          "NTFS"
        ]
      }
    ]
  }
  const oneDriveTotalAssetsCount = {
    "measures": [
      "Assets.count"
    ],
    "filters": [
      {
        "member": "Assets.datasourceType",
        "operator": "equals",
        "values": [
          "ONEDRIVE"
        ]
      }
    ]
  }
  const dropBoxTotalAssetsCount = {
    "measures": [
      "Assets.count"
    ],
    "filters": [
      {
        "member": "Assets.datasourceType",
        "operator": "equals",
        "values": [
          "DROPBOX"
        ]
      }
    ]
  }
  const googledriveTotalAssetsCount = {
    "measures": [
      "Assets.count"
    ],
    "filters": [
      {
        "member": "Assets.datasourceType",
        "operator": "equals",
        "values": [
          "GOOGLEDRIVE"
        ]
      }
    ]
  }
  const ntfsTotalAssetsCount = {
    "measures": [
      "Assets.count"
    ],
    "filters": [
      {
        "member": "Assets.datasourceType",
        "operator": "equals",
        "values": [
          "NTFS"
        ]
      }
    ]
  }
  try {
    const totalAssetsCount = yield cubejsApi.load(totalAssetsCountQuery);
    const totalOneDriveAssetsCount = yield cubejsApi.load(oneDriveTotalAssetsCount);
    const totalDropBoxAssetsCount = yield cubejsApi.load(dropBoxTotalAssetsCount);
    const totalGoogleDriveAssetsCount = yield cubejsApi.load(googledriveTotalAssetsCount);
    const totalNtfsAssetsCount = yield cubejsApi.load(ntfsTotalAssetsCount);
    response = totalAssetsCount.rawData();
    oneDriveCount = totalOneDriveAssetsCount.rawData();
    dropBoxCount = totalDropBoxAssetsCount.rawData();
    googleDriveCount = totalGoogleDriveAssetsCount.rawData();
    ntfsCount = totalNtfsAssetsCount.rawData();
  } catch (error) {
    redirectToLogin(error)
  }

  if (!isEmpty(response)) {
    yield put({
      type: SET_TOTAL_ASSETS_COUNT,
      payload: response[0]["Assets.count"]
    });
  }
  if (!isEmpty(oneDriveCount)) {
    yield put({
      type: SET_ONEDRIVE_ASSETS_COUNT,
      payload: oneDriveCount[0]["Assets.count"]
    });
  }
  if (!isEmpty(dropBoxCount)) {
    yield put({
      type: SET_DROPBOX_ASSETS_COUNT,
      payload: dropBoxCount[0]["Assets.count"]
    });
  }
  if (!isEmpty(googleDriveCount)) {
    yield put({
      type: SET_GOOGLEDRIVE_ASSETS_COUNT,
      payload: googleDriveCount[0]["Assets.count"]
    });
  }
  if (!isEmpty(ntfsCount)) {
    yield put({
      type: SET_NTFS_ASSETS_COUNT,
      payload: ntfsCount[0]["Assets.count"]
    });
  }
}

function* TotalAssetsCountSaga() {
  yield takeEvery(FETCH_TOTAL_ASSETS_COUNT, fetchTotalAssetsCount)
}

export default TotalAssetsCountSaga;