import {domain_vizr} from "../../redux/constants/constants";
//Netspace constants
export const UPLOAD_NMAP_FILE = "UPLOAD_NMAP_FILE";
export const SET_NMAP_LOADER = "SET_NMAP_LOADER";
export const SET_NETSPACE_LOADER = "SET_NETSPACE_LOADER";
export const SET_NETSPACE_SNACKBAR = "SET_NETSPACE_SNACKBAR";
export const NMAP_UPLOAD_UNSUCCESSFUL = "File upload unsuccessful";
export const GET_HOST_WITH_ALERTS = "GET_HOST_WITH_ALERTS";
export const SET_HOST_WITH_ALERT = "SET_HOST_WITH_ALERT";
export const GET_SECURITY_ALERTS = "GET_SECURITY_ALERTS";
export const SET_SECURITY_ALERTS = "SET_SECURITY_ALERTS";
export const GET_VISIBILITY = "GET_VISIBILITY"
export const SET_VISIBILITY = "SET_VISIBILITY"
export const GET_CRITICALITY = "GET_CRITICALITY";
export const SET_CRITICALITY = "SET_CRITICALITY";
export const LOADING_DATA = "Loading Data...";
export const GET_GROUP_BY = "GET_GROUP_BY";
export const SET_GROUPS = "SET_GROUPS";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const UPDATE_SELECTED_GROUP = "UPDATE_SELECTED_GROUP";
export const SET_GROUP_COUNT = "SET_GROUP_COUNT";
export const SET_SITEMAP_EXPAND_ACCORDION = "SET_SITEMAP_EXPAND_ACCORDION";
export const SET_SITEMAP_EXPAND_ACCORDION_RESET = "SET_SITEMAP_EXPAND_ACCORDION_RESET";
export const GET_GROUP_BY_DATA = "GET_GROUP_BY_DATA";
export const SET_ACCORDION_DATA = "SET_ACCORDION_DATA";
export const UNGROUPED = "UNGROUPED";
export const SAVE_TAGS = "SAVE_TAGS";
export const CLOSE_NETSPACE_DRAWER = "CLOSE_NETSPACE_DRAWER";
export const OPEN_NETSPACE_DRAWER = "OPEN_NETSPACE_DRAWER";
export const SET_PAGINATION_MODEL = "SET_PAGINATION_MODEL";
export const CHANGE_TAG_MODAL_STATUS = "CHANGE_TAG_MODAL_STATUS";
export const SAVE_TAGS_URL = `${domain_vizr}/tenant/netSpace/tags?hostId=`;
export const GET_NETMAP_STATUS_URL = `${domain_vizr}/tenant/netSpace/fileStatus`;
export const GET_ACTIVE_NETMAP_URL = `${domain_vizr}/tenant/netSpace/activeSiteMapFile`;
export const GET_ACTIVE_NETMAP = 'GET_ACTIVE_NETMAP';
export const GET_NETMAP_STATUS = 'GET_NETMAP_STATUS';
export const FETCH_FILE_STATUS_CANCEL = 'FETCH_FILE_STATUS_CANCEL';
export const SET_ACTIVE_NETMAP = 'SET_ACTIVE_NETMAP';
export const SET_NETMAP_PROGRESS = 'SET_NETMAP_PROGRESS';
export const SET_FILE_EDITABLE = 'SET_FILE_EDITABLE';
export const FILE_COMPLETED_STATUS = ['PROCESSED','ERROR'];
export const SET_RELOAD_NETSPACE = 'SET_RELOAD_NETSPACE';
export const icons = {
    "DB": '/dataSourceIcons/db.svg',
    "API": '/dataSourceIcons/api.svg',
    "LDAP": '/dataSourceIcons/ldap.png',
    "MAIL": '/dataSourceIcons/smtp.png',
    "WINDOWS": '/dataSourceIcons/win.svg',
    "LINUX": '/dataSourceIcons/linux.svg',
    "EMBEDDED": '/dataSourceIcons/embedded.png'
  };

export const desiredOrderAlerts = ['HIGH', 'MEDIUM', 'LOW'];
export const desiredOrderMonitor = ['NO', 'UNKNOWN', 'YES'];
export const desiredOrderCriticality = ['highSeverityHosts', 'mediumSeverityHosts', 'lowSeverityHosts'];
export const formFinalURL = (selected_group, accordionKey, pageNo, pageSize) => {
    const prefix = GROUP_DATA_URL[selected_group];
    const suffix = GROUP_DATA_URL_SUFFIX[selected_group]
    return prefix + `?size=${pageSize}&page=${pageNo}` + suffix + accordionKey;
};
export const GROUP_DATA_URL = {
    TYPE: `${domain_vizr}/tenant/netSpace/groupByHostType`,
    OS: `${domain_vizr}/tenant/netSpace/groupByOsName`,
    SENSITIVE_PORTS: `${domain_vizr}/tenant/netSpace/groupByPortSeverity`,
    TAG: '',
    SECURITY_ALERTS: `${domain_vizr}/tenant/netSpace/groupByAlertSeverity`,
    MONITORING: `${domain_vizr}/tenant/netSpace/groupByMonitorStatus`,
    UNGROUPED: `${domain_vizr}/tenant/netSpace/hosts`
};
export const GROUP_DATA_URL_SUFFIX = {
    TYPE: `&hostType=`,
    OS: `&osName=`,
    SENSITIVE_PORTS: `&severity=`,
    TAG: '',
    SECURITY_ALERTS: `&alertType=`,
    MONITORING: `&status=`,
    UNGROUPED: ``
};
export const GROUP_COUNT_URL = {
    TYPE: `${domain_vizr}/tenant/netSpace/hostTypes`,
    OS: `${domain_vizr}/tenant/netSpace/osFamilies`,
    SENSITIVE_PORTS: `${domain_vizr}/tenant/netSpace/severities`,
    TAG: '',
    SECURITY_ALERTS: `${domain_vizr}/tenant/netSpace/alertSeverities`,
    MONITORING: `${domain_vizr}/tenant/netSpace/monitoringStatus`,
    UNGROUPED: ''
};
export const criticalityMap = {
    'highSeverityHosts': 'High',
    'mediumSeverityHosts': 'Medium',
    'lowSeverityHosts': 'Low'
};
export const MonitorMap = {
    'NO': 'Not Monitored',
    'UNKNOWN': 'Unknown',
    'YES': 'Monitored'
};
export const sensitivityMap = {
    'HIGH': 'High',
    'MEDIUM': 'Medium',
    'LOW': 'Low'
};
export const criticalityBGColorMap = {
    'highSeverityHosts': '#FFD3DC',
    'mediumSeverityHosts': '#FFE7D4',
    'lowSeverityHosts': '#BBC1E6'
};
export const criticalityBarColorMap = {
    'highSeverityHosts': '#FF2251',
    'mediumSeverityHosts': '#FF8429',
    'lowSeverityHosts': '#081981'
};
export const NMAP_FILE_UPLOAD = `${domain_vizr}/tenant/netSpace/upload`;
export const HOST_WITH_ALERT_URL = `${domain_vizr}/tenant/netSpace/hostsWithAlerts`;
export const SECURITY_ALERTS_URL = `${domain_vizr}/tenant/netSpace/alertSeverities`;
export const VISIBILITY_URL = `${domain_vizr}/tenant/netSpace/monitoringStatus`;
export const CRITICALITY_URL = `${domain_vizr}/tenant/netSpace/hostCriticalityCounts`;
export const GROUPS_URL = `${domain_vizr}/tenant/netSpace/groups`;

export const securityLegend = [
    {class: 'highUser', label: 'High'},
    {class: 'mediumUser', label: 'Medium'},
    {class: 'lowUser', label: 'Low'}
];

export const visibilityLegend = [
    {class: 'highUser', label: 'Not Monitored', isMonitored: 'NO'},
    {class: 'mediumUser', label: 'Unknown', isMonitored: 'UNKNOWN'},
    {class: 'lowUser', label: 'Monitored', isMonitored: 'YES'},
];
export const defaultPaginationModel = {
    pageSize: 20,
    page: 0,
};