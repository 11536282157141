import React, {useState, useEffect} from "react";
import PlatformInstructions from "../DevopsUser/PlatformInstructions";
import { GET_ALLPLATFORMS, POSTGRESQL, GOOGLEDRIVE, setupPageDetails } from "../../../redux/constants/constants";
import PopUp from "../../views/DevopsUser/PopUp";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif"
import { instructionTypes, unMaskInstruction } from "./dataSourceConstants";

const StaticPlatformInstructions = ({sourceType}) => {
  const [currentInstructions, setCurrentInstructions ] = useState ([]);
  const [platforms, setPlatforms] = useState([]);
  const [curAgentId, setCurAgentId] = useState("");
  const [tenant, setTenant] = useState(localStorage.getItem("tenant"));
  const [loader, setLoader] = useState(false);
  const [isUnmasked, setIsUnmasked] = useState(false);
  useEffect(() => {
    const apiCall = async () => {
      setLoader(true);
      const res = await getService({
        method: "GET",
        url: GET_ALLPLATFORMS(
          tenant,
          setupPageDetails[sourceType]
        ),
        headers: getAxiosHeaders(true),
      });
      if (res.status === 200) {
        setLoader(false);
        if (res.data.content[0]) {
          setPlatforms(res.data.content[0].platformInstructions);
          setCurAgentId(res.data.content[0].id);
        } else {
          setLoader(false);
          setPlatforms(res.data.content);
        }
      }
    };
      apiCall(); 
  }, []);

  useEffect(() => {
    if (platforms && platforms.length > 0) {
      let currentIns;
      platforms.forEach((el) => {
        if (instructionTypes.includes(el.platformType)) {
          currentIns = el.instructions ? el.instructions : "";
        } else {
          setCurrentInstructions([]);
        }
      });
      const insArr = currentIns ? currentIns.split() : [];
      setCurrentInstructions([...insArr]);
    } else {
      setCurrentInstructions([]);
    }
  }, [platforms]);

  const renderPlatformInstructions = () => {
    return (
      <div>
        {currentInstructions.length > 0 ? (
          <div className="devopsInitialSetUp_Popup_Container dataSourcePlatform">
            <PopUp
              currentInstructions={currentInstructions}
              curAgentId={curAgentId}
              setcurrentInstructions={setCurrentInstructions}
              tenant={tenant}
              currentPlatform={unMaskInstruction[sourceType]}
              platforms={platforms}
              setinsUnmasked={setIsUnmasked}
              loc= {sourceType === GOOGLEDRIVE ? "googleDrive" : "dataSource"}
            ></PopUp>
          </div>
        ) : null}
        {!currentInstructions.length > 0 ? (
          <div className="initialSetUp_platformSelection_rightContainer">
            <div>
              <h2 className="initialSetUp_platformSelection_rightContainer_heading">
                Select Your platform to see APM Agent Installation
              </h2>
            </div>
          </div>
        ) : (
          <PlatformInstructions
            currentInstructions={currentInstructions}
            curAgentId={curAgentId}
            setcurrentInstructions={setCurrentInstructions}
            insUnmasked={isUnmasked}
            loc="dataSource"
          ></PlatformInstructions>
        )}
      </div>
    );
  };


  return (
    <>
      {loader ? (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source tenantProfileLoader"
        />
      ) :renderPlatformInstructions() }
    </>
  );
};

export default StaticPlatformInstructions;
