import React, { useEffect, useState } from "react";
import "./RiskGovernance.scss";
import noRegisterIcon from "../../../assets/icons/noRegisterIcon.svg";
import {
  CLEAR_FILTERS_RP,
  CREATE_RP_TEXT,
  GET_ALL_RISK_PILOT_FILES,
  NO_DATA_TO_SHOW_RP,
  CLEAR_RISK_PILOT_FILES, RISK_TABLE_VIEW_OPTIONS
} from "./riskPilotConstant";
import { Button } from "@material-ui/core";
import RiskPilotFormManager from "./RiskPilotFormManager";
import { useDispatch, useSelector } from "react-redux";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import RiskPilotTableHeader from "./RiskPilotTableHeader";
import { RiskPilotTableSelector } from "./RiskPilotTableSelector";
import { isEmpty } from "lodash";

function RiskDetails({ setShowNewRiskButton, isEditMode, setIsEditMode, showRiskPilotForm, setShowRiskPilotForm }) {
  const dispatch = useDispatch();

  // Get data from Redux store
  const riskPilotTableData = useSelector(
    (state) => state.riskPilotReducer.allRiskPilotFiles
  );
  const loader = useSelector((state) => state.riskPilotReducer.loader);
  const isRiskCreated = useSelector(
    (state) => state.riskPilotReducer.isRiskCreated
  );
  const isFiltersSelected = useSelector(
    (state) => state.riskPilotReducer.isFiltersSelected
  );

  // Local state
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [tableView, setTableView] = useState(RISK_TABLE_VIEW_OPTIONS[0].code)

  // Clear filters when the component is unmounted
  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_RISK_PILOT_FILES });
      dispatch({ type: CLEAR_FILTERS_RP });
    };
  }, []);

  // Fetch risk register files when dependencies change
  useEffect(() => {
    if (!showRiskPilotForm && !isEditMode) {
    dispatch({
      type: GET_ALL_RISK_PILOT_FILES,
      payload: { page: currentPage, size: recordsPerPage, view: tableView },
    });
    }
  }, [showRiskPilotForm, isEditMode, currentPage, recordsPerPage, tableView]);

  // Render different components based on the state
  const renderPilot = () => {
    if (showRiskPilotForm || isEditMode) {
      // Render the form to create or edit a risk register
      return (
        <RiskPilotFormManager
          showRiskPilotForm={setShowRiskPilotForm}
          setShowNewRiskButton={setShowNewRiskButton}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      );
    } else if (
      !isFiltersSelected &&
      riskPilotTableData &&
      riskPilotTableData.length === 0
    ) {
      // Render a message indicating no data is available
      return (
        <div className="filter-table-container riskPilot">
          <div className="RRContainer">
            <img src={noRegisterIcon} alt="no register icon" />
            <div className="noDataFlexContainer">
              <p className="createRRText">{NO_DATA_TO_SHOW_RP}</p>
              <div className="noDataFlexContainer">
                <p>{CREATE_RP_TEXT}</p>
                <Button
                  className="createRRButton"
                  onClick={() => setShowRiskPilotForm(true)}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (riskPilotTableData || isFiltersSelected) {
      // Render the table with risk register data
      return (
        <div className="filter-table-container riskPilotTable">
          <div className="riskRegisterTableContainer">
            <RiskPilotTableHeader
              showRiskPilotForm={setShowRiskPilotForm}
              data={riskPilotTableData}
              tableView={tableView}
              setTableView={setTableView}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              setCurrentPage={setCurrentPage}
              setRecordsPerPage={setRecordsPerPage}
            />
            <RiskPilotTableSelector
              tableData={riskPilotTableData}
              tableView={tableView}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              setCurrentPage={setCurrentPage}
              setRecordsPerPage={setRecordsPerPage}
              isEditMode={setIsEditMode}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="riskLoader"
        />
      )}
      <div>{renderPilot()}</div>
    </>
  );
}

export default RiskDetails;