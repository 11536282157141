import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import SnackBar2 from "../../../components/shared/SnackBar2";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignUpMessages = ({
  openError,
  handleSnackbarClose,
  logoErrorMessage,
  classes,
  openBackdrop,
  handleBackdrop,
  errorObject,
}) => {
  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {logoErrorMessage}
        </Alert>
      </Snackbar>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        onClick={handleBackdrop}
      >
        <img src={Page_Loader} alt="_Loader" className="loader" />
      </Backdrop>
      <SnackBar2
        failure={errorObject.errorMessage ? true : false}
        success={errorObject.successMessage ? true : false}
        errorObject={errorObject}
        successMessage={errorObject.successMessage}
        errorMessage={errorObject.errorMessage}
      ></SnackBar2>
    </>
  );
};

export default SignUpMessages;
