import { call, put, select, takeEvery } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { 
  S3NOT_VERIFIED, 
  S3VerificationApi, 
  S3Verified, 
  VERIFYS3DATA, 
  SET_NTFS_SNACKBAR_MESSAGE,
  TENANT_SIGNUP_LOADER, 
} from "../TenantSignUp/tenantSignUpConstants";
import { SERVER_ERROR_MESSAGE } from "../../../redux/constants/constants";
import { FETCH_ALL_FOLDERS_DATA, fetchAllFoldersAPI, SET_ALL_FOLDERS_DATA, SET_NTFS_LOADER, SET_TOTAL_FOLDER_COUNT } from "./ntfsConstants";

function* verifyS3() {
  yield put({ type: TENANT_SIGNUP_LOADER, payload: true});
  const s3Data = yield select((state) => state.tenantSignUpReducer.s3Details);
  const { s3BucketName, accessKey, secretKey } = s3Data;
  const data = {
    bucketName: s3BucketName,
    accessKey,
    secretKey,
  };
  const options = {
    method: "POST",
    url: S3VerificationApi,
    headers: getAxiosHeaders(true),
    data: data,
  };
  try {
    const response = yield call(getService, options);
    if (response && response.status === 200) {
      yield put({ type: S3Verified, payload: true });
      yield put({ type: TENANT_SIGNUP_LOADER, payload: false});
    } else {
      let errorMessage = SERVER_ERROR_MESSAGE;
      if (response.response?.status === 400) {
        errorMessage = response.response.data?.errorMessage || SERVER_ERROR_MESSAGE;
      }
      yield put({ type: SET_NTFS_SNACKBAR_MESSAGE, payload: errorMessage });
      yield put({ type: S3Verified, payload: false });
      yield put({ type: TENANT_SIGNUP_LOADER, payload: false});
    }
  } catch (error) {
    yield put({ type: SET_NTFS_SNACKBAR_MESSAGE, payload: SERVER_ERROR_MESSAGE });
    yield put({ type: S3Verified, payload: false });
    yield put({ type: TENANT_SIGNUP_LOADER, payload: false});
  }
}
function* fetchAllFoldersData(action) {
  yield put({type: SET_NTFS_LOADER, payload: true});
  const {id, currentPage, pageSize} = action.payload;
  const options = {
    method: "GET",
    url: fetchAllFoldersAPI(id, currentPage, pageSize),
    headers: getAxiosHeaders(true)
  }
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data?.content
      const totalCount = res.data?.totalElements;
      yield put({type: SET_TOTAL_FOLDER_COUNT, payload: totalCount});
      yield put({type: SET_ALL_FOLDERS_DATA, payload: data });
      yield put({type: SET_NTFS_LOADER, payload: false});
    }
  } catch (error) {
    console.log(error);
    yield put({type: SET_NTFS_LOADER, payload: false});
  }
}

function* ntfsDataSourceSaga() {
  yield takeEvery(VERIFYS3DATA, verifyS3);
  yield takeEvery(FETCH_ALL_FOLDERS_DATA, fetchAllFoldersData);
}

export default ntfsDataSourceSaga;