import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { DDV, MDR, NETSPACE, RISK, VALIDATE_SHORTNAME } from "../../../redux/constants/constants";

export const validateShortName = (name, shortNameRef, errorObj, setErrorObj, resetPasswordMail) => {
  async function getFeaturesAndLicence() {
    if (name !== "" && shortNameRef !== name) {
      const res = await getService({
        method: "GET",
        url: VALIDATE_SHORTNAME(name),
        headers: getAxiosHeaders(true),
        data: {
          email: resetPasswordMail,
        },
      });
      if (res && res.status === 200) {
        if (res.data.isShortNameExist) {
          setErrorObj({
            ...errorObj,
            shortName: "shortName already exists.",
          });
        } else {
          setErrorObj({
            ...errorObj,
            shortName: "",
          });
        }
      }
    }
  }
  getFeaturesAndLicence();
};

//moved from TenantSignUP.js
export const getFeatureName = (name) => {
  switch (name) {
    case MDR:
      return "Threat Insights";
    case DDV:
      return "Data Vision";
    case RISK:
      return RISK;
    case NETSPACE:
      return NETSPACE;
    default:
      return "";
  }
};

