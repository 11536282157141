import {
  SET_NETSPACE_LOADER,
  SET_NETSPACE_SNACKBAR,
  SET_HOST_WITH_ALERT,
  SET_SECURITY_ALERTS,
  SET_VISIBILITY,
  SET_CRITICALITY,
  SET_GROUPS,
  UPDATE_SELECTED_GROUP,
  SET_GROUP_COUNT,
  SET_SITEMAP_EXPAND_ACCORDION,
  SET_SITEMAP_EXPAND_ACCORDION_RESET,
  SET_ACCORDION_DATA,
  UNGROUPED,
  CLOSE_NETSPACE_DRAWER,
  OPEN_NETSPACE_DRAWER,
  SET_PAGINATION_MODEL,
  CHANGE_TAG_MODAL_STATUS,
  SET_ACTIVE_NETMAP,
  SET_NETMAP_PROGRESS,
  SET_FILE_EDITABLE,
  SET_RELOAD_NETSPACE,
  defaultPaginationModel,
} from "./NetSpaceConstant";
import { cloneDeep, update } from "lodash";

const initialState = {
  loader: false,
  snackBar: false,
  snackBarMessage: "",
  hostWithAlert: null,
  securityAlerts: null,
  groups: [],
  groupCount: [],
  selectedGroup: UNGROUPED,
  expandAccordion: {},
  accordionData: {},
  selectedHost: null,
  drawerIsOpen: false,
  isTagModalOpen: false,
  selectedTagData: [],
  selectedTagNodeId: null,
  nmapProgressData: null,
  activeNetMap: null,
  reloadNetspace: false,
  isFileEditable: false,
  updateTagGroup: null,
  componentLoader: {
    GET_HOST_WITH_ALERTS: false,
    GET_SECURITY_ALERTS: false,
    GET_VISIBILITY: false,
  }
};

export const NetspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETSPACE_LOADER: {
      if (action.payload.key) {
        return {
          ...state,
          componentLoader: {
            ...state.componentLoader,
            [action.payload.key]: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
          loader: action.payload,
        };
      }
    }
    case SET_NETSPACE_SNACKBAR: {
      return {
        ...state,
        snackBar: action.payload.status,
        snackBarMessage: action.payload.message,
      };
    }
    case SET_HOST_WITH_ALERT: {
      return {
        ...state,
        hostWithAlert: action.payload.hostWithAlert,
      };
    }
    case SET_SECURITY_ALERTS: {
      return {
        ...state,
        securityAlerts: action.payload.securityAlerts,
      };
    }
    case SET_VISIBILITY: {
      return {
        ...state,
        visibility: action.payload.visibility,
      };
    }
    case SET_CRITICALITY: {
      return {
        ...state,
        criticality: action.payload.criticality,
      };
    }
    case SET_GROUPS: {
      return {
        ...state,
        groups: [
          ...action.payload.groups.content
        ]
      }
    }
    case UPDATE_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: action.payload
      }
    }
    case SET_GROUP_COUNT: {
      const accordionData = {};
      Object.keys(action.payload.groupCount).forEach(element => {
        accordionData[element] = {
          data: [],
          paginationModel: defaultPaginationModel
        }
      });
      return {
        ...state,
        groupCount: action.payload.groupCount,
        selectedHost: null,
        drawerIsOpen: false,
        accordionData: { ...accordionData }
      }
    }
    case SET_SITEMAP_EXPAND_ACCORDION: {
      return {
        ...state,
        expandAccordion: { ...action.payload }
      }
    }
    case SET_NETMAP_PROGRESS: {
      return {
        ...state,
        nmapProgressData: action.payload.nmap_progress_data
      }
    }
    case SET_ACTIVE_NETMAP: {
      return {
        ...state,
        activeNetMap: action.payload.active_nmap
      }
    }
    case SET_SITEMAP_EXPAND_ACCORDION_RESET: {
      return {
        ...state,
        expandAccordion: { ...action.payload, ...state.expandAccordion }
      }
    }
    case SET_ACCORDION_DATA: {
      let updatedSelectedHost = state.selectedHost;
      if (state.drawerIsOpen && updatedSelectedHost) {
        updatedSelectedHost = action.payload.accordionData.content.find((host) => (host.nodeId === state.selectedHost.nodeId));
      }
      const PageModel = state.accordionData[action.payload.accordionKey] ? state.accordionData[action.payload.accordionKey].paginationModel : defaultPaginationModel;
      return {
        ...state,
        accordionData: cloneDeep({
          ...state.accordionData,
          [action.payload.accordionKey]: {
            data: action.payload.accordionData,
            paginationModel: PageModel,
          }
        }),
        selectedHost: { ...updatedSelectedHost },
      }
    }
    case CLOSE_NETSPACE_DRAWER: {
      return {
        ...state,
        drawerIsOpen: false,
        selectedHost: null
      }
    }
    case OPEN_NETSPACE_DRAWER: {
      return {
        ...state,
        drawerIsOpen: true,
        updateTagGroup: action.payload.updateTagGroup,
        selectedHost: action.payload.selectedHost
      }
    }
    case SET_PAGINATION_MODEL: {
      return {
        ...state,
        accordionData: {
          ...state.accordionData,
          [action.payload.key]: {
            ...state.accordionData[action.payload.key],
            paginationModel: action.payload.newModel
          }
        }
      }
    }
    case CHANGE_TAG_MODAL_STATUS: {
      return {
        ...state,
        isTagModalOpen: action.payload.isTagModalOpen,
        selectedTagData: action.payload.selectedTagData,
        selectedTagNodeId: action.payload.selectedTagNodeId,
        updateTagGroup: (action.payload.updateTagGroup===null || action.payload.updateTagGroup===undefined) ? state.updateTagGroup : action.payload.updateTagGroup 
      }
    }
    case SET_FILE_EDITABLE: {
      return {
        ...state,
        isFileEditable: action.payload
      }
    }
    case SET_RELOAD_NETSPACE: {
      return {
        ...state,
        reloadNetspace: action.payload
      }
    }
    default:
      return state;
  }
};
