// HostField.js
import React from "react";
import { TextField } from "@material-ui/core";
import { SET_NTFS_HOST } from "../../../../redux/constants/constants";

function HostField({
  ntfsHost,
  classes,
  dispatch,
  removeErrorByKey,
  isEditMode,
  error,
}) {
  return (
    <div className={classes.customPadding}>
      <div>Host{<span className={classes.customErrorIndication}>*</span>}</div>
      <TextField
        required
        value={ntfsHost}
        onChange={(event) => {
          dispatch({ type: SET_NTFS_HOST, payload: event.target.value });
          removeErrorByKey("ntfsHost");
        }}
        variant="outlined"
        fullWidth
        placeholder="NTFS Hosts"
        disabled={isEditMode}
      />
      {error && error.ntfsHost ? (
        <div style={{ color: "red" }}>{error.ntfsHost}</div>
      ) : null}
    </div>
  );
}

export default HostField;
