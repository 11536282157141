import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, NavLink, Link, useLocation } from "react-router-dom";
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import { useTranslation } from "react-i18next";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { NewPolicyButtonForPolicyCreateEdit } from "../../common/buttons";
import UserAccountIcon from "../../../assets/vizr_images/Group-8.png";

import HelpIcon from "../../../assets/vizr_images/Group-7.svg";
import LeftArrowIcon from "../../../assets/vizr_images/01. Inactive.svg";
import RightArrowIcon from "../../../assets/Images/inspect/ic_home.svg";
import DruvStarLogo from "../../../assets/vizr_images/02. Monotone Logo.svg";
import NewDruvstarLogo from "../../../assets/vizr_images/DDVWhiteLogo.svg";
import SmallDruvstarLogo from "../../../assets/vizr_images/logo.svg";
// import DropDownChevron from "../../../assets/vizr_images/ic_chevron_down.svg";
import MDRLogo from "../../../assets/vizr_images/ThreatInsightLogoWhite.svg";
import MDRLogoSmall from "../../../assets/vizr_images/MDRLogoSmall.svg";
import DashboardIcon from "../../../assets/vizr_images/Group-5.svg";
import userManagementIcon from "../../../assets/vizr_images/userManagement.svg";
import netspaceIcon from "../../../assets/icons/netspace.svg";
import settingsIcon from "../../../assets/vizr_images/SettingsIcon1.svg";
import { MDR, DDV, MDR_CONFIG, addTenantUser, RISK, NETSPACE } from "../../../redux/constants/constants";
import farwardicon from "../../../assets/icons/farwardicon.svg";
import druvstarLogoFinal_whitesmall from "../../../assets/icons/druvstarLogoFinal_whitesmall.svg";
import activeDashboard from "../../../assets/vizr_images/activeDashboard.svg";
import activeUserManagement from "../../../assets/vizr_images/activeUserManagement.svg";
import activeSettings from "../../../assets/vizr_images/activeSettings.svg";
import { activeRoutes } from "../../common/utilities";
import CanAllow from "../../shared/CanAllow";
import ManageRiskPilot from "../../views/RiskPilot/ManageRiskPilot";
import { RISK_PILOT } from "../../views/RiskPilot/riskPilotConstant";
export default function Sidebar(props) {
  let history = useHistory();
  const {
    routes,
    bgColor,
    expand,
    handleShrink,
    isSignuppage,
    setProject,
    project,
  } = props;
  const permissions = useSelector((state) => state.applicationData.permissions);
  // const profilePicture = useSelector(
  //   (state) => state.signInpage.profilePicture
  // );
  const profilePicture = localStorage.getItem("profilePicture");
  const displayName = localStorage.getItem("displayName");
  const featuresData = JSON.parse(localStorage.getItem("featuresInfo"));
  const features =
    featuresData &&
    featuresData.features &&
    featuresData.features.map((data) => data.name);

  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  var tenant = window.localStorage.getItem("tenant");
  const dispatch = useDispatch();
  let location = useLocation();

  const handleClickOpen = () => {
    setOpenHelpDialog(true);
  };
  const handleClose = () => {
    setOpenHelpDialog(false);
  };

  const userAccountClickHandler = () => {
    dispatch({ type: "TRACK_TRAVERSAL", payload: "Profile Editing" });
    history.push("/mdr/profileEditing");
  };

  return (
    <React.Fragment>
      {expand ? (
        <div
          className={"sidebar mdrlessheight MDRsidebar_bgimg"}
          style={{
            height: isSignuppage ? "1075px" : "1000px",
          }}
        >
          <div className="sidebar-wrapper increase-Pad">
            <Nav className="navbar" style={{ listStyleType: "none" }}>
              <div
                className="MDR-main-logo"
                onClick={() => {
                  history.push("/mdr/MDRdashboards");
                  dispatch({
                    type: "TRACK_TRAVERSAL",
                    payload: "Dashboard",
                  });
                }}
              >
                <img
                  src={MDRLogo}
                  alt={MDRLogo}
                  style={{ width: "100px", textAlign: "center" }}
                />
                {/* Data Vision */}
              </div>
              <li className="MDR-list-items">
                <Link
                  onClick={() =>
                    dispatch({
                      type: "TRACK_TRAVERSAL",
                      payload: `Dashboard`,
                    })
                  }
                  className="nav-link"
                  to={"/mdr/MDRdashboards"}
                  href={"/mdr/MDRdashboards"}
                  activeClassName="active"
                >
                  <img className={
                    location && location.pathname.indexOf("/mdr/MDRdashboards") > -1
                        ? ""
                        : "dashboard-style"
                    }
                    src={activeRoutes(location,"/mdr/MDRdashboards", DashboardIcon, activeDashboard)} 
                    alt={"Dashboard"} />
                  <p
                    className={
                      location && location.pathname.indexOf("/mdr/MDRdashboards") > -1
                        ? "active side-bar-para mdr-para"
                        : "side-bar-para mdr-para"
                    }
                  >
                    {"Dashboard"}
                  </p>
                </Link>
              </li>
              <CanAllow
                needs={{
                  permission: [RISK_PILOT],
                  hasFeature: features && features.includes(RISK)
                }}
              >
                <li className="MDR-list-items">
                  <ManageRiskPilot location={location}/>
                </li>
              </CanAllow>
              <CanAllow needs={{ permission: [addTenantUser] }}>
                <li className="MDR-list-items">
                  <Link
                    onClick={() =>
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: `User Management`,
                      })
                    }
                    className="nav-link"
                    to={"/mdr/userManagement"}
                    href={"/mdr/userManagement"}
                    activeClassName="active"
                  >
                    <img
                      src={activeRoutes(location,"/mdr/userManagement", userManagementIcon, activeUserManagement)}
                      alt={"UserManagement"}
                    />
                    <p
                      className={
                        location && location.pathname.indexOf("/mdr/userManagement") >
                            -1
                          ? "active side-bar-para mdr-para"
                          : "side-bar-para mdr-para"
                      }
                    >
                      {"User Management"}
                    </p>
                  </Link>
                </li>
              </CanAllow>
              <CanAllow needs={{ permission: [MDR_CONFIG],  hasFeature: features && features.includes(NETSPACE) }}>
                <li className="MDR-list-items">
                    <Link
                      className="nav-link"
                      to={"/mdr/netspace"}
                      href={"/mdr/netspace"}
                      activeClassName="active"
                    >
                      <img
                        src={activeRoutes(location,"/mdr/netspace", netspaceIcon, netspaceIcon)}
                        alt={"Net_Space_Icon"}
                      />
                      <p
                        className={
                          location && location.pathname.indexOf("/mdr/netspace") >
                              -1
                              ? "active side-bar-para mdr-para"
                            : "side-bar-para mdr-para"
                        }
                      >
                        {"NetSpace"}
                      </p>
                    </Link>
                  </li>
              </CanAllow>
              <CanAllow needs={{permission: [MDR_CONFIG]}}>
                <li className="MDR-list-items">
                  <Link
                    onClick={() =>
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: `Settings`,
                      })
                    }
                    className="nav-link"
                    to={"/mdr/Settings"}
                    href={"/mdr/Settings"}
                    activeClassName="active"
                  >
                    <img
                      src={activeRoutes(location,"/mdr/Settings", settingsIcon, activeSettings)}
                      alt={"settingsIcon"}
                    />
                    <p
                      className={
                        location && location.pathname.indexOf("/mdr/Settings") > -1
                          ? "active side-bar-para mdr-para"
                          : "side-bar-para mdr-para"
                      }
                    >
                      {"Settings"}
                    </p>
                  </Link>
                </li>
              </CanAllow>
              {features && features.includes(DDV) && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "7rem",
                      paddingLeft: "10px",
                    }}
                    onClick={() => {
                      history.push("/tenant/dashboard");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Dashboard",
                      });
                    }}
                  >
                    <img
                      src={farwardicon}
                      alt="MDRLogo"
                      style={{
                        cursor: "pointer",
                      }}
                    ></img>
                    <span
                      style={{
                        height: "64px",
                        marginLeft: "10px",
                        borderLeft: "1px solid white",
                      }}
                    ></span>
                    <img
                      src={druvstarLogoFinal_whitesmall}
                      alt="druvstarLogoFinal_whitesmall"
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    ></img>
                  </div>
                </>
              )}

              {!isSignuppage && (
                <li className="user-account">
                  <ReactstrapNavLink onClick={userAccountClickHandler}>
                    {profilePicture ? (
                      <img
                        src={`data:image/png;base64,` + profilePicture}
                        alt={UserAccountIcon}
                      />
                    ) : (
                      <div className="userName">
                        {displayName &&
                          displayName.split(" ").map((el) => {
                            return el[0];
                          })}
                      </div>
                    )}

                    <p className="side-bar-para user-title">User Account</p>
                  </ReactstrapNavLink>
                </li>
              )}

              <li
                className={!isSignuppage ? "active-pro" : null}
              >
                <ReactstrapNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClickOpen()}
                >
                  <img className="sidebar-small-icon" src={HelpIcon} alt={HelpIcon} />
                  <p className="side-bar-para user-title">Help & Support</p>
                </ReactstrapNavLink>
              </li>
              <li className="forward" onClick={handleShrink}>
                <IconButton>
                  <img src={LeftArrowIcon} alt={LeftArrowIcon} />
                </IconButton>
              </li>
            </Nav>
          </div>
          <>
            <Dialog
              open={openHelpDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Druvstar Support"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please contact{" "}
                  <i
                    style={{
                      color: "#081981",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    ddv.support@druvstar.com
                  </i>
                  , for further queries and support.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <NewPolicyButtonForPolicyCreateEdit
                  style={{
                    marginBottom: "0px",
                    cursor: "pointer",
                    width: "124px",
                    height: "32px",
                  }}
                  onClick={() => handleClose()}
                  //autoFocus
                >
                  Close
                </NewPolicyButtonForPolicyCreateEdit>
              </DialogActions>
            </Dialog>
          </>
        </div>
      ) : (
        <div className={"shrunk-sidebar MDR_bgimg_shrunk"}>
          <div className="sidebar-wrapper">
            <Nav className="navbar shrunk" style={{ listStyleType: "none" }}>
              <li className="shrunk-druvstar-logo">
                <ReactstrapNavLink
                  href="#"
                  className="MDR-shrunk-sidebar-icons"
                >
                  <img
                    src={MDRLogoSmall}
                    alt={MDRLogoSmall}
                    className="MDR-shrunk-sidebar-logo"
                    onClick={() => {
                      history.push("/mdr/MDRdashboards");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Dashboard",
                      });
                    }}
                  />
                </ReactstrapNavLink>
              </li>
              <li className="shrunk-druvstar-logo">
                <ReactstrapNavLink
                  href="#"
                  className="MDR-shrunk-sidebar-icons"
                >
                  <img
                    src={activeRoutes(location,"/mdr/MDRdashboards", DashboardIcon, activeDashboard)}
                    alt={"dasboardLogo"}
                    onClick={() => {
                      history.push("/mdr/MDRdashboards");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Dashboard",
                      });
                    }}
                  />
                </ReactstrapNavLink>
              </li>
              <li className="shrunk-druvstar-logo">
                <ReactstrapNavLink
                  href="#"
                  className="MDR-shrunk-sidebar-icons"
                >
                  <img
                    src={activeRoutes(location,"/mdr/userManagement", userManagementIcon, activeUserManagement)}
                    alt={"user-management"}
                    onClick={() => {
                      history.push("/mdr/userManagement");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "User Management",
                      });
                    }}
                  />
                </ReactstrapNavLink>
              </li>
              <li className="shrunk-druvstar-logo">
                <ReactstrapNavLink
                  href="#"
                  className="MDR-shrunk-sidebar-icons"
                >
                  <img
                    src={activeRoutes(location,"/mdr/Settings", settingsIcon, activeSettings)}
                    alt={"settingsIcon"}
                    onClick={() => {
                      history.push("/mdr/Settings");
                      dispatch({
                        type: "TRACK_TRAVERSAL",
                        payload: "Settings",
                      });
                    }}
                  />
                </ReactstrapNavLink>
              </li>
              <li className="shrunk-druvstar-logo">
                {features && features.includes(DDV) && (
                  <ReactstrapNavLink
                    href="#"
                    className="MDR-shrunk-sidebar-icons"
                  >
                    <img
                      src={SmallDruvstarLogo}
                      alt={SmallDruvstarLogo}
                      className="MDR-shrunk-sidebar-logo"
                      onClick={() => {
                        history.push("/tenant/dashboard");
                        dispatch({
                          type: "TRACK_TRAVERSAL",
                          payload: "Dashboard",
                        });
                      }}
                    />
                  </ReactstrapNavLink>
                )}
              </li>
              {!isSignuppage ? (
                <li className="shrink-user-account">
                  <ReactstrapNavLink onClick={userAccountClickHandler}>
                    {profilePicture ? (
                      <img
                        src={`data:image/png;base64,` + profilePicture}
                        alt={UserAccountIcon}
                      />
                    ) : (
                      <div className="userName">
                        {displayName.split(" ").map((el) => {
                          return el[0];
                        })}
                      </div>
                    )}
                  </ReactstrapNavLink>
                </li>
              ) : null}

              <li className={!isSignuppage ? "active-pro" : null}>
                <ReactstrapNavLink href="#">
                  <img className="sidebar-small-icon" src={HelpIcon} alt={HelpIcon} />
                </ReactstrapNavLink>
              </li>
              <li className="forward mdr-forward" onClick={handleShrink}>
                <IconButton>
                  <img src={RightArrowIcon} alt={"rightarrowicon"} />
                </IconButton>
              </li>
            </Nav>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
