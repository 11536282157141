import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryIcons } from "../../common/utilities";
import { isEmpty } from "lodash";
import CanAllow from "../CanAllow";
import { ColorButtonForToolBar } from "../../common/buttons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { SAVE_FILE_CATEGORIES, UPDATE_SENSITIVITY_INFO_CATEGORY } from "../../../redux/constants/constants";
import { FormControl, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  noOptions: {
    color: "red",
  },
  autocompleteRoot: {
    "& ::-webkit-scrollbar": {
      width: "8px",
    },
    "& ::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#D0D0D0",
      borderRadius: "6px",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#C0C0C0",
    },
  },
  option: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  icon: {
    marginRight: theme.spacing(1),
    width: "24px",
    height: "24px",
  },
  selectedValue: {
    display: "flex",
    alignItems: "center",
  },
  selectedIcon: {
    marginRight: theme.spacing(1),
    width: "20px",
    height: "20px",
  },
}));

const FileCategory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataMapFilePopUpData = useSelector(
    (state) => state.dataMapReducer.dataMapFilePopUpData
  );
  const finalFileType = useSelector(
    (state) => state.InspectContentReducer.finalFileType
  );

  const allCategories = useSelector(
    (state) => state.InspectContentReducer.allCategories
  );

  const [selectedCategory, setSelectedCategory] = useState(null); // State for a single selected category
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (allCategories.length > 0 && isEditMode && finalFileType) {
      const category = allCategories.find((v) => v.code === finalFileType);
      setSelectedCategory(category || null);
    }
  }, [isEditMode, finalFileType]);

  const renderIcons = (data) => {
     if (!isEmpty(data) && data!=="NO_SENSITIVITY") {
      const categoryName = data?.trim().toUpperCase(); 
      const icon = categoryIcons[categoryName]; // Lookup the icon using the uppercase name
      return (
        <img
          src={icon}
          alt={categoryName}
          title={categoryName}
          className="oneDriveIconImage"
        />
      );
    } else {
      return <div>-</div>;
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  // Handle saving and transforming the selected category into the required format
  const handleSave = () => {
    const sensitivityId = dataMapFilePopUpData['Contents.sensitivityId'];
    const transformedCategory = {
      sensitiveFieldCategory: dataMapFilePopUpData['Contents.category'],
      sensitiveFieldType: selectedCategory.code,
    };

    dispatch({ type: SAVE_FILE_CATEGORIES, payload: { id: sensitivityId, category: transformedCategory } });
    setIsEditMode(false);
  };

  // Filter out already selected categories from the options list
  const availableCategories = allCategories.filter(
    (category) => selectedCategory?.code !== category.code
  );

  const renderViewMode = () => {
    return (
      <div className="sensitivity-field">
        <div className="severity-field">
          <span className="field-header">CATEGORY:</span>
          <div className="categoryIcons">
            {renderIcons(finalFileType)}
          </div>
        </div>
        <CanAllow needs={{ permission: [UPDATE_SENSITIVITY_INFO_CATEGORY] }}>
          <ColorButtonForToolBar
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setIsEditMode(true)}
            className="min-margin"
          >
            <EditOutlinedIcon /> Edit Category
          </ColorButtonForToolBar>
        </CanAllow>
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="sensitivity-field">
        <div className="severity-field">
          <span className="field-header">CATEGORY:</span>
          <div className="categorySelector">
            <Autocomplete
              id="fixed-tags-demo"
              options={availableCategories} // Filtered options
              getOptionLabel={(option) => option.value}
              value={selectedCategory}
              onChange={handleCategoryChange}
              renderOption={(props, option) => {
                const icon = categoryIcons[option.code];
                return (
                  <li {...props} className={classes.option}>
                    {icon && (
                      <img
                        src={icon}
                        alt={option.value}
                        className={classes.icon}
                      />
                    )}
                    {option.value}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Category"
                  classes={{ root: classes.autocompleteRoot }}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedCategory && categoryIcons[selectedCategory.code] ? (
                      <img
                        src={categoryIcons[selectedCategory.code]}
                        alt={selectedCategory.value}
                        className={classes.selectedIcon}
                      />
                    ) : null,
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="severity-field save-buttons">
          <ColorButtonForToolBar
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            disabled={!selectedCategory}
            className="min-margin"
          >
            Save
          </ColorButtonForToolBar>
          <ColorButtonForToolBar
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setIsEditMode(false);
              setSelectedCategory(null);
            }}
            className="min-margin"
          >
            Cancel
          </ColorButtonForToolBar>
        </div>
      </div>
    );
  };

  return isEditMode ? renderEditMode() : renderViewMode();
};

export default FileCategory;
