import React, { useState, useEffect } from "react";
import SignUpBackGround from "../BackOfficeGeneralContainer/SignupPageBackGround";
import "../../../assets/scss/TenantMangement.scss";
import { useSelector, useDispatch } from "react-redux";
import EnhancedTable from "../../shared/alertsTable";
import { makeStyles } from "@material-ui/core/styles";
import { headCellsForTenantList } from "../../common/headCells";
import {
  getAllTenants,
  getAllDeletedTenants,
} from "../../../redux/actions/getAllTenantsAction";
import { PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE } from "../../../redux/constants/constants";
import SnackBar2 from "../../../components/shared/SnackBar2";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";

const TenantManagement = () => {
  const dispatch = useDispatch();
  const [tenantRows, setTenantRows] = useState([]);
  const [deletedTenantRows, setDeletedTenantRows] = useState([]);
  const [callApi, setCallApi] = useState(true);
  const [tenantsActive, setTenantsActive] = useState(true);
  const [deletedTenantsActive, setDeletedTenantsActive] = useState(false);
  const [errorObject, setErrorMessage] = useState({
    errorMessage: "",
    successMessage: "",
  });
  const [loader, setLoader] = useState(false);

  const activeToggle = (payload) => {
    if (payload === "tenants") {
      setTenantsActive(true);
      setDeletedTenantsActive(false);
    } else if (payload === "deleted-tenants") {
      setTenantsActive(false);
      setDeletedTenantsActive(true);
    }
  };

  useEffect(() => {
    async function getPlatformAllTenants() {
      setLoader(true);
      const res = await getAllTenants();
      if (res && res.content) {
        setTenantRows(res.content);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
    if (callApi) {
      getPlatformAllTenants();
    }
    return () => {
      setCallApi(false);
    };
  }, [callApi]);

  useEffect(() => {
    async function getPlatformAllDeletedTenants() {
      setLoader(true);
      const res = await getAllDeletedTenants();
      if (res && res.content) {
        setDeletedTenantRows(res.content);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
    if (deletedTenantsActive) {
      getPlatformAllDeletedTenants();
    }
    return () => {
      setCallApi(false);
    };
  }, [deletedTenantsActive]);

  //used for error handling and closing the backdrop
  const tenantCreation = useSelector(
    (state) => state.tenantCreate.tenantCreation
  );
  useEffect(() => {
    if (tenantCreation.successMessage) {
      setErrorMessage({
        errorMessage: "",
        successMessage: tenantCreation.successMessage,
      });
      dispatch({ type: PLATFORM_ADMIN_TENANT_ONBOARD_CLEAR_MESSAGE });
    }
  }, [tenantCreation]);

  return (
    <div>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source tenantPlatform"
        ></img>
      )}
      <SnackBar2
        failure={errorObject.errorMessage ? true : false}
        success={errorObject.successMessage ? true : false}
        errorObject={errorObject}
        successMessage={errorObject.successMessage}
        errorMessage={errorObject.errorMessage}
      ></SnackBar2>
      <SignUpBackGround headerName="Tenant Management"></SignUpBackGround>
      <div className="header_toggler">
        <div
          className= {tenantsActive ? "header_toggler_active" : "header_toggler_inactive"}
          onClick={() => activeToggle("tenants")}
        >
          Tenants
        </div>
        <div
          className={deletedTenantsActive ? "header_toggler_active" : "header_toggler_inactive"}
          onClick={() => activeToggle("deleted-tenants")}
        >
          Deleted Tenants
        </div>
      </div>

      <div className="filter-table-container">
        <div className="table-container">
          {tenantsActive && (
            <EnhancedTable
              tableData={tenantRows}
              headCells={headCellsForTenantList}
              check={true}
              num={tenantRows.length}
              setCallApi={setCallApi}
              loc="tenant-management-Tenants"
            />
          )}
          {deletedTenantsActive && (
            <EnhancedTable
              tableData={deletedTenantRows}
              headCells={headCellsForTenantList}
              check={true}
              loc="tenant-management-Deleted"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TenantManagement;
