import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Popup from "../signUpPages/Popup";
import "../../../assets/scss/signuppage-styles/signuppage.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selfSignUpSubmit } from "../../../redux/actions/signUppageAction";
import { TENANTSIGNUPPAGE_Submit } from "../../../redux/actions/TenantSignUpAction";
import { PlatformAdminTenantOnboard } from "../../../redux/actions/platformAdminTenantOnBoardAction";
import { PlatformAdminTenantUpdate } from "../../../redux/actions/platformAdminTenantUpdateAction";
import {
  CompleteButton,
  ColoredActionButton,
} from "../../common/buttons";
import unCheckedBox from "../../../assets/vizr_images/Input_cell.svg";
import checkedBox from "../../../assets/vizr_images/Active_Input.svg";

import { Emailvalidator } from "../../views/Util/Emailvalidator";
import { isEmpty } from "lodash";

const SignUpPageFooter = ({
  classname,
  popClassName,
  id,
  signUpObj,
  signUpactive,
  makeSignUpAcitive,
  privacyPolicy,
  setprivacyPolicy,
  license,
  setLicense,
  platformAdminTenantOnBoard,
  updateTenantId,
  fileUpload,
  setDomainMismatchError,
  errorObj,
  setErrorObj,
  commonDomain,
  domainsArr,
  createTenant,
  mdr,
  noFeaturesSelected,
  setOpenBackdrop = { setOpenBackdrop },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const s3Details = useSelector((state) => state.tenantSignUpReducer.s3Details);
  const {isNTFSSelected} = s3Details;
  const isS3Verified = useSelector((state) => state.tenantSignUpReducer.isS3Verified)

  const signUpcheckBoxClick = () => {
    makeSignUpAcitive(() => !signUpactive);
  };
  const privacyPolicyClickHanlder = (name) => {
    if (
      name === "privacyPolicy" &&
      privacyPolicy === false &&
      license === false
    ) {
      setprivacyPolicy(() => !privacyPolicy);
      document.getElementById("cross").style.display = "block";
    } else if (
      name === "license" &&
      license === false &&
      privacyPolicy === false
    ) {
      setLicense(() => !license);
      document.getElementById("cross").style.display = "block";
    }
  };
  const cancelClickHanlder = (name) => {
    if (name === "privacyPolicy") {
      setprivacyPolicy(() => !privacyPolicy);
    } else if (name === "license") {
      setLicense(() => !license);
    }
    document.getElementById("cross").style.display = "none";
  };
  const signUpClickHandler = () => {
    if (id === "selfSignup" && signUpactive) {
      let isInvalid = false;
      let errObj = {};
      if (!signUpObj.adminEmail || !signUpObj.domain || !signUpObj.tenantName) {
        isInvalid = true;
        errObj["emailError"] = !signUpObj.adminEmail
          ? "Please enter Email"
          : "";
        errObj["domainError"] = !signUpObj.domain ? "Please enter Domain" : "";
        errObj["tenantNameError"] = !signUpObj.tenantName
          ? "Please enter Tenant name"
          : "";
      } else if (!signUpObj.adminEmail.match(Emailvalidator)) {
        isInvalid = true;
        errObj["setErrorObj"] = "Please enter valid Email";
      }
      if (signUpObj.provisionDbType === "Existing_RDS") {
        if (!signUpObj.databaseConfiguration.host) {
          isInvalid = true;
          errObj["host_error"] = "Please enter host name";
        }
        if (!signUpObj.databaseConfiguration.username) {
          isInvalid = true;
          errObj["username_error"] = "Please enter username";
        }
        if (!signUpObj.databaseConfiguration.password) {
          isInvalid = true;
          errObj["password_error"] = "Please enter password";
        }
      }
      if (isInvalid) {
        setErrorObj({
          ...errorObj,
          errObj,
        });
      } else {
        dispatch(
          selfSignUpSubmit({
            name: signUpObj.tenantName,
            platformSubdomainName: signUpObj.domain,
            licenseTypes: [],
            domains: [signUpObj.domain],
            administrator: {
              firstName: signUpObj.firstName,
              lastName: signUpObj.lastName,
              email: signUpObj.adminEmail,
              phone: signUpObj.phoneNumber,
            },
            provisionDbType: signUpObj.provisionDbType,
            databaseConfiguration: {
              host: signUpObj.host,
              port: signUpObj.port,
              username: signUpObj.username,
              password: signUpObj.password,
              databaseEngine: { code: "POSTGRESQL", value: "PostgreSQL" },
            },
          })
        );
      }
    } else if (id === "tenantSignUp" && signUpactive) {
      dispatch(
        TENANTSIGNUPPAGE_Submit({
          name: signUpObj.tenantName,
          platformSubdomainName: domainsArr[0],
          licenseTypes: [],
          domains: signUpObj.domainsArr,
          administrator: {
            firstName: signUpObj.firstName,
            lastName: signUpObj.lastName,
            email: signUpObj.adminEmail,
            phone: signUpObj.phoneNumber,
          },
          provisionDbType: signUpObj.provisionDbType,

          databaseConfiguration: {
            host: signUpObj.host,
            port: signUpObj.port,
            username: signUpObj.username,
            password: signUpObj.password,
            databaseEngine: { code: "POSTGRESQL", value: "PostgreSQL" },
          },
        })
      );
    } else if (platformAdminTenantOnBoard) {
      let isInvalid = false;
      let errObj = {};
      if (
        !signUpObj.adminEmail ||
        !signUpObj.domain[0] ||
        !signUpObj.tenantName
      ) {
        isInvalid = true;
        if (mdr !== "") {
          errObj["email"] = !signUpObj.adminEmail ? "Please Enter Email" : "";
          errObj["Domain"] = !signUpObj.domain[0] ? "Please Enter Domain" : "";
          errorObj["tenantRegion"] = !signUpObj.tenantSelectedRegion ? "Region should not be empty" : "";
          errObj["tenantName"] = !signUpObj.tenantName
            ? "Please Enter Tenant Name"
            : "";
          errObj["shortName"] = !signUpObj.shortName
            ? "Please enter short name"
            : errorObj.shortName
            ? errorObj.shortName
            : signUpObj.shortName.length !== 3
            ? "Short name can have a minimum and maximum of 3 characters"
            : "";
        } else {
          errObj["email"] = !signUpObj.adminEmail ? "Please Enter Email" : "";
          errObj["Domain"] = !signUpObj.domain[0] ? "Please Enter Domain" : "";
          errorObj["tenantRegion"] = !signUpObj.tenantSelectedRegion ? "Region should not be empty" : "";
          errObj["tenantName"] = !signUpObj.tenantName
            ? "Please Enter Tenant Name"
            : "";
          if (isNTFSSelected) {
            const bucketName = signUpObj.s3Details?.s3BucketName;
            if (isEmpty(bucketName)) {
              errObj["s3BucketName"] = "Please enter s3 bucket name";
            } else if (
              !isEmpty(bucketName) &&
              (bucketName.length < 3 || bucketName.length > 63)
            ) {
              errObj["s3BucketName"] =
                "Bucket Name must be between 3 and 63 characters long";
            } else {
              errObj["s3BucketName"]="";
            }
            errObj["accessKey"] = !signUpObj.s3Details?.accessKey
              ? "Please enter access key"
              : "";
            errObj["secretKey"] = !signUpObj.s3Details?.secretKey
              ? "Please enter secret Key"
              : "";
          }
        }
      } else if (!signUpObj.adminEmail.match(Emailvalidator)) {
        isInvalid = true;
        errObj["email"] = "Please Enter Valid Email";
        if (mdr !== "" && !signUpObj.shortName) {
          errObj["email"] = !signUpObj.adminEmail.match(Emailvalidator)
            ? "Please Enter Valid Email"
            : "";
          errObj["shortName"] = !signUpObj.shortName
            ? "Please enter short name"
            : errorObj.shortName
            ? errorObj.shortName
            : signUpObj.shortName.length !== 3
            ? "Short name can have a minimum and maximum of 3 characters"
            : "";
        } else if (mdr !== "" && signUpObj.shortName.length !== 3) {
          errObj["email"] = !signUpObj.adminEmail.match(Emailvalidator)
            ? "Please Enter Valid Email"
            : "";
          errObj["shortName"] =
            "Short name can have a minimum and maximum of 3 characters";
        }
      } else if (mdr !== "" && !signUpObj.shortName) {
        isInvalid = true;
        errObj["shortName"] = !signUpObj.shortName
          ? "Please enter short name"
          : "";
      } else if (mdr !== "" && signUpObj.shortName.length !== 3) {
        isInvalid = true;
        errObj["shortName"] =
          "Short name can have a minimum and maximum of 3 characters";
      } else if (mdr !== "" && errorObj.shortName) {
        isInvalid = true;
        errObj["shortName"] = errorObj.shortName;
      } else if (signUpObj.features.length === 0) {
        isInvalid = true;
        noFeaturesSelected("create");
      }
  //giving the action type which is manual named as EXISTING_RDS checking the validation
      if (signUpObj.provisionDbType === "EXISTING_RDS") {
        if (!signUpObj.databaseConfiguration.host) {
          isInvalid = true;
          errObj["Host_error"] = "Please enter host name";
        }
        if (!signUpObj.databaseConfiguration.username) {
          isInvalid = true;
          errObj["Username_error"] = "Please enter username";
        }
        if (!signUpObj.databaseConfiguration.password) {
          isInvalid = true;
          errObj["Password_error"] = "Please enter password";
        }
        if (!signUpObj.databaseConfiguration.port) {
          isInvalid = true;
          errObj["Port_error"] = "Please enter port";
        }
      }
      if (isInvalid) {
        setErrorObj({
          ...errorObj,
          ...errObj,
        });
      } else {
        // tenant creation
        setOpenBackdrop(true);
        let arg = {};
        if (mdr !== "") {
          arg["shortName"] = signUpObj.shortName;
        }
        let payload = {
          name: signUpObj.tenantName,
          platformSubdomainName: domainsArr[0],
          features: signUpObj.features,
          domains: domainsArr,
          ...arg,
          windowsDomain: signUpObj.windows_domain,
          administrator: {
            firstName: signUpObj.firstName,
            lastName: signUpObj.lastName,
            email: signUpObj.adminEmail,
            phone: signUpObj.phoneNumber,
          },
          provisionDbType: signUpObj.provisionDbType,
          timezone: signUpObj.tenantSelectedRegion,
        };
          if(signUpObj.provisionDbType === "EXISTING_RDS"){
            payload.databaseConfiguration =  {
              databaseEngine: { code: "POSTGRESQL", value: "PostgreSQL" },
              host: signUpObj.databaseConfiguration.host,
              port: signUpObj.databaseConfiguration.port,
              username: signUpObj.databaseConfiguration.username,
              password: signUpObj.databaseConfiguration.password,
            }
          }
          if (isNTFSSelected) {
            payload.tenantS3Configuration = {
              bucketName: signUpObj.s3Details.s3BucketName,
              accessKey: signUpObj.s3Details.accessKey,
              secretKey: signUpObj.s3Details.secretKey,
            };
          }
        dispatch(
          PlatformAdminTenantOnboard({
            tenantDetails:payload,
            image: fileUpload,
            history: history,
          })
        );
      }
    } else if (updateTenantId !== "") {
      let arg = {};
      if (mdr !== "") {
        arg["shortName"] = signUpObj.shortName;
      } else if (signUpObj.features.length === 0) {
        noFeaturesSelected("update");
        return;
      }
      const tenantDetails = {
        name: signUpObj.tenantName,
        platformSubdomainName: signUpObj.platformSubdomainName,
        features: signUpObj.features,
        ...arg,
        windowsDomain: signUpObj.windows_domain,
        administrator: {
          firstName: signUpObj.firstName,
          lastName: signUpObj.lastName,
          email: signUpObj.adminEmail,
          phone: signUpObj.phoneNumber,
        },
      };
      
      // Conditionally add tenantS3Configuration if isNTFSSelected is true
      if (isNTFSSelected) {
        tenantDetails.tenantS3Configuration = {
          bucketName: signUpObj.s3Details.s3BucketName,
          accessKey: signUpObj.s3Details.accessKey,
          secretKey: signUpObj.s3Details.secretKey,
        };
      }
      
      dispatch(
        PlatformAdminTenantUpdate({
          tenantDetails,
          id: updateTenantId,
          image: fileUpload,
          history: history,
        })
      );
    }
  };

  const signupCancelHandler = () => {
    if (updateTenantId) {
      history.push("/platform/tenantManagement");
    } else if (createTenant) {
      history.push("/platform/tenantManagement");
    }
  };

  const handleDoneButtonDisable = () => {
    // Return false if NTFS is not selected
    if (!isNTFSSelected) {
      return false;
    }
    // Return true if NTFS is selected but S3 is not verified
    if (!isS3Verified) {
      return true;
    }
    // Return false if NTFS is selected and S3 is verified
    return false;
  };

  return (
    <Grid item lg={12} md={12} sm={10} xs={10}>
      {updateTenantId ? (
        <div className="tenant-edit-footer">
          <ColoredActionButton
            variant="contained"
            color="primary"
            onClick={signupCancelHandler}
          >
            Cancel
          </ColoredActionButton>
          &nbsp;
          <div
            title={
              handleDoneButtonDisable() ? "Please Verify your S3 details" : ""
            }
            className={handleDoneButtonDisable() ? "signUpNotAllowed" : "signUpPointer"}
          >
            <CompleteButton
              variant="contained"
              color="primary"
              disableElevation
              disabled={handleDoneButtonDisable()}
              onClick={signUpClickHandler}
            >
              Done
            </CompleteButton>
          </div>
        </div>
      ) : createTenant ? (
        <div className="tenant-edit-footer">
          <ColoredActionButton
            variant="contained"
            color="primary"
            onClick={signupCancelHandler}
          >
            Cancel
          </ColoredActionButton>
          &nbsp;
          <div
            title={
              handleDoneButtonDisable() ? "Please Verify your S3 details" : ""
            }
            className={handleDoneButtonDisable() ? "signUpNotAllowed" : "signUpPointer"}
          >
            <CompleteButton
              variant="contained"
              color="primary"
              disableElevation
              disabled={handleDoneButtonDisable()}
              onClick={signUpClickHandler}
            >
              Submit
            </CompleteButton>
          </div>
        </div>
      ) : (
        <div className={classname ? classname : "signUp_footer"}>
          <div>
            {signUpactive ? (
              <img
                className="signUp_footerCheckbox"
                src={checkedBox}
                alt="checkBox"
                onClick={() => signUpcheckBoxClick()}
              ></img>
            ) : (
              <img
                className="signUp_footerCheckbox"
                src={unCheckedBox}
                alt="checkBox"
                onClick={() => signUpcheckBoxClick()}
              ></img>
            )}
          </div>
          <div>
            <div className="SignUpFooterCommonStyle">
              By clicking "Sign Up", you agree to the{" "}
              <span>
                <span
                  className="signUp_footerPopUp SignUpFooterCommonStyle"
                  onClick={() => privacyPolicyClickHanlder("license")}
                >
                  License
                </span>
              </span>{" "}
              and{" "}
              <span>
                <span
                  className="signUp_footerPopUp SignUpFooterCommonStyle"
                  onClick={() => privacyPolicyClickHanlder("privacyPolicy")}
                >
                  Privacy Policy
                </span>
              </span>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={
                  signUpactive ? "signUp_Button" : "signUp_ButtonDisabled"
                }
                onClick={signUpClickHandler}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      )}

      <Popup
        cancelClickHanlder={() =>
          cancelClickHanlder(privacyPolicy ? "privacyPolicy" : "license")
        }
        Name={privacyPolicy ? "Privacypolicy" : "License"}
        popClassName={popClassName}
      ></Popup>
    </Grid>
  );
};

export default SignUpPageFooter;