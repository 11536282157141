import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from 'material-ui-core';
import { GET_CRITICALITY, desiredOrderCriticality, criticalityBGColorMap, criticalityBarColorMap, criticalityMap } from './NetSpaceConstant';
import styled from "styled-components";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
    CircularProgressbar,
    buildStyles,
} from "react-circular-progressbar";
import { percentageCalculation } from '../../components/common/utilities';

const CustomCard = styled(Card)(({ color }) => ({
    backgroundColor: color,
    borderRadius: '2px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    fontWeight: 'bold',
    height: '190.34px'
}));

const CriticalityCard = ({ type, percentage, value, since, color, barColor }) => {
    percentage = percentage || 0;
    return (
        <CustomCard color={color} height={192.33}>
            <CardContent sx={{ textAlign: 'left' }}>
                <a className="a-tag-none" href='https://nodezero.druvstar.net/investigate/hosts' target="_blank" rel="noopener noreferrer">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                Host/s With {type} Critical Port/s
                            </Typography>
                            <Typography variant="h3" component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                                {percentage}%
                            </Typography>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                {value}
                            </Typography>
                            {since>0? (
                            <Typography variant="body2" color="primary" sx={{ mt: 1, fontWeight: 'bold' }}>
                                +{since}% from yesterday
                            </Typography>
                        ): null}
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}%`}
                                    styles={buildStyles({
                                        pathColor: `${barColor}`,
                                    })}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </a>
            </CardContent>
        </CustomCard>
    );
};

function Criticality() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_CRITICALITY,
        });
    }, []);
    const criticality = useSelector(
        (state) => state.NetspaceReducer.criticality
    );
    
    if (criticality) {
        const modifiedCriticality = []
        desiredOrderCriticality.forEach(element => {
            modifiedCriticality.push({
                count: criticality[element],
                type: element
            })
        });
        const finalPercentage = percentageCalculation(modifiedCriticality, criticality['totalHosts']);
        const percentage = {};
        desiredOrderCriticality.forEach(element => {
            percentage[element] = finalPercentage.find((val) => val.type === element).count;
        });
        return (
            <Grid xs={6} spacing={3} className="mrg-left-auto">
                <Grid container spacing={3} className="mrg-left-auto">
                    {desiredOrderCriticality.map((critical) => {
                        if (critical in criticality) {
                            const type = criticalityMap[critical]
                            return (
                                <Grid item xs={4}>
                                    <CriticalityCard type={type} percentage={percentage[critical]} value={criticality[critical]} since={0} color={criticalityBGColorMap[critical]} barColor={criticalityBarColorMap[critical]} />
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid item xs={12}>
                                    <div className={critical}>No Data</div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid xs={6} spacing={3} className="mrg-left-auto">
                <Grid container spacing={3} className="mrg-left-auto">
                    <Grid item xs={12}>
                        <div>No Data</div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Criticality;