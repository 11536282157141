import React from 'react';
import NMapFileUpload from './NmapFileUpload';
import NetSpaceSummary from './NetSpaceSummary';
import "./NetSpace.scss"
import { Grid } from 'material-ui-core';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from '@material-ui/icons/Edit';
import { SET_NETSPACE_SNACKBAR, SET_FILE_EDITABLE, FETCH_FILE_STATUS_CANCEL, SET_RELOAD_NETSPACE, GET_ACTIVE_NETMAP, GET_NETMAP_STATUS } from "./NetSpaceConstant";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ControlPanel from './ControlPanel';
import NetSpaceDrawer from './NetSpaceDrawer';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, CircularProgress, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

const NetSpace = () => {
    const dispatch = useDispatch();
    // const [isEditable, setIsEditable] = useState(false);
    const loader = useSelector((state) => state.NetspaceReducer.loader);
    const isEditable = useSelector((state) => state.NetspaceReducer.isFileEditable);
    const snackBarMessage = useSelector(
        (state) => state.NetspaceReducer.snackBarMessage
    );
    const snackBar = useSelector((state) => state.NetspaceReducer.snackBar);
    const nmapProgress = useSelector((state) => state.NetspaceReducer.nmapProgressData);
    const activeNetMap = useSelector((state) => state.NetspaceReducer.activeNetMap);
    const reloadNetspace = useSelector((state) => state.NetspaceReducer.reloadNetspace);
    const [openError, setopenError] = useState(false);
    const [errorMessage, setErrorMesage] = useState("");
    const [runName, setRunName] = useState("");
    const [networkName, setNetworkName] = useState("");

    useEffect(() => {
        dispatch({
            type: GET_ACTIVE_NETMAP,
        });
        dispatch({
            type: GET_NETMAP_STATUS,
        });
        return () => {
            dispatch({
                type: FETCH_FILE_STATUS_CANCEL,
            });
        };
    }, []);

    useEffect(() => {
        setRunName(nmapProgress?.runName);
        setNetworkName(nmapProgress?.networkName);
    }, [nmapProgress]);

    useEffect(() => {
        setopenError(snackBar);
        setErrorMesage(snackBarMessage);
    }, [snackBar]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setopenError(false);
        dispatch({
            type: SET_NETSPACE_SNACKBAR,
            payload: { status: false, message: "" },
        });
    };

    const handleEditClick = (bool) => {
        dispatch({
            type: FETCH_FILE_STATUS_CANCEL,
        });
        dispatch({
            type: SET_FILE_EDITABLE,
            payload: bool,
        });
    };

    const reloadPage = () => {
        dispatch({
            type: SET_RELOAD_NETSPACE,
            payload: false,
        })
        window.location.reload();
    };

    function getProcessingTimeMessage(fileSizeInBytes) {
        const baseSize = 4231675; // bytes
        const baseTime = 45; // seconds
        const estimatedTime = (fileSizeInBytes / baseSize) * baseTime;
        const minutes = Math.floor(estimatedTime / 60);
        const seconds = Math.ceil(estimatedTime % 60);
        if (minutes > 0) {
            return `Estimated time: ${minutes} minute(s) and ${seconds} second(s)`;
        } else {
            return `Estimated time: ${seconds} second(s)`;
        }
    }

    const renderStatus = () => {
        const status = nmapProgress?.status?.code;
        if (status === 'UPLOADED') {
            return (
                <Box display="flex" alignItems="center">
                    <CheckCircleIcon className='color-green' style={{ color: 'green' }} />
                    <Typography variant="body1" className='mrg-left-8'>
                        Uploaded {getProcessingTimeMessage(nmapProgress?.size || 0)}
                    </Typography>
                </Box>
            );
        } else if (status === 'INPROGRESS') {
            return (
                <Box display="flex" alignItems="center">
                    <CircularProgress size={24} />
                    <Typography variant="body1" className='mrg-left-8'>
                        In Progress {getProcessingTimeMessage(nmapProgress?.size || 0)}
                    </Typography>
                </Box>
            );
        } else if (status === 'PROCESSED') {
            return (
                <Box display="flex" alignItems="center">
                    <CheckCircleIcon className='color-blue' />
                    <Typography variant="body1" className='mrg-left-8'>
                        Processed
                    </Typography>
                </Box>
            );
        } else if (status === 'ERROR') {
            return (
                <Box display="flex" alignItems="center">
                    <ErrorIcon className='color-red' />
                    <Typography variant="body1" className='mrg-left-8'>
                        Error
                    </Typography>
                </Box>
            );
        } else {
            return null;
        }
    }

    const PaperComponent = (props) => {
        return (
            <Paper {...props} />
        );
    }

    const renderReloadDialog = () => {
        if (reloadNetspace) {
            return <Dialog
                open={reloadNetspace}
                onClose={reloadPage}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle id="draggable-dialog-title">
                    Reload
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color='black'>
                        N-map file processing is completed. Please reload the page to view the changes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={reloadPage} color="primary">
                        Reload
                    </Button>
                </DialogActions>
            </Dialog>
        }
        return null;
    };

    const renderSnackBar = () => {
        return (
            <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                className="error-snackbar"
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
        );
    };
    return (
        <div className="main netspace-wrapper">
            <NetSpaceDrawer />
            {loader && (
                <img
                    src={Page_Loader}
                    alt="loading"
                    className="loader-data-source tenantProfileLoader"
                ></img>
            )}
            <div className="netspace-header">
                <Grid container>
                    <Grid item xs={7}>
                        <h2>NetSpace</h2>
                        <h3>Network - {activeNetMap?.networkName || ''}    Run - {activeNetMap?.runName || ''}</h3>
                    </Grid>
                    <Grid item xs={5}>
                        <div className='input-container'>
                            <div className='input-child'>
                                <TextField id="outlined-basic" label="Network Name:" variant="filled"
                                    disabled={!isEditable}
                                    value={networkName}
                                    type="text"
                                    className="InputTextFeild"
                                    onChange={(event) => {
                                        setNetworkName(event.target.value)
                                    }}
                                />
                            </div>
                            <div className='input-child'>
                                <TextField id="outlined-basic" label="Run Name:" variant="filled"
                                    disabled={!isEditable}
                                    value={runName}
                                    type="text"
                                    className="InputTextFeild"
                                    onChange={(event) => {
                                        setRunName(event.target.value)
                                    }}
                                />
                            </div>
                            {!isEditable && <div className='input-child'>{renderStatus()}</div>}
                            {isEditable && <div className='input-child'>
                                <NMapFileUpload runName={runName} networkName={networkName} />
                            </div>}
                            <div className='input-child short-width'>
                                {isEditable ?
                                    <CloseIcon onClick={() => handleEditClick(false)} className='edit-nmap' tooltip='Close Edit' /> :
                                    <EditIcon onClick={() => handleEditClick(true)} className='edit-nmap' tooltip='Edit' />}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='netspace-body'>
                <NetSpaceSummary />
                <ControlPanel />
            </div>
            {renderReloadDialog()}
            {renderSnackBar()}
        </div>
    );
};

export default NetSpace;