import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@mui/material";
import ChevronRightIcon from '@material-ui/icons/ChevronRight'; // Chevron icon
import CloseIcon from '@material-ui/icons/Close'; // Close icon
import {
  CLEAR_ALL_FOLDER_DATA,
  FETCH_ALL_FOLDERS_DATA,
  SET_FOLDER_CURRENT_PAGE,
  SET_FOLDER_CURRENT_SIZE,
  SET_NTFS_LOADER,
} from "../views/NTFS/ntfsConstants";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { isEmpty, isNull } from "lodash";

// Define styles using makeStyles
const useStyles = makeStyles({
  folderIcon: {
    marginRight: "10px",
    color: "#5a67d8",
  },
  folderCell: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  userCell: {
    display: "flex",
    alignItems: "flex-start",
    gap: "5px",
  },
  userIcon: {
    marginRight: 4,
    cursor: "pointer",
  },
  popup: {
    padding: "10px",
    minWidth: "150px",
    maxWidth: "350px",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: "relative", // Position relative for CloseIcon
  },
  closeIcon: {
    position: "absolute", // Absolute position to the top right
    top: 5,
    right: 5,
    cursor: "pointer",
    color: "black", // Set CloseIcon color
  },
  popupHeader: {
    fontSize: "15px",
    fontWeight: "700",
  },
  popupName: {
    fontSize: "15px",
    cursor: "pointer", // Make usernames clickable
  },
  moreLink: {
    marginLeft: "auto",
    color: "#5a67d8",
    cursor: "pointer",
  },
  chevronIcon: {
    cursor: "pointer",
    marginLeft: "8px",
    color: "black", // Set Chevron icon color
  },
  remainingUserPopup: {
    padding: "10px",
    minWidth: "200px",
  },
  userRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Align username and Chevron icon to the right
  },
  popUpContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  }
});

const UserFolders = ({ selectedNode }) => {
  const allFoldersData = useSelector((state) => state.ntfsReducer.allFoldersData);
  const currentPage = useSelector((state) => state.ntfsReducer.currentPage);
  const pageSize = useSelector((state) => state.ntfsReducer.pageSize);
  const totalFolderCount = useSelector((state) => state.ntfsReducer.totalFolderCount);
  const loader = useSelector((state) => state.ntfsReducer.loader);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowData, setRowData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupData, setPopupData] = useState({ username: "", permissions: [] });
  const [remainingUserAnchor, setRemainingUserAnchor] = useState(null);
  const [remainingUsers, setRemainingUsers] = useState([]);

  useEffect(() => {
    if (allFoldersData.apiSuccess) {
      setRowData(allFoldersData.data);
      dispatch({ type: SET_NTFS_LOADER, payload: false });
    }
  }, [allFoldersData]);

  useEffect(() => {
    dispatch({ type: SET_NTFS_LOADER, payload: true });
    dispatch({
      type: SET_FOLDER_CURRENT_PAGE,
      payload: paginationModel.page,
    });
    dispatch({
      type: SET_FOLDER_CURRENT_SIZE,
      payload: paginationModel.pageSize,
    });

    dispatch({
      type: FETCH_ALL_FOLDERS_DATA,
      payload: {
        id: selectedNode,
        currentPage: paginationModel.page,
        pageSize: paginationModel.pageSize,
      },
    });
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_ALL_FOLDER_DATA });
    };
  }, []);

  const handlePaginationChange = (params) => {
    setPaginationModel((prevState) => ({
      ...prevState,
      page: params.page,
      pageSize: params.pageSize,
    }));
  };

  const handleUserIconClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setPopupData(user);
  };

  const handleRemainingUserClick = (event, users) => {
    setRemainingUserAnchor(event.currentTarget);
    setRemainingUsers(users);
  };

  const handleChevronClick = (event, user) => {
    event.stopPropagation(); // Prevent popup from closing
    handleUserIconClick(event, user); // Show the same popup for chevron click
  };

  const handleClosePopup = () => {
    setAnchorEl(null); // Close the user popup
  };

  const handleCloseRemainingUserPopup = () => {
    setRemainingUserAnchor(null); // Close the remaining user popup
  };

  const open = Boolean(anchorEl);
  const openRemainingUsers = Boolean(remainingUserAnchor);

  const imageCount = (count) => {
    if (count > 0) {
      return (
        <div className="circle ntfs">
          <span className="circleText" title="Click to view remaining users">{`+${count}`}</span>
        </div>
      );
    } else {
      return "";
    }
  };

  // Columns for DataGrid
  const columns = [
    {
      field: "folderName",
      headerName: "Folder Name",
      width: 400,
      renderCell: (params) => (
        <div className={classes.folderCell}>
          <span className={classes.folderIcon}>
            <img src={"/ntfsIcons/ntfsFolderIcon.svg"} alt="folder" />
          </span>
          {params.value}
        </div>
      ),
    },
    {
      field: "userCount",
      headerName: "Users",
      width: 850,
      renderCell: (params) => {
        const visibleUsers = params.value.slice(0, 20);
        const remainingUsersCount = params.value.length - 20;

        return (
          <div className={classes.userCell}>
            {visibleUsers.map((user, index) => (
              <span
                key={index}
                title="Click to view User Name and Permissions"
                className={classes.userIcon}
                onClick={(event) => handleUserIconClick(event, user)}
              >
                <img src={"/ntfsIcons/ntfsUserIcon.svg"} alt="user" />
              </span>
            ))}
            {remainingUsersCount > 0 && (
              <span
                className={classes.moreLink}
                onClick={(event) => handleRemainingUserClick(event, params.value.slice(4))}
              >
                {imageCount(remainingUsersCount)}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  // Process allFoldersData for DataGrid
  const rows =
    !isNull(rowData) && !isEmpty(rowData)
      ? rowData.map((folder, index) => ({
          id: index,
          folderName: folder.folderName,
          userCount: folder.userPermissions,
        }))
      : [];

  const renderTable = () => (
    <div className="custom-data-grid-main">
      <DataGrid
        className="custom-data-grid"
        rows={rows}
        columns={columns}
        rowCount={totalFolderCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        paginationMode="server"
        rowHeight={90}
        hideFooterSelectedRowCount
        getRowSpacing={(params) => ({
          top: 10,
          bottom: params.isLastVisible ? 0 : 5,
        })}
        disableRowSelectionOnClick
      />
    </div>
  );

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source ntfs"
        />
      )}
      {renderTable()}

      {/* Popover for user details on icon click */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.popup}>
          <CloseIcon className={classes.closeIcon} onClick={handleClosePopup} />{" "}
          {/* Close icon */}
          <span>
            <p className={classes.popupHeader}>
              User Name
            </p>
            {popupData.username}
          </span>
          <p className={classes.popupHeader}>Permissions</p>
          <ul>
            {popupData.permissions.map((perm, idx) => (
              <li key={idx}>{perm}</li>
            ))}
          </ul>
        </div>
      </Popover>

      {/* Popover for remaining users */}
      <Popover
        open={openRemainingUsers}
        anchorEl={remainingUserAnchor}
        onClose={handleCloseRemainingUserPopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={classes.remainingUserPopup}>
          <div className={classes.popUpContainer}>
            <div className={classes.popupHeader}>Users</div>
            <div>
              <CloseIcon
                className={classes.closeIcon}
                onClick={handleCloseRemainingUserPopup}
              />
            </div>
          </div>
          {remainingUsers.map((user, index) => (
            <div key={index} className={classes.userRow}>
              <span
                className={classes.popupName}
              >
                {user.username}
              </span>
              <ChevronRightIcon
                className={classes.chevronIcon}
                onClick={(event) => handleChevronClick(event, user)}
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default UserFolders;
