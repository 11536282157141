import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ClickAwayListener, MenuItem, Tab } from '@material-ui/core';
import CloseIcon from '../../assets/vizr_images/ic_remove.svg';
import { AppBar, Box, Tabs, Typography } from 'material-ui-core';
import PropTypes from 'prop-types';
import AllAccessFilePopUp from './AllAccessFilePopUp';
import SensitivityInformation from './SensitivityInformation';
import {FETCH_ALL_CATEGORIES, GET_OVERALL_CATEGORY, NTFS} from '../../redux/constants/constants';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import infoOutline from '../../assets/icons/info_outline.svg';
import contentCopy from '../../assets/icons/content_copy.svg';
import copy from 'copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { isCloudFileSystem } from '../common/utilities';
import CanAllow from './CanAllow';
import FileSensitivity from './dataMapSensitivtyAndCategory/fileSensitivity';
import FileCategory from './dataMapSensitivtyAndCategory/fileCategory';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#DFE1E9',
    color: 'rgb(0, 0, 0)',
    fontSize: '12px',
    lineHeight: '16px',
    marginRight: '20px',
    borderRadius: '20px',
    padding: '5px 15px',
    wordBreak: 'break-all',
  },
  arrow: {
    color: '#DFE1E9',
  },
}))(Tooltip);
export default function RenderDataMapFilePopUp({ sourceName }) {
  const cloudFileSystem = isCloudFileSystem(sourceName) || sourceName === NTFS;
  const dataMapFilePopUpData = useSelector(
    (state) => state.dataMapReducer.dataMapFilePopUpData
  );

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [fieldIdToolTip, setFieldIdToolTip] = useState(false);
  const [tableIdToolTip, setTableIdToolTip] = useState(false);
  const [open, setOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch({type: FETCH_ALL_CATEGORIES})
    dispatch({ type: GET_OVERALL_CATEGORY, payload: dataMapFilePopUpData['Contents.sensitivityId']})
  },[])
  const copyToClipboard = (copyText) => {
    copy(copyText);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const fieldIdTitle = () => {
    if (dataMapFilePopUpData['Contents.sensitivityId']) {
      return (
        <div className="tooltip-content">
          <span>ID: {dataMapFilePopUpData['Contents.sensitivityId']}</span>
          <div>
            <img
              className="copy-icon"
              onClick={() =>
                copyToClipboard(dataMapFilePopUpData['Contents.sensitivityId'])
              }
              src={contentCopy}
              alt="copy"
            />
          </div>
        </div>
      );
    } else {
      return <>ID: {renderNoData()}</>;
    }
  };
  const renderFieldToolTip = () => {
    return (
      <ClickAwayListener onClickAway={() => setFieldIdToolTip(false)}>
        <LightTooltip
          open={fieldIdToolTip}
          interactive={true}
          arrow
          className="id-tooltip"
          title={fieldIdTitle()}
        >
          <img
            onClick={() => {
              setFieldIdToolTip(!fieldIdToolTip);
              setOpen(false)
            }}
            src={infoOutline}
            alt="icon"
          />
        </LightTooltip>
      </ClickAwayListener>
    );
  };
  const tableIdTitle = () => {
    if (dataMapFilePopUpData['Contents.tableId']) {
      return (
        <>
          <div className="tooltip-content">
            <span>ID: ${dataMapFilePopUpData['Contents.tableId']}</span>
            <img
              className="copy-icon"
              onClick={() =>
                copyToClipboard(dataMapFilePopUpData['Contents.tableId'])
              }
              src={contentCopy}
              alt="copy"
            />
          </div>
        </>
      );
    } else {
      return <>ID: {renderNoData()}</>;
    }
  };
  const renderTableToolTip = () => {
    return (
      <ClickAwayListener onClickAway={() => setTableIdToolTip(false)}>
        <LightTooltip
          arrow
          interactive={true}
          open={tableIdToolTip}
          className="id-tooltip"
          title={tableIdTitle()}
        >
          <img
            onClick={() => {
              setTableIdToolTip(!tableIdToolTip);
              setOpen(false)
            }}
            src={infoOutline}
            alt="icon"
          />
        </LightTooltip>
      </ClickAwayListener>
    );
  };
  const renderNoData = () => {
    return <> - </>;
  };
  const renderFieldName = () => {
    if (sourceName === NTFS) {
      return "FOLDER:";
    } else if (cloudFileSystem) {
      if (dataMapFilePopUpData["Contents.tableType"] === "Personal") {
        return "DRIVE:";
      } else {
        return "SITE:";
      }
    } else {
      return "TABLE:";
    }
  };

  const renderFieldValue = () => {
    if (sourceName === NTFS) {
      return dataMapFilePopUpData["Contents.directoryName"];
    } else if (dataMapFilePopUpData["Contents.tableName"]) {
      return dataMapFilePopUpData["Contents.tableName"];
    } else {
      return renderNoData();
    }
  }
  
  return (
    <div className="filePopUp">
      <div className="filePopUp-section1">
        <div className="filePopUp-header">
          <div className="file-details-header">
            {cloudFileSystem ? "FILE DETAILS" : "FIELD DETAILS"}
          </div>
          <Button
            className="close-icon"
            onClick={(event) => {
              dispatch({ type: "CLOSE_FILE_POP_UP" });
            }}
            aria-label="close"
          >
            <img src={CloseIcon} alt={"close"} />
          </Button>
        </div>
        <div className="file-details-row">
          <div className="filePopUp-fieldName file-details-field">
            <span className="field-header">
              {cloudFileSystem ? "FILE:" : "FIELD:"}
            </span>
            {dataMapFilePopUpData["Contents.columnName"]
              ? dataMapFilePopUpData["Contents.columnName"]
              : renderNoData()}
          </div>
          {renderFieldToolTip()}
        </div>
        <div className="file-details-row">
          <div className="filePopUp-fieldName file-details-field">
            <span className="field-header">{renderFieldName()}</span>
            { renderFieldValue()}
          </div>
          {renderTableToolTip()}
        </div>
        <div className="file-details-row">
          {cloudFileSystem ? (
            <div className="filePopUp-fieldName file-owner">
              <span className="field-header">OWNER: </span>
              {dataMapFilePopUpData["Contents.fileOwner"] ? (
                <span className="userName">
                  {dataMapFilePopUpData["Contents.fileOwner"]
                    .split(" ")
                    .map((el) => {
                      return el[0];
                    })}
                </span>
              ) : null}
              <p title={dataMapFilePopUpData["Contents.fileOwner"]}>{dataMapFilePopUpData["Contents.fileOwner"]
                ? dataMapFilePopUpData["Contents.fileOwner"]
                : renderNoData()}</p>
            </div>
          ) : null}
          <div className="filePopUp-fieldName last-scan">
            <span className="field-header">LAST SCAN: </span>
            {dataMapFilePopUpData["Contents.lastDLPScanTime"]
              ? dataMapFilePopUpData["Contents.lastDLPScanTime"]
              : renderNoData()}
          </div>
        </div>
        {cloudFileSystem ? (
          <div className="access-time-details">
            <div className="filePopUp-fieldName access-details-field">
              <b>ACCESS TIME: </b>
            </div>
            <TableContainer className="access-details-container">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="access-details-table-cell access-details-header">
                      <span>FIRST</span>
                    </TableCell>
                    <TableCell className="access-details-table-cell access-details-header">
                      <span>LAST</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="access-details-table-cell">
                      {dataMapFilePopUpData["Contents.firstAccess"]
                        ? dataMapFilePopUpData["Contents.firstAccess"]
                        : renderNoData()}
                    </TableCell>
                    <TableCell className="access-details-table-cell">
                      {dataMapFilePopUpData["Contents.lastAccess"]
                        ? dataMapFilePopUpData["Contents.lastAccess"]
                        : renderNoData()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}
      </div>
      <hr className="separatorStyle" />
      <div className="filePopUp-fieldName">
        <div className="sensitivityCategory">
          <FileSensitivity filePopUpData={dataMapFilePopUpData} />
          <FileCategory />
        </div>
      </div>
      <hr className="separatorStyle" />
      <div className="filePopUp-tabs">
        <AppBar position="static" color="default">
          <CanAllow
            needs={{ permission: ["VIEW_ALL_ACCESSES"] }}
            component={
              <Tabs value={value} indicatorColor="primary" textColor="primary">
                <Tab label="Sensitivity Information" {...a11yProps(0)} />
              </Tabs>
            }
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Sensitivity Information" {...a11yProps(0)} />
              <Tab label="All Accesses" {...a11yProps(1)} />
            </Tabs>
          </CanAllow>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SensitivityInformation />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AllAccessFilePopUp />
        </TabPanel>
      </div>
      <div className="copied-snackbar">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} className="copied-alert">
            Copied Successfully
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
