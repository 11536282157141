import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GET_TENANT_REGIONS, SET_SELECTED_TENANT_REGION } from "./tenantSignUpConstants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tenantRegions: {
    marginTop: "15px",
    "& .MuiFormControl-fullWidth": {
      width: "76%",
    },
    "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense": {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  },
  regionHeader: {
    marginBottom: "5px",
    color: "#292929",
    width: "8rem",
    height: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: 0,
  },
  regionImp: {
    color: "red"
  }
}));

const TenantLocation = ({ errorObj, setErrorObj, tenantId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tenantRegions = useSelector((state) => state.tenantSignUpReducer.tenantRegions);
  const tenantSelectedRegion = useSelector((state) => state.tenantSignUpReducer.tenantSelectedRegion);

  useEffect(() => {
    dispatch({ type: GET_TENANT_REGIONS });
  }, [dispatch]);

  const handleRegionInput = (region) => {
    dispatch({ type: SET_SELECTED_TENANT_REGION, payload: region });
  };

  return (
    <div className={classes.tenantRegions}>
      <div className={classes.regionHeader}>
        Time Zone<span className={classes.regionImp}>*</span>
      </div>
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          options={tenantRegions}
          getOptionLabel={(option) => option.value}
          value={tenantSelectedRegion}
          disabled={tenantId}
          onChange={(event, newValue) => {
            handleRegionInput(newValue);
            setErrorObj({
              ...errorObj,
              tenantRegion: "",
            });
          }}
          filterOptions={(options, state) =>
            options.filter((option) =>
              option.value
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              disabled={tenantId}
              fullWidth
              error={errorObj.tenantRegion}
              helperText={errorObj.tenantRegion}
              placeholder="Regions"
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default TenantLocation;
