
import CardHeader from "@material-ui/core/CardHeader";
import styled from "styled-components";
import { visibilityLegend, icons, CHANGE_TAG_MODAL_STATUS } from './NetSpaceConstant';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import { isNull } from "lodash";
import AlertBadge from '../../components/shared/Badge/AlertBadge';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Paper from '@material-ui/core/Paper';
import NetSpaceUserTag from "./NetSpaceUserTag";

export const StyledCardHeader = styled(({ ...other }) => (
    <CardHeader {...other} />
))`
    & .MuiCardHeader-content {
      flex: 1 1 auto;
      width: 100%;
    }
    & .MuiTypography-h5 {
      font-size: 1rem;
      font-weight: bold;
    }
  `;
export const sensitivityColor = (sensitivity) => {
    switch (sensitivity.toUpperCase()) {
        case 'HIGH':
        case 'NO':
            return '#FF2251';
        case 'MEDIUM':
        case 'UNKNOWN':
            return '#FF8429';
        case 'LOW':
        case 'YES':
            return '#081981';
        default:
            return '#ffffff';
    }
}
export const renderCriticality = (color) => {
    return <div className={`circle-dot ${color || 'LOW'}`}></div>
}
export const renderIsMonitored = (params) => {
    let iconObj = visibilityLegend[2];
    if (params.row.isMonitored === 'NO') {
        iconObj = visibilityLegend[0]
        const className = 'item ' + iconObj.class
        return <span className={className} title={iconObj.label}><CancelIcon /></span>
    } else if (params.row.isMonitored === 'UNKNOWN') {
        iconObj = visibilityLegend[1]
        const className = 'item ' + iconObj.class
        return <span className={className} title={iconObj.label}><ErrorIcon /></span>
    }
    const className = 'item ' + iconObj.class
    return <span className={className} title={iconObj.label}><CheckCircleIcon /></span>
};
export const renderNull = () => {
    return (<>-</>);
};
export const renderAlerts = (params) => {
    let param = {
        alert_count_high: params.value?.high || 0,
        alert_count_medium: params.value?.medium || 0,
        alert_count_low: params.value?.low || 0
    }
    return (
        <div className="inspect-alerts dataOwner">
            <AlertBadge parentprops={param} InspectContent={true} />
        </div>
    );
};
export const renderHostIPFnWrapper = (dispatch, group=null) => (params) => {
    const handleOpenPopup = (e, tags, nodeId) => {
        e.stopPropagation();
        const payloadObj = { isTagModalOpen: true, selectedTagData: tags, selectedTagNodeId: nodeId};
        if (group!==null) {
            payloadObj.updateTagGroup = group;
        }
        dispatch({
            type: CHANGE_TAG_MODAL_STATUS,
            payload: payloadObj
        });
    };
    let finalIPTxt = '';
    if (params.row && params.row.networks && params.row.networks.length > 0) {
        params.row.networks.forEach((n, i) => {
            let ip = '',
                mac = '',
                end = '';
            if (n.ipv4) {
                ip = n.ipv4;
            }
            if (n.mac) {
                mac = ' (' + n.mac + ')';
            }
            if (i !== params.row.networks.length - 1) {
                end = ',';
            }
            if (ip || mac) {
                finalIPTxt = finalIPTxt + ip + mac + end;
            }
        })
        return <div className="host-name-container">
            <div className="host-icon">{renderCriticality(params.row.overallSensitivity)}</div>
            <div className="host-name" title={finalIPTxt}>{finalIPTxt || '-'}</div>
            <div className="host-add-tag"><AddCircleOutlineIcon className="add-tag" onClick={(e) => handleOpenPopup(e, params.row.userTags, params.row.nodeId)} /></div>
        </div>
    }
    return null;
}

export const renderHostType = (params) => {
    if (params.row.ports) {
        return params.row.ports.map((pdetails) => {
            const icon = pdetails.icon;
            if(icon){
                return (
                    <div className="inspect-alerts dataOwner">
                        <img src={pdetails.icon} alt={pdetails.hostType} title={pdetails.description} className={`dataOwner-icons`} />
                    </div>
                );
            } else{
                return (
                    <div className="inspect-alerts dataOwner">
                        <div title={pdetails.hostType}>{pdetails.hostType}</div>
                    </div>
                );
            }
        })
    }
    return null;
};

export const renderOS = (params) => {
    const icon = icons[params.row.os];
    const OSName = params.row.os || 'NA';
    if (icon){
        return (
            <div className="inspect-file-header dataOwner">
                <div title={OSName}><img src={icon} alt={OSName} className={`dataOwner-icons`} /></div>
            </div>
        );
    } else {
        return (<div className="inspect-file-header dataOwner txt-only">
                <div title={OSName}>{OSName}</div>
            </div>);
    }
}

export const renderPorts = (params) => {
    const InspectContent = true;
    if (params.row.ports) {
        return params.row.ports.map((pdetails) => {
            return (
                <div className="inspect-alerts dataOwner">
                    <Paper component="ul" className={InspectContent ? "chip-container alerts inspect-content ports" : "chip-container alerts"}>
                        <div className={InspectContent ? 'alerts-container inspect-content-header' : 'alerts-container'}>
                            <div className={`squareBadge ${pdetails.severity.toLowerCase()}`} title={pdetails.port}>
                                <span className='squareBadge-text'>{pdetails.hostType + ':' + pdetails.port}</span>
                            </div>
                        </div>
                    </Paper>
                </div>
            );
        })
    }
    return null;
};

export const renderNames = (params) => {
    const InspectContent = true;
    return <div className="inspect-alerts dataOwner">
        <Paper component="ul" className={InspectContent ? "chip-container alerts inspect-content" : "chip-container alerts"}>
            {params.row.userTags && params.row.userTags.map((p) => {
                const tagTxt = p.code + ':' + p.value
                return (
                    <div className={InspectContent ? 'alerts-container inspect-content-header' : 'alerts-container'}>
                        <div className={`squareBadge other tag`} title={tagTxt}>
                            <span className='squareBadge-text'>{p.value}</span>
                        </div>
                    </div>
                );
            })}
        </Paper>
    </div>
}

export const renderTags = (params) => {
    const InspectContent = true;
    return <div className="inspect-alerts dataOwner">
        <Paper component="ul" className={InspectContent ? "chip-container alerts inspect-content" : "chip-container alerts"}>
            {params.row.systemTags && params.row.systemTags.map((p) => {
                const tagTxt = p.code + ':' + p.value
                return (
                    <div className={InspectContent ? 'alerts-container inspect-content-header' : 'alerts-container'}>
                        <div className={`squareBadge other tag`} title={tagTxt}>
                            <span className='squareBadge-text'>{p.value}</span>
                        </div>
                    </div>
                );
            })}
        </Paper>
    </div>
}


export const isFileProcessCompleted = (data) => {
    ['PROCESSED','ERROR'].includes(data?.status?.code);
}

export const NetSpaceTableColumns = (renderHostIP) => [
    {
        field: "IP",
        flex: 8,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        headerName: 'Host IP',
        renderCell: renderHostIP
    },
    {
        field: "Type",
        flex: 5,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        renderCell: renderHostType
    },
    {
        field: "User Tags",
        flex: 5,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        renderCell: renderNames
    },
    {
        field: "OS",
        flex: 3,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        renderCell: renderOS
    },
    {
        field: "Ports",
        flex: 11,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        cellClassName: 'port-header',
        checkboxSelection: false,
        renderCell: renderPorts
    },
    {
        field: "System Tags",
        flex: 5,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        renderCell: renderTags
    },
    {
        field: "alertSegregation",
        flex: 4,
        sortable: false,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        headerName: 'Security Alerts',
        renderCell: renderAlerts
    },
    {
        field: "Is Monitored",
        flex: 2,
        sortable: true,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'site-map-header',
        checkboxSelection: false,
        headerName: 'Visibility',
        renderCell: renderIsMonitored,
    }
];