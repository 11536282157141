import React, { useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
        width: '100%',
        overflow: 'auto',
        height: '100%',
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));



function ChipsArray({ tags, onSaveHandle, handleCancel }) {
    const classes = useStyles();
    const [chipData, setChipData] = React.useState(tags);
    const [tag, setTag] = React.useState({ code: '', value: '' });
    const [disableSave, setDisableSave] = React.useState(true);
    const handleDelete = (chipToDelete) => () => {
        setDisableSave(false);
        setChipData((chips) => chips.filter((chip) => chip.code !== chipToDelete.code && chip.value !== chipToDelete.value));
    };
    useEffect(() => {
        setChipData(tags);
    }, [tags])
    const updateName = (newName) => {
        setTag(prevTag => ({ ...prevTag, code: newName }));
    };

    const updateValue = (newValue) => {
        setTag(prevTag => ({ ...prevTag, value: newValue }));
    };
    const addTag = (ev) => {
        ev.preventDefault();
        setDisableSave(false);
        setChipData([...chipData, tag]);
        setTag({ code: '', value: '' });
    }
    return (
        <Grid container alignContent="center" alignItems="center">
            <Grid item xs={12} className="new-tag-container">
                <TextField className="tag-txt"
                    value={tag.code} label="User Tag Name" onChange={(e) => {
                        updateName(e.target.value);
                    }} />
                <TextField className="tag-txt"
                    value={tag.value} label="User Tag Value" onChange={(e) => {
                        updateValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            addTag(e);
                        }
                      }} />
                <AddCircleOutlineIcon className="add-tag-circle" onClick={addTag} />
            </Grid>
            <Grid item xs={12} className="chip-container">
                <Paper component="ul" className={classes.root}>
                    {chipData && chipData.length > 0 && chipData.map((data, i) => {
                        const label = data.code + ' : ' + data.value;
                        return (
                            <li key={i}>
                                <Chip
                                    label={label}
                                    onDelete={handleDelete(data)}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
                </Paper>
            </Grid>
            <Grid item xs={12} alignContent="center" className="tag-btn-container">
                <div className="btn-container">
                    <Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); onSaveHandle(e, chipData) }} disabled={disableSave}>
                        Save
                    </Button>
                </div>
                <div className="btn-container">
                    <Button variant="contained" color="secondary" onClick={handleCancel}>
                        Close
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

export default ChipsArray;