import React from 'react';
import { GET_VISIBILITY, desiredOrderMonitor, MonitorMap } from "./NetSpaceConstant";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { Grid } from 'material-ui-core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { StyledCardHeader } from "./NetSpaceUtils";
import { sensitivityColor } from './NetSpaceUtils';
import {
    PieChart,
    Pie,
    Tooltip,
    Cell,
    ResponsiveContainer,
  } from "recharts";

function Visibility() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_VISIBILITY,
        });
    }, []);
    const visibility = useSelector(
        (state) => state.NetspaceReducer.visibility
    );
    const loader = useSelector(
        (state) => state.NetspaceReducer.componentLoader[GET_VISIBILITY]
    );
    const totalHosts = visibility ? Object.values(visibility).reduce((acc, curr) => acc + curr, 0) : 0;
    const renderContent = () => {
        if (visibility) {
            let renderEachMonitor = [];
            let pieData = [];
            desiredOrderMonitor.forEach((monitor) => {
                if (monitor in visibility) {
                    renderEachMonitor.push(
                        <>
                            <Grid item xs={12}>
                                <div className={monitor}>{visibility[monitor]} {MonitorMap[monitor]}</div>
                            </Grid>
                        </>
                    );
                    pieData.push({ name: monitor, value: visibility[monitor], color: sensitivityColor(monitor) });

                } else {
                    renderEachMonitor.push(
                        <>
                            <Grid item xs={12}>
                                <div className={monitor}>0 {MonitorMap[monitor]}</div>
                            </Grid>
                        </>
                    );
                    pieData.push({ name: monitor, value: 0, fill: sensitivityColor(monitor) });
                }
            })
            return (
                <a className="a-tag-none" href='https://nodezero.druvstar.net/investigate/hosts' target="_blank" rel="noopener noreferrer">
                    <Grid container spacing={2}>
                        <Grid item xs={8} className='grid-top-pad-0'>
                            <Grid container spacing={2} className='grid-margin-top-0'>
                                {renderEachMonitor.map((item, i) => (
                                    <React.Fragment key={i}>
                                        {item}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <div className='radial-chart-container'>
                                <ResponsiveContainer width="100%" height="80%">
                                    <PieChart className='netspace-card-pie'>
                                        <Pie
                                            isAnimationActive={false}
                                            data={pieData}
                                            nameKey="name"
                                            dataKey="value"
                                        >
                                            {pieData.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={entry['color']}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </Grid>
                    </Grid>
                </a>
            );
        }
    }
    return (
        <>
            <Grid item xs={2}>
                <Card>
                    <StyledCardHeader
                        title={`Visibility ${totalHosts}`}
                    />
                    <CardContent className='card-content'>
                        {loader ? (
                            <img
                                src={Page_Loader}
                                alt="loading"
                                className="loader-img"
                            ></img>
                        ) : renderContent()}
                    </CardContent>
                </Card>
            </Grid >

        </>
    );
}

export default Visibility;