import { isEmpty } from "lodash";
import { saveConnection } from "../../../redux/actions/saveConnectionAction";
import {
  CONNECTION_SUCCESSFUL,
  DATASOURCE_SAVE_ALIAS_NAME,
} from "../../../redux/constants/constants";
import { getTestConnection } from "../../../redux/actions/getTestConnectionAction";
import { NTFS } from "./dataSourceConstants";

export const saveAliasName = (name, dataSourceData, dispatch, setLoader) => {
  setLoader(true);
  const applicationId = dataSourceData.application.id;
  const dataSourceId = dataSourceData.id;
  dispatch({
    type: DATASOURCE_SAVE_ALIAS_NAME,
    payload: {
      name,
      applicationId,
      dataSourceId,
    },
  });
};

export function VerifyAndSaveConnection(
  event,
  choice,
  setLoader,
  handleValidation,
  setErrors,
  setConnectionMsg,
  dataSourceData,
  props,
  resetFormFields,
  setIsDSSaved,
  getConnectionData,
  engine
) {
  async function handleTestConnection(data) {
    setLoader(true);
    const res = await getTestConnection(data);
    if (!isEmpty(res)) {
      if (res === CONNECTION_SUCCESSFUL) {
        setIsDSSaved(true);
      }
      setLoader(false);
      setConnectionMsg(res);
    }
  }
  async function handleSaveConnection(
    event,
    data,
    applicationId,
    dataSourceId
  ) {
    setLoader(true);
    const res = await saveConnection(data, applicationId, dataSourceId);
    if(engine === NTFS) {
      resetFormFields();
      props.toggleDrawer(false, event);
    }
    if (res && res.connectionUrl != null && res.connectionUrl !== undefined) {
      resetFormFields();
      props.toggleDrawer(false, event);
    } else {
      setLoader(false);
      setConnectionMsg(res);
    }
  }
  const errors = handleValidation();
  if (Object.keys(errors).length > 0) {
    setErrors({ ...errors });
    setConnectionMsg("");
  } else {
    setErrors({});
    const data = getConnectionData(choice);
    if (choice === "test") {
      handleTestConnection(data);
    } else {
      handleSaveConnection(
        event,
        data,
        dataSourceData.application.id,
        dataSourceData.id
      );
    }
  }
}
