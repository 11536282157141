import React, { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { percentageCalculation, totalCount } from "../../common/utilities";
import RiskPilotGaugeChart from "./Dashboards/RiskPilotGaugeChart";
import CircularProgressBar from "../../shared/Badge/CircularProgressBar";
import RiskPilotStatusCards from "./RiskPilotStatusCards";
import "./RiskGovernance.scss";

const CustomCard = styled(Card)(({ color }) => ({
  backgroundColor: color,
  borderRadius: "8px",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  width: "100%",
  fontWeight: "bold",
  height: "230px",
  boxShadow: "none",
}));

const statusNamesLookUp = {
  toDo: "To Do",
  open: "Open",
  inProgress: "In Progress",
  closed: "Closed",
};


const statusTotals = (data, totalCount) => {
  // This is done to ensure that items with zero counts do not participate in the percentage calculation.
  // Including zero-count items would skew the distribution of percentages and give non-meaningful results.
  const filteredData = [
    { type: "toDo", count: data["toDo"] },
    { type: "open", count: data["open"] },
    { type: "inProgress", count: data["inProgress"] },
    { type: "closed", count: data["closed"] },
  ].filter((item) => item.count > 0);

  // The percentageCalculation function will distribute the percentages among non-zero items.
  const percentages = percentageCalculation(filteredData, totalCount);

  const percentageMap = percentages.reduce((acc, item) => {
    acc[item.type] = item.count;
    return acc;
  }, {});

  // For zero-count items, the percentage is set to 0.
  const allStatusData = {
    toDo: {
      count: data["toDo"],
      percentage: percentageMap["toDo"] || 0,
      totalCount,
    },
    open: {
      count: data["open"],
      percentage: percentageMap["open"] || 0,
      totalCount,
    },
    inProgress: {
      count: data["inProgress"],
      percentage: percentageMap["inProgress"] || 0,
      totalCount,
    },
    closed: {
      count: data["closed"],
      percentage: percentageMap["closed"] || 0,
      totalCount,
    },
  };

  return allStatusData;
};

const RiskPilotBadges = ({tableView}) => {
  const mitigationStatusData = useSelector(
    (state) => state.riskPilotReducer.mitigationStatusData
  );
  const statusData = useMemo(
    () => statusTotals(mitigationStatusData, mitigationStatusData.totalCount),
    [mitigationStatusData]
  );

  const countData = {
    totalMitigationCount: mitigationStatusData.totalCount,
    totalClosedCount: mitigationStatusData.closed
  }

  const PilotCard = React.memo(() => (
    <CustomCard color="#7A0C94">
      <CardContent sx={{ textAlign: "left" }}>
        <div style={{ fontSize: "20px", color: "#B8FF01" }}>
          Mitigation Flight Path
        </div>
        <div className="riskPilotCardContainer"
        >
          <RiskPilotGaugeChart countData={countData} />
        </div>
      </CardContent>
    </CustomCard>
  ));

  const CriticalityCard = React.memo(
    ({
      type,
      typeColor,
      percentage,
      totalCount,
      value,
      status,
      color,
      barColor,
    }) => (
      <CustomCard color={color}>
        <CardContent sx={{ textAlign: "left" }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                style={{
                  color: typeColor,
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {statusNamesLookUp[type]}
              </Typography>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 1, fontWeight: "bold" }}
                style={{
                  fontWeight: "600",
                  marginTop: "20px",
                  color: barColor,
                  marginBottom: "10px",
                }}
              >
                {percentage}%
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                <RiskPilotStatusCards
                  color={color}
                  tableView={tableView}
                  type={type}
                  count={value}
                  totalCount={totalCount}
                />
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CircularProgressBar
                strokeWidth={13}
                percentage={percentage}
                count={value}
                severity={status}
                width={40}
                height={40}
                loc="riskPilot"
              />
            </Grid>
          </Grid>
        </CardContent>
      </CustomCard>
    )
  );

  return (
    <Grid
      container
      spacing={4}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        marginBottom: "20px",
      }}
    >
      <Grid item xs={4}>
        <PilotCard />
      </Grid>
      {["toDo", "open", "inProgress", "closed"].map((status, index) => (
        <Grid item xs={2} key={status}>
          <CriticalityCard
            type={status}
            typeColor={
              status === "toDo"
                ? "#E51959"
                : status === "open"
                ? "#FFBF00"
                : status === "inProgress"
                ? "#F68D2B"
                : "#344BFD"
            }
            percentage={statusData[status].percentage}
            value={statusData[status].count}
            totalCount={statusData[status].totalCount}
            status={status.toUpperCase()}
            color={
              status === "toDo"
                ? "#FFE2E5"
                : status === "open"
                ? "#ffecb2"
                : status === "inProgress"
                ? "#ffdbba"
                : "#BFE8FE"
            }
            barColor={
              status === "toDo"
                ? "#FF2251"
                : status === "open"
                ? "#ED9E00"
                : status === "inProgress"
                ? "#EF5B02"
                : "#0483C7"
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default RiskPilotBadges;
