// NTFSDataSource.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_NTFS_DIRECTORIES,
  SET_NTFS_PROPOSED,
} from "../../../../redux/constants/constants";
import HostField from "./HostField";
import DirectoriesAutocomplete from "./DirectoriesAutocomplete";
import ProcessAutocomplete from "./ProcessAutocomplete";
import NTFSButtons from "./NTFSButtons";
import { DOWNLOAD_NTFS_CONFIGURATION } from "../dataSourceConstants";
import {
  FormControlLabel,
  makeStyles,
  Chip,
  Checkbox,
} from "@material-ui/core";
import InfoIcon from "../../../../assets/icons/InfoIcon.svg";

const useStyles = makeStyles(() => ({
  customErrorIndication: { color: "red" },
  customPadding: { padding: "10px 0px" },
  customDirectories: { padding: "10px 0px", marginBottom: "10px" },
  cancelButtonCustomStyles: {
    width: "124px",
    height: "32px",
    boxShadow: "0 5px 8px -3px rgb(1 18 121 / 50%)",
  },
  saveButtonCustomStyles: {
    marginLeft: "10px",
    textTransform: "none",
    width: "124px",
    height: "32px",
  },
  downloadButtonCustomStyles: {
    marginLeft: "10px",
    textTransform: "none",
    height: "32px",
  },
}));

function NTFSDataSource({
  isEditMode,
  error,
  removeErrorByKey,
  dataSourceData,
  toggleDrawer,
  saveDataSourceFieldData,
  staticInstructionsOpen,
  handleSaveActions,
}) {
  const [ntfsConfigurationCheck, setNtfsConfigurationCheck] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const ntfsHost = useSelector((state) => state.CreateSourceReducer.ntfsHost);
  const ntfsDirectories = useSelector(
    (state) => state.CreateSourceReducer.ntfsDirectories
  );
  const ntfsProcessNames = useSelector(
    (state) => state.CreateSourceReducer.ntfsProcessNames
  );

  const handleConfirmConfiguration = (event) =>
    setNtfsConfigurationCheck(event.target.checked);

  const handleNTFSConfig = () => {
    saveDataSourceFieldData();
    staticInstructionsOpen(true);
  };

  const handleDownloadActions = (event) => {
    dispatch({
      type: DOWNLOAD_NTFS_CONFIGURATION,
      payload: { id: dataSourceData.id, name: dataSourceData.aliasName },
    });
  };

  const handleAutocompleteTags = (value, getTagProps, loc) => {
    const getData = (option) =>
      loc === "Directories"
        ? dataSourceData.directoryNames.includes(option)
        : dataSourceData.processNames.includes(option);

    return (
      <div className="chipContainer">
        {value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={index}
            label={option}
            disabled={isEditMode ? getData(option) : false}
          />
        ))}
      </div>
    );
  };

  const handleAddNewDirectory = (event) => {
    if (
      (event.type === "keydown" && event.key === "Enter") ||
      event.type === "blur"
    ) {
      const newValue = event.target.value.trim();
      if (newValue && ntfsDirectories.indexOf(newValue) === -1) {
        dispatch({
          type: SET_NTFS_DIRECTORIES,
          payload: [...ntfsDirectories, newValue],
        });
        removeErrorByKey("ntfsDirectories");
      }
      event.target.value = "";
    }
  };

  const handleAddNewProposed = (event) => {
    if (
      (event.type === "keydown" && event.key === "Enter") ||
      event.type === "blur"
    ) {
      const newValue = event.target.value.trim();
      if (newValue) {
        dispatch({
          type: SET_NTFS_PROPOSED,
          payload: [...ntfsProcessNames, newValue],
        });
        removeErrorByKey("ntfsProcessNames");
      }
      event.target.value = "";
    }
  };

  const renderNTFSConsent = () => (
    <div
      className={
        ntfsConfigurationCheck
          ? "logConfigurationDoneContainer logSourceConfigDone"
          : "logConfigurationDoneContainer"
      }
    >
      <FormControlLabel
        className="confirmContainer"
        control={
          <Checkbox
            color="primary"
            checked={ntfsConfigurationCheck}
            onChange={handleConfirmConfiguration}
          />
        }
        label="Please accept if NTFS configuration is done"
        labelPlacement="end"
        style={{ fontSize: "14px" }}
      />
      <div className="WinlogbeatContainer">
        <img src={InfoIcon} alt="info icon" />
        <div className="winlogbeatText" onClick={handleNTFSConfig}>
          Link To NTFS configuration
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <HostField
        ntfsHost={ntfsHost}
        classes={classes}
        dispatch={dispatch}
        removeErrorByKey={removeErrorByKey}
        isEditMode={isEditMode}
        error={error}
      />
      <DirectoriesAutocomplete
        ntfsDirectories={ntfsDirectories}
        classes={classes}
        dataSourceData={dataSourceData}
        dispatch={dispatch}
        removeErrorByKey={removeErrorByKey}
        error={error}
        handleAddNewDirectory={handleAddNewDirectory}
        handleAutocompleteTags={handleAutocompleteTags}
      />
      <ProcessAutocomplete
        ntfsProcessNames={ntfsProcessNames}
        classes={classes}
        dataSourceData={dataSourceData}
        dispatch={dispatch}
        removeErrorByKey={removeErrorByKey}
        error={error}
        handleAddNewProposed={handleAddNewProposed}
        handleAutocompleteTags={handleAutocompleteTags}
      />
      {!isEditMode && renderNTFSConsent()}
      <NTFSButtons
        classes={classes}
        toggleDrawer={toggleDrawer}
        handleSaveActions={handleSaveActions}
        handleDownloadActions={handleDownloadActions}
        isEditMode={isEditMode}
        ntfsConfigurationCheck={ntfsConfigurationCheck}
      />
    </div>
  );
}

export default NTFSDataSource;
