import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import Paper from '@material-ui/core/Paper';
import ClearFilter from "../shared/clearFilter";
import SortComponent from "../shared/sortComponent";
import { makeStyles } from "@material-ui/core/styles";
import InspectFilterContent from "./InspectFilterContent";
import SortIcon from "../../assets/vizr_images/ic_sort-1.svg";
import disabledSort from "../../assets/vizr_images/disabledSort.svg";
import { isEmpty } from 'lodash';
import InspectSelectedFilterView from './InspectSelectedFilterView';
import {
  CLEAR_INSPECT_FILTER_DATA,
  SET_CATEGORIES_FILTER_DATA,
  SET_INSPECT_CONTENT_FILTERS,
  SET_SEVERITY_FILTER_DATA,
  SET_INSPECT_FILTER_DATA,
  FETCH_ALL_FIELDS_DATA_REQUEST,
  FETCH_SENSITIVE_DATA_FIELDS_REQUEST
} from '../../redux/constants/constants';



function InspectFilter({ showFilter, cloudFileSystem, value }) {
  const dispatch = useDispatch();
  const categoryOptions = [
    {
      code: 'ORGANIZATIONAL',
      value: 'Organizational'
    },
    {
      code: 'PERSONAL',
      value: 'Personal'
    },
    {
      code: 'API',
      value: 'API'
    },
    {
      code: 'FINANCIAL',
      value: 'Financial'
    },
    {
      code: 'MARKETING',
      value: 'Marketing'
    },
    {
      code: 'MEDICAL',
      value: 'Medical'
    },
    {
      code: 'OTHER',
      value: 'Other'
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('bottom-end');
  const [sortBy, setSortBy] = useState();
  const [inspectContentFilters, setInspectContentFilters] = useState([]);
  const severityFilter = useSelector(
    (state) => state.InspectContentReducer.severityFilter
  );
  const categories = useSelector(
    (state) => state.InspectContentReducer.categories
  );
  const filters = useSelector(
    (state) => state.InspectContentReducer.filters
  );


  const activeFilters = () => {
    const inspectFilters = [
      {
        code: 'HIGH',
        Value: 'High',
      },
      {
        code: 'MEDIUM',
        Value: 'Medium',
      },
      {
        code: 'LOW',
        Value: 'Low',
      },
      {
        code: 'NOT_PROCESSED',
        Value: 'Not_Processed',
      },
    ];
    if (value === 0) {
      const sensitiveSeverities = inspectFilters.filter(
        (severity) => severity.code === 'HIGH' || severity.code === 'MEDIUM'
      );
      setInspectContentFilters(sensitiveSeverities);
    } else {
      setInspectContentFilters(inspectFilters);
    }
  };

  //To change filters based on current Tab
  useEffect(() => {
    activeFilters();
  }, [value]);

  useEffect(() => {
    inspectFilters();
  }, [severityFilter, categories]);

  const handleClick = (event, newPlacement) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const setFieldsData = () => {
    if (value === 0) {
      dispatch({ type: FETCH_SENSITIVE_DATA_FIELDS_REQUEST });
    } else if (value === 1) {
      dispatch({ type: FETCH_ALL_FIELDS_DATA_REQUEST });
    }
  };

  const inspectFilters = () => {
    const allFieldsFilters = [];
    if (!isEmpty(severityFilter)) {
      const newFilter = {
        member: "Contents.category",
        operator: "equals",
        values: severityFilter,
      };
      allFieldsFilters.push(newFilter);
    };
    if (!isEmpty(categories)) {
      const categoryFilter = {
        member: "Contents.finalType",
        operator: "contains",
        values: categories,
      }
      allFieldsFilters.push(categoryFilter);
    }
    dispatch({
      type: SET_INSPECT_CONTENT_FILTERS,
      payload: allFieldsFilters
    });
  }

  const clearFilterData = () => {
    dispatch({
      type: CLEAR_INSPECT_FILTER_DATA
    });
    dispatch({ type: SET_INSPECT_FILTER_DATA });
    setFieldsData();
    setOpen(false);
  };

  const handleApplyFilter = () => {
    dispatch({ type: SET_INSPECT_FILTER_DATA });
    setFieldsData();
    setOpen(false);
  };

  const handleCheckbox = (event, type) => {
    if (event.target.checked) {
      if (type === "severity") {
        if (!severityFilter.includes(event.target.value)) {
          dispatch({
            type: SET_SEVERITY_FILTER_DATA,
            payload: [...severityFilter, event.target.value]
          });
        }
      }
      if (type === "categories") {
        if (!categories.includes(event.target.value)) {
          dispatch({
            type: SET_CATEGORIES_FILTER_DATA,
            payload: [...categories, event.target.value]
          });
        }
      }
    } else {
      if (type === "severity") {
        let temp = severityFilter.filter((each) => each !== event.target.value);
        dispatch({
          type: SET_SEVERITY_FILTER_DATA,
          payload: temp
        });
      }
      if (type === "categories") {
        let temp = categories.filter((each) => each !== event.target.value);
        dispatch({
          type: SET_CATEGORIES_FILTER_DATA,
          payload: temp
        });
      }
    }
  };

  const handleOnChipDelete = (value, member,) => {
    if (member === "Contents.category") {
      let temp = severityFilter.filter((each) => each !== value);
      dispatch({
        type: SET_SEVERITY_FILTER_DATA,
        payload: temp
      });
    } else if (member === "Contents.finalType") {
      let temp = categories.filter((each) => each !== value);
      dispatch({
        type: SET_CATEGORIES_FILTER_DATA,
        payload: temp
      });
    }
  };

  const renderPopover = () => {
    return (
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition style={{ zIndex: 10 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <Paper>
              <div className="clear-filter-container">
                <ClearFilter
                  className="clear-filter"
                  handleApplyFilter={handleApplyFilter}
                  clearFilterButtonClick={clearFilterData}
                  loc={"oneDrive_filter"}
                  toggle={handleClick}
                />
              </div>
              <div className="popper-root oneDrive-filter ">
                <Fade {...TransitionProps} timeout={150}>
                  <SortComponent
                    sortBy={sortBy}
                    cloudFileSystem={cloudFileSystem}
                    loc={"oneDrive_filter"}
                    type={"inspect_filters"}
                  />
                </Fade>
                <Fade {...TransitionProps} timeout={350}>
                  <div className='inspectContentFilters'>
                    <InspectFilterContent
                      severitiesArray={inspectContentFilters}
                      severityFilter={severityFilter}
                      categoryOptions={categoryOptions}
                      categories={categories}
                      handleCheckbox={handleCheckbox}
                    />
                  </div>
                </Fade>
                <div>
                  <InspectSelectedFilterView filterInfo={filters} handleOnChipDelete={handleOnChipDelete} />
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  };
  const disableSortAndFilter = () => {
    if(showFilter){
      return <img src={disabledSort} alt="sort-filter" />
    } else {
      return <img src={SortIcon} alt="sort-filter" />     
    }
  }

  return (
    <>
      <div className="inspect-sort">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => handleClick(event, "bottom-end")}
          startIcon={disableSortAndFilter()}
          className="inspect-sortAndFilter-button"
          disabled={showFilter}
        >
          Sort & Filter
        </Button>
        {renderPopover()}
      </div>
      {open && (
        <div
          className='back-drop-filter'
        />
      )}
    </>
  );
}

export default InspectFilter