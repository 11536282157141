import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import ChipsArray from './TagChips';
import { SAVE_TAGS, CHANGE_TAG_MODAL_STATUS } from './NetSpaceConstant';
import { makeStyles } from "@material-ui/core/styles";

const useModalStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: '50vh',
        minWidth: '52vw',
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const NetSpaceUserTag = () => {
    const isOpen = useSelector((state) => state.NetspaceReducer.isTagModalOpen);
    const nodeId = useSelector((state) => state.NetspaceReducer.selectedTagNodeId);
    const chipData = useSelector((state) => state.NetspaceReducer.selectedTagData);
    const selectedGroup = useSelector((state) => state.NetspaceReducer.selectedGroup);
    const updateTagGroup = useSelector((state) => state.NetspaceReducer.updateTagGroup);
    const paginationModel = useSelector((state) => state.NetspaceReducer.accordionData[updateTagGroup]?.paginationModel);
    const dispatch = useDispatch();
    function getModalStyle() {
        const top = 50;
        const left = 50;
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleClosePopup = (e) => {
        e.stopPropagation();
        dispatch({
            type: CHANGE_TAG_MODAL_STATUS,
            payload: { isTagModalOpen: false, selectedTagData: null, selectedTagNodeId: null }
        });
    };

    const handleSave = (e, updatedTags) => {
        updateTags(updatedTags, { key: selectedGroup, value: updateTagGroup, pageNo: paginationModel.page, pageSize: paginationModel.pageSize });
    };
    const modalClasses = useModalStyles();
    const [modalStyle] = useState(getModalStyle);

    const updateTags = (updatedTags, loadGroupData) => {
        dispatch({
            type: SAVE_TAGS,
            payload: { hostId: nodeId, tags: updatedTags, loadGroupData }
        });
    }
    return (
        <Modal open={isOpen} onClose={handleClosePopup}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className='add-tag-popup'>
            <div style={modalStyle} className={modalClasses.paper}>
                <h2 id="simple-modal-title">Add User Tags</h2>
                <p id="simple-modal-description"></p>
                <ChipsArray tags={chipData} onSaveHandle={handleSave} handleCancel={handleClosePopup} />
            </div>
        </Modal>
    );
};

export default NetSpaceUserTag;