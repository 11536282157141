import React from 'react';
import { GET_HOST_WITH_ALERTS, sensitivityMap, desiredOrderAlerts } from "./NetSpaceConstant";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { Grid } from 'material-ui-core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { StyledCardHeader } from "./NetSpaceUtils";

function HostWithAlerts() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_HOST_WITH_ALERTS,
        });
    }, []);
    const hostWithAlert = useSelector(
        (state) => state.NetspaceReducer.hostWithAlert
    );
    const loader = useSelector(
        (state) => state.NetspaceReducer.componentLoader[GET_HOST_WITH_ALERTS]
    );
    const renderContent = () => {
        if (hostWithAlert) {
            return (
                <a className="a-tag-none" href='https://nodezero.druvstar.net/investigate/hosts' target="_blank" rel="noopener noreferrer">
                    <Grid container spacing={2}>
                        {desiredOrderAlerts.map((alert) => {
                            if(alert in hostWithAlert){
                                return (
                                    <>
                                        <Grid item xs={8}>
                                            <div className={alert}>{hostWithAlert[alert]} {sensitivityMap[alert]}</div>
                                        </Grid>
                                        <Grid item xs={4} className="txt-underline">
                                            View
                                        </Grid>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <Grid item xs={8}>
                                            <div className={alert}>0 {sensitivityMap[alert]}</div>
                                        </Grid>
                                        <Grid item xs={4} className="txt-underline">
                                            View
                                        </Grid>
                                    </>
                                )
                            }
                        })}
                    </Grid>
                </a>
            );
        }
    }
    const totalAlerts = hostWithAlert ? Object.values(hostWithAlert).reduce((acc, curr) => acc + curr, 0) : 0;
    return (
        <>
            <Grid item xs={2}>
                <Card>
                    <StyledCardHeader
                        title={`Host with alerts ${totalAlerts}`}
                    />
                    <CardContent className='card-content'>
                        {loader ? (
                            <img
                                src={Page_Loader}
                                alt="loading"
                                className="loader-img"
                            ></img>
                        ) : renderContent()}
                    </CardContent>
                </Card>
            </Grid >

        </>
    );
}

export default HostWithAlerts;