const platformNTFSFieldData = [
    {
      label: "S3 Bucket",
      value: "", // Default empty string, will be managed by parent component
      type: "text",
      placeholder: "S3 Bucket",
      autoComplete: "off",
      onChange: () => {}, // Placeholder function, will be replaced by parent component
      showPassword: false,
      togglePasswordVisibility: null,
    },
    {
      label: "Access Key",
      value: "", // Default empty string, will be managed by parent component
      type: "password",
      placeholder: "Access Key",
      autoComplete: "off",
      onChange: () => {}, // Placeholder function, will be replaced by parent component
      showPassword: false,
      togglePasswordVisibility: null,
    },
    {
      label: "Secret Key",
      value: "", // Default empty string, will be managed by parent component
      type: "password",
      placeholder: "Secret Key",
      autoComplete: "off",
      onChange: () => {}, // Placeholder function, will be replaced by parent component
      showPassword: false,
      toggleSharedKeyVisibility: null,
    },
  ];
  
  export default platformNTFSFieldData;