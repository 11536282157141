import React from 'react';
import { CLOSE_NETSPACE_DRAWER } from "./NetSpaceConstant";
import { useSelector, useDispatch } from 'react-redux';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "../../assets/vizr_images/ic_remove.svg";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {
    renderIsMonitored,
    renderAlerts,
    renderHostIPFnWrapper,
    renderHostType,
    renderOS,
    renderPorts,
    renderNames,
    renderTags
} from './NetSpaceUtils';
const useStyles = makeStyles({
    paper: {
        background: "white",
    }
});
const NetSpaceDrawer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const renderHostIP = renderHostIPFnWrapper(dispatch);
    const drawerLoader = useSelector((state) => state.NetspaceReducer.drawerLoader);
    const selectedHost = useSelector((state) => {
        const params = {};
        params['row'] = state.NetspaceReducer.selectedHost
        return params;
    });
    const drawerIsOpen = useSelector((state) => state.NetspaceReducer.drawerIsOpen);
    const renderNetSpaceHostDetails = () => {
        return (
            drawerLoader ? (
                <img
                    src={Page_Loader}
                    alt="loading"
                    className="loader-data-source tenantProfileLoader"
                ></img>
            ) :
                (
                    <>
                        <div
                            className='netspace_drawer_main'
                        >
                            <div className="netspace_drawer_container">
                                <div
                                    className="netspace_drawer_header">
                                    <div className="netspace_details">Host Details</div>
                                    <Button
                                        className='close_drawer'
                                        onClick={(event) => dispatch({ type: CLOSE_NETSPACE_DRAWER })}
                                        aria-label="close"
                                    >
                                        <img src={CloseIcon} alt={"close"} />
                                    </Button>
                                </div>
                                <div className="netspace_part2">
                                    <>
                                        <div className="netspace_content_DB">
                                            <span className='header'>Host Name:</span>{" "}{renderHostIP(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>Host Type:</span>{" "}{renderHostType(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>User Tags:</span>{" "}{renderNames(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>OS:</span>{" "}{renderOS(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>Ports:</span>{" "}{renderPorts(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>System Tags:</span>{" "}{renderTags(selectedHost)}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>Security Alerts:</span>{" "}{renderAlerts({ value: selectedHost.alertSegregation })}
                                        </div>
                                        <Divider />
                                        <div className="netspace_content_DB">
                                            <span className='header'>Visibility:</span>{" "}{renderIsMonitored(selectedHost)}
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </>
                )
        );
    };
    return (
        drawerIsOpen ? (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.paper,
                    }}
                    anchor={"right"}
                    open={drawerIsOpen}
                    onClose={(event) => dispatch({ type: CLOSE_NETSPACE_DRAWER })}
                >
                    {renderNetSpaceHostDetails()}
                </Drawer>
            </React.Fragment>
        ) : null
    );
};

export default NetSpaceDrawer;