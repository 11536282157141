import React, { useState, useEffect } from "react";
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";

import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import  AlertDrawer  from "./alertDrawer";
import "./oneDriveInspectContentsStyles.scss";
import { isEmpty, isNull } from "lodash";
import { NTFS, SET_INSPECT_CURRENT_PAGE, SET_INSPECT_PAGE_SIZE, SET_LOADER} from "../../redux/constants/constants";
import { renderSensitivityValue, isCloudFileSystem, categoryIcons } from "../common/utilities";


export default function InspectContentTable({ allFilesTableData, loader, totalCount, sourceName, changeSource, loc }) {
  const cloudFileSystem = isCloudFileSystem(sourceName) || sourceName === NTFS;
  const dispatch = useDispatch();
  const currentPage = useSelector(
    (state) => state.InspectContentReducer.currentPage
  );
  const pageSize = useSelector(
    (state) => state.InspectContentReducer.pageSize
  );
  const filters = useSelector(
    (state) => state.InspectContentReducer.filters
  );
  const dataMapFilePopUp = useSelector(
    (state) => state.dataMapReducer.dataMapFilePopUp
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if(allFilesTableData.apiSuccess) {
      dispatch({type: SET_LOADER, payload: true});
      setRowData(allFilesTableData.data);
      setPaginationModel(prevState => ({
        ...prevState,
        page: currentPage,
        pageSize: pageSize,
      }));
      dispatch({type: SET_LOADER, payload: false});
    };
  },[allFilesTableData, currentPage, pageSize])

  useEffect(() => {
    dispatch({
      type: SET_INSPECT_CURRENT_PAGE,
      payload: paginationModel.page
    });
    dispatch({
      type: SET_INSPECT_PAGE_SIZE,
      payload: paginationModel.pageSize
    });
  }, [paginationModel]);

  useEffect(() => {
    dispatch({
      type: SET_INSPECT_CURRENT_PAGE,
      payload: 0
    });
    dispatch({
      type: SET_INSPECT_PAGE_SIZE,
      payload: 20
    });
    setPaginationModel({page: 0, pageSize:20});
  },[])



  const renderNoDataMessage = () => {
    if (!loader) {
      if (cloudFileSystem) {
        return "No Files for this node";
      } else {
        return "No Fields for this node";
      }
    } else {
      return null;
    }
  }

  const handlePaginationChange = (params) => {
    const newPagination = {
      pageSize: params.pageSize,
      page: params.page,
    };
    if (paginationModel.pageSize !== newPagination.pageSize) {
      newPagination.page = 0;
    }
    setPaginationModel(newPagination);
  };

  const renderNull = () => {
    return (<>-</>);
  };

  const renderIcons = (data) => {
    if (!isEmpty(data) && data!=="NO_SENSITIVITY") {
     const categoryName = data?.trim().toUpperCase(); 
     const icon = categoryIcons[categoryName];
     return (
       <img
         src={icon}
         alt={categoryName}
         title={categoryName}
         className="oneDriveIconImage"
       />
     );
   } else {
     return <div>-</div>;
   }
 };

  const imageCount = (count) => {
    if (count > 0) {
      return (
        <div className="circle oneDrive">
          <span className="circleText" title={count}>{`+${count}`}</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const renderNoData = (params) => {
    if (isNull(params.value)) {
      return renderNull()
    }
  };

  const renderFileCell = (params) => {
    if (isNull(params.value)) {
      return renderNull()
    } else {
      return (
        <div className="inspect-file-header">
          <div className="inspect-file-fieldName" title={params.value}>{params.value}</div>
        </div>
      );
    }
  };

  const renderCategory = (params) => {
    if (isNull(params.value)) {
      return renderNull()
    } else {
      return (
        <div className="oneDriveCategoryContainer">
          <div className="oneDriveCategoryIcons" >
            {renderIcons(params.value)}
          </div>
        </div>
      );
    }
  };


  const renderSensitivityCell = (params) => {
    if (isNull(params.value)) {
      return renderNull()
    } else {
      return (
        <div className={`inspect-file-severity ${params.value}`}>{renderSensitivityValue(params.value)}</div>
      );
    }
  };

  const renderLastDLPScanTimeCell = (params) => {
    if (isNull(params.value)) {
      return renderNull()
    } else {
      return (
        <div>
          <div>{params.value}</div>
        </div>
      );
    }
  };

  //custom message to display when there is no Data found after applying filters
  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Results</div>
      </GridOverlay>
    );
  }

  const columns = [
    {
      field: "Contents.columnName",
      headerName: cloudFileSystem ? 'Files' : 'Fields',
      flex: 0.8,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: renderFileCell
    },
    {
      field: "Contents.category",
      headerName: 'Sensitivity',
      flex: 0.5,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      checkboxSelection: false,
      renderCell: renderSensitivityCell
    },
    {
      field: "Contents.finalType",
      headerName: 'Category',
      flex: 0.6,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderCategory
    },
    {
      field: cloudFileSystem ? "Contents.fileOwner" : "Contents.tableName",
      headerName: cloudFileSystem ? 'Owner': ' Table Name',
      flex: 0.6,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderCell: renderNoData
    },
    {
      field: "Contents.lastAccess",
      headerName: 'Last Access',
      type: 'number',
      flex: 0.5,
      headerAlign: 'left',
      align: 'center',
      sortable: false,
      hide:true,
      renderCell: renderNoData
    },
    {
      field: "Contents.lastDLPScanTime",
      headerName: 'Last Scan',
      type: 'number',
      flex: 0.5,
      headerAlign: 'left',
      align: 'center',
      sortable: false,
      renderCell: renderLastDLPScanTimeCell
    },
  ];
  const filteredColumns =
    !cloudFileSystem
      ? columns.filter((column) => column.hide !== true)
      : columns;
  const handleFilePopUp = (data) => {
    dispatch({ type: "OPEN_FILE_POP_UP", payload: data.row });
  }
  const renderSidePopup = () => {
    return (
      <AlertDrawer
        dataMapFilePopUp={dataMapFilePopUp}
        sourceName={sourceName}
        loc={"Data-Map-File-Pop-up"}
      ></AlertDrawer>
    );
  };
  const renderTable = () => {
    if (!isEmpty(rowData) || !isEmpty(filters)) {
      return (
        <div className="custom-data-grid-main">
          <DataGrid
            className="custom-data-grid"
            rows={rowData}
            rowCount={totalCount}
            columns={filteredColumns}
            getRowId={(row) => row['Contents.sensitivityId']}
            rowHeight={90}
            components={{ NoRowsOverlay: customNoRowsOverlay }}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            showCellRightBorder={false}
            showColumnRightBorder={false}
            showCellVerticalBorder={true}
            disableColumnFilter={true}
            disableColumnSelector
            disableColumnMenu
            paginationMode="server"
            hideFooterSelectedRowCount={true}
            getRowSpacing={params => ({
              top: 10,
              bottom: params.isLastVisible ? 0 : 5
            })}
            onRowClick={handleFilePopUp}
          />
          {renderSidePopup()}
        </div>
      );
    } else {
      return (
        <div>
          <p>{renderNoDataMessage()}</p>
        </div>
      )
    }
  };


  const renderContentTable = () => {
    if ( loc === "fullScreen") {
      if (loader && changeSource) {
        return null;
      } else {
        return renderTable();
      }
    } else {
      return (
        <>
        {loader && (
          <img
            src={Page_Loader}
            alt="loading"
            className="loader-data-source oneDrive-Table"
          ></img>
        )}
        {renderTable()}
        </>
      )
    }
  }


  return (
    <>
      {renderContentTable()}
    </>
  );
};