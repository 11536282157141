export const ntfsDashBoardInstance = () => {
  const DashBoardInstance = [
    {
      id: 0,
      name: "Total Alerts",
      vizState: {
        chartType: "dataAssetsDonut",
        query: [
          {
            measures: ["Alerts.count"],
            filters: [
              {
                member: "Alerts.datasourceType",
                operator: "equals",
                values: ["NTFS"],
              },
            ],
          },
          {
            dimensions: ["Alerts.severity"],
            filters: [
              {
                member: "Alerts.datasourceType",
                operator: "equals",
                values: ["NTFS"],
              },
            ],
            measures: ["Alerts.count"],
          },
        ],
      },
      size: 4,
      row: "medium-row",
    },

    {
      id: 1,
      name: "Sensitivity File Count",
      vizState: {
        chartType: "heatMapLegend",
        query: {
          dimensions: ["Assets.category"],
          timeDimensions: [],
          order: {
            "Assets.count": "desc",
          },
          filters: [
            {
              member: "Assets.datasourceType",
              operator: "equals",
              values: ["NTFS"],
            },
          ],
          measures: ["Assets.count"],
        },
      },
      size: 4,
      row: "medium-row",
    },
    {
      id: 2,
      name: "Alerts By Users",
      vizState: {
        chartType: "dataAssetsDonut",
        query: [
          {
            measures: ["ActorAlerts.userCount"],
            filters: [
              {
                member: "ActorAlerts.datasourceType",
                operator: "equals",
                values: ["NTFS"],
              },
            ],
          },
          {
            measures: ["ActorAlerts.userCount"],
            order: {
              "ActorAlerts.userCount": "desc",
            },
            dimensions: ["ActorAlerts.severityCode"],
            filters: [
              {
                member: "ActorAlerts.datasourceType",
                operator: "equals",
                values: ["NTFS"],
              },
            ],
          },
        ],
        location: "oneDriveUserWithAlerts",
      },
      size: 4,
      row: "medium-row",
    },
    {
      id: 3,
      name: "Total Users By Roles",
      vizState: {
        chartType: "userRoles",
        queries: [
          {
            measures: ["UserRoles.totalCount"],
            filters: [
              {
                member: "UserRoles.dataSourceType",
                operator: "equals",
                values: ["NTFS"],
              },
              {
                member: "UserRoles.userIsInActive",
                operator: "equals",
                values: ["false"],
              },
            ],
          },
          {
            measures: ["UserRoles.totalCount"],
            dimensions: ["UserRoles.roleName", "UserRoles.dataSourceType"],
            timeDimensions: [],
            order: {
              "UserRoles.totalCount": "desc",
            },
            limit: 5,
            offset: 0,
            filters: [
              {
                member: "UserRoles.dataSourceType",
                operator: "equals",
                values: ["NTFS"],
              },
              {
                member: "UserRoles.userIsInActive",
                operator: "equals",
                values: ["false"],
              },
            ],
          },
        ],
      },
      size: 4,
      row: "third-row",
    },
    {
      id: 4,
      name: "Total Folders",
      vizState: {
        chartType: "totalGroups",
        query: [
          {
            measures: ["TotalFolders.folderCount"],
            timeDimensions: [],
            order: {},
          },
          {
            measures: ["TotalFolders.userCount"],
            dimensions: ["TotalFolders.folderName"],
            order: {
              "TotalFolders.userCount": "desc",
            },
          },
        ],
      },
      size: 4,
      row: "third-row",
    },
    {
      id: 5,
      name: "Sensitive Data Folders",
      vizState: {
        chartType: "SensitiveGroups",
        query: [
          {
            measures: ["SensitiveFolders.folderCount"],
            timeDimensions: [],
            order: {},
          },
          {
            measures: ["SensitiveFolders.userCount"],
            dimensions: ["SensitiveFolders.folderName"],
            order: {
              "SensitiveFolders.userCount": "desc",
            },
            filters: [
              {
                member: "SensitiveFolders.dataSourceType",
                operator: "equals",
                values: ["NTFS"],
              },
            ],
            limit: 5,
          },
        ],
        location: "dataMap",
      },
      size: 4,
      row: "sensitiveGroupWidget",
    },
  ];
  return DashBoardInstance;
};
