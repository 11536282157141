import { getService, getAxiosHeaders } from "../../library/RestAPI";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  CREATE_RISK_PILOT,
  DELETE_RP_ROW,
  GET_ALL_RISK_PILOT_FILES,
  GET_RISK_PILOT_ROW_BY_ID,
  RISK_PILOT_ROW_DETAILS,
  SET_ALL_RISK_PILOT_FILES,
  SET_IS_RISK_PILOT_ROW_DELETED,
  SET_ALL_RISK_TYPES,
  SET_IS_RISK_CREATED,
  SET_RISK_OWNERS,
  SET_RP_LOADER,
  TOTAL_RP_ROW_COUNT,
  UPDATE_RISK_PILOT_ROW_BY_ID,
  createRiskPilotApi,
  getAllRiskPilotFilesApi,
  getAllRisksTypesApi,
  getRiskOwnersApi,
  riskPilotRowApi,
  FILTER_RISK_PILOT,
  SET_FILTER_TRIGGERED,
  CLEAR_FILTERS_RP,
  getAllMitigationFilesApi,
  getAllThreatFilesApi,
  getAllVulnerabilitiesFilesApi,
  getRiskDetailsById,
  riskFields,
  threatFields,
  VULNERABILITY_STATUS_DATA,
  THREAT_STATUS_DATA,
  RISK_STATUS_DATA,
  vulnerabilityFields,
  MITIGATION_STATUS_DATA,
  mitigationFields,
  RISK_SNACKBAR_MESSAGE,
  RISK_SUCCESS_MESSAGE,
  RISK_SAVE_UNSUCCESSFUL_MESSAGE,
  getRiskSummaryDetails,
  GET_RISK_SUMMARY_DETAILS,
  SET_RISK_SUMMARY_DETAILS,
  RISK_SUMMARY_DETAILS_FETCH_ERROR,
  getMitigationStatusData,
} from "../../components/views/RiskPilot/riskPilotConstant";
import { getCubejsApi } from "../../components/common/Dashboard/cubejs-util";
import {
  transformStatusData,
  transformTableData,
} from "../../components/views/RiskPilot/riskPilotUtilities";

function* getAllRisks() {
  const options = {
    method: "GET",
    url: getAllRisksTypesApi,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data.content;
      yield put({ type: SET_ALL_RISK_TYPES, payload: data });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAllRiskOwners() {
  const options = {
    method: "GET",
    url: getRiskOwnersApi,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data.content;
      yield put({ type: SET_RISK_OWNERS, payload: data });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createRiskRegister(action) {
  yield put({ type: SET_RP_LOADER, payload: true });
  yield put({ type: CLEAR_FILTERS_RP });
  const options = {
    method: "POST",
    url: createRiskPilotApi,
    headers: getAxiosHeaders(true),
    data: action.payload,
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({ type: SET_IS_RISK_CREATED, payload: "success" });
      yield put({ type: RISK_SNACKBAR_MESSAGE, payload: RISK_SUCCESS_MESSAGE });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else if (res && res.response.status === 400) {
      const errorMessage =
        res.response?.data?.errorMessage || RISK_SAVE_UNSUCCESSFUL_MESSAGE;
      yield put({ type: SET_IS_RISK_CREATED, payload: "error" });
      yield put({ type: RISK_SNACKBAR_MESSAGE, payload: errorMessage });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else {
      console.log("error");
      yield put({ type: SET_IS_RISK_CREATED, payload: "error" });
      yield put({
        type: RISK_SNACKBAR_MESSAGE,
        payload: RISK_SAVE_UNSUCCESSFUL_MESSAGE,
      });
      yield put({ type: SET_RP_LOADER, payload: false });
    }
  } catch (err) {
    console.log(err);
    yield put({ type: SET_IS_RISK_CREATED, payload: "error" });
    yield put({
      type: RISK_SNACKBAR_MESSAGE,
      payload: RISK_SAVE_UNSUCCESSFUL_MESSAGE,
    });
    yield put({ type: SET_RP_LOADER, payload: false });
  }
}

function* getAllRiskRegisterFiles(action) {
  yield put({ type: SET_RP_LOADER, payload: true }); // Start loader

  const { page, size, view } = action.payload;
  const cubejsApi = getCubejsApi();
  const offset = page * size;
  const limit = size;

  const getAPIParams = () => {
    if (view === "RISK_VIEW") {
      return getAllRiskPilotFilesApi(offset, limit);
    } else if (view === "THREAT_VIEW") {
      return getAllThreatFilesApi(offset, limit);
    } else if (view === "VULNERABILITY_VIEW") {
      return getAllVulnerabilitiesFilesApi(offset, limit);
    } else if (view === "MITIGATION_VIEW") {
      return getAllMitigationFilesApi(offset, limit);
    }
  };

  const currentAPI = getAPIParams();
  try {
    yield* getAllRisks();
    yield* getAllRiskOwners();

    const totalCountResponse = yield cubejsApi.load(currentAPI[0]);
    const tableDataResponse = yield cubejsApi.load(currentAPI[1]);
    const statusDataResponse = yield cubejsApi.load(currentAPI[2]);
    const mitigationStatusData = yield cubejsApi.load(getMitigationStatusData);

    const totalCountData = totalCountResponse.rawData();
    const tableDataRaw = tableDataResponse.rawData();
    const statusDataRaw = statusDataResponse.rawData();
    const mitigationStatusDataRaw = mitigationStatusData.rawData();

    if (
      totalCountData.length > 0 &&
      tableDataRaw.length > 0 &&
      statusDataRaw.length > 0 &&
      mitigationStatusDataRaw.length > 0
    ) {
      const getTableData = () => {
        if (view === "RISK_VIEW") {
          return transformTableData(tableDataRaw, "RiskPilotRisks", riskFields);
        } else if (view === "THREAT_VIEW") {
          return transformTableData(
            tableDataRaw,
            "RiskPilotThreats",
            threatFields
          );
        } else if (view === "VULNERABILITY_VIEW") {
          return transformTableData(
            tableDataRaw,
            "RiskPilotVulnerabilities",
            vulnerabilityFields
          );
        } else if (view === "MITIGATION_VIEW") {
          return transformTableData(
            tableDataRaw,
            "RiskPilotMitigations",
            mitigationFields
          );
        }
      };

      const getTotalCount = () => {
        if (view === "RISK_VIEW") {
          return totalCountData[0]["RiskPilotRisks.totalRiskCount"];
        } else if (view === "THREAT_VIEW") {
          return totalCountData[0]["RiskPilotThreats.totalThreatCount"];
        } else if (view === "VULNERABILITY_VIEW") {
          return totalCountData[0][
            "RiskPilotVulnerabilities.vulnerabilityTotalCount"
          ];
        } else if (view === "MITIGATION_VIEW") {
          return totalCountData[0]["RiskPilotMitigations.mitigationCount"];
        }
      };

      const getStatusData = () => {
        if (view === "RISK_VIEW") {
          return {
            name: RISK_STATUS_DATA,
            data: transformStatusData(statusDataRaw, "RiskPilotRisks"),
          };
        } else if (view === "THREAT_VIEW") {
          return {
            name: THREAT_STATUS_DATA,
            data: transformStatusData(statusDataRaw, "RiskPilotThreats"),
          };
        } else if (view === "VULNERABILITY_VIEW") {
          return {
            name: VULNERABILITY_STATUS_DATA,
            data: transformStatusData(
              statusDataRaw,
              "RiskPilotVulnerabilities"
            ),
          };
        }
      };

      const tableData = getTableData();
      const totalCount = getTotalCount();
      const statusData = getStatusData();

      yield put({ type: SET_ALL_RISK_PILOT_FILES, payload: tableData });
      yield put({ type: TOTAL_RP_ROW_COUNT, payload: totalCount });
      yield put({
        type: MITIGATION_STATUS_DATA,
        payload: transformStatusData(
          mitigationStatusDataRaw,
          "RiskPilotMitigations"
        ),
      });
      yield put({ type: statusData.name, payload: statusData.data });
    } else {
      console.error("Data retrieval error: No data returned from API.");
    }
  } catch (err) {
    console.error("API call error:", err);
  }

  yield put({ type: SET_RP_LOADER, payload: false }); // End loader
}


function* updateRiskRegisterRow(action) {
  const riskRowID = action.payload.id;
  const data = action.payload.data;
  yield put({ type: SET_RP_LOADER, payload: true });
  yield put({ type: CLEAR_FILTERS_RP });
  const options = {
    method: "PUT",
    url: getRiskDetailsById(riskRowID),
    headers: getAxiosHeaders(true),
    data: data,
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({ type: SET_IS_RISK_CREATED, payload: "success" });
      yield put({ type: SET_RP_LOADER, payload: false });
      yield put({ type: RISK_SNACKBAR_MESSAGE, payload: RISK_SUCCESS_MESSAGE });
    } else {
      yield put({ type: SET_IS_RISK_CREATED, payload: "error" });
      yield put({
        type: RISK_SNACKBAR_MESSAGE,
        payload: RISK_SAVE_UNSUCCESSFUL_MESSAGE,
      });
      yield put({ type: SET_RP_LOADER, payload: false });
    }
  } catch (err) {
    yield put({ type: SET_IS_RISK_CREATED, payload: "error" });
    yield put({
      type: RISK_SNACKBAR_MESSAGE,
      payload: RISK_SAVE_UNSUCCESSFUL_MESSAGE,
    });
    yield put({ type: SET_RP_LOADER, payload: false });
    console.log(err);
  }
}

function* fetchRiskRegisterRow(action) {
  const riskRowID = action.payload;
  yield put({ type: SET_RP_LOADER, payload: true });
  const options = {
    method: "GET",
    url: getRiskDetailsById(riskRowID),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data;
      yield put({ type: RISK_PILOT_ROW_DETAILS, payload: data });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else {
      yield put({ type: SET_RP_LOADER, payload: false });
    }
  } catch (err) {
    yield put({ type: SET_RP_LOADER, payload: false });
    console.log(err);
  }
}

function* deleteRiskRegisterRow(action) {
  yield put({
    type: SET_IS_RISK_PILOT_ROW_DELETED,
    payload: true,
  });
  const riskRowID = action.payload;
  yield put({ type: SET_RP_LOADER, payload: true });
  yield put({ type: CLEAR_FILTERS_RP });
  const options = {
    method: "DELETE",
    url: riskPilotRowApi(riskRowID),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: SET_IS_RISK_PILOT_ROW_DELETED,
        payload: false,
      });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else {
      yield put({ type: SET_RP_LOADER, payload: false });
    }
  } catch (err) {
    yield put({ type: SET_RP_LOADER, payload: false });
    console.log(err);
  }
}

function* filterRiskRegister(action) {
  const { page, size, likelihoodFilters, impactFilters, riskFilters } =
    action.payload;

  const likelihood = likelihoodFilters
    .filter((filter) => filter.checked)
    .map((filter) => filter.name)
    .join(",");

  const impact = impactFilters
    .filter((filter) => filter.checked)
    .map((filter) => filter.name)
    .join(",");

  const risk = riskFilters
    .filter((filter) => filter.checked)
    .map((filter) => filter.code)
    .join(",");

  const url = getAllRiskPilotFilesApi(0, size);
  const filterUrl = `${url}&impact=${impact}&likelihood=${likelihood}&risk=${risk}`;

  yield put({ type: SET_RP_LOADER, payload: true });
  const options = {
    method: "GET",
    url: filterUrl.trim(),
    headers: getAxiosHeaders(true),
  };

  yield put({ type: SET_FILTER_TRIGGERED, payload: true });
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const data = res.data.content;
      const totalCount = res.data.totalElements;
      yield put({ type: SET_ALL_RISK_PILOT_FILES, payload: data });
      yield put({ type: TOTAL_RP_ROW_COUNT, payload: totalCount });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else {
      yield put({ type: SET_RP_LOADER, payload: false });
    }
  } catch (err) {
    yield put({ type: SET_RP_LOADER, payload: false });
    console.log(err);
  }
}

function* getRiskSummaryData(action) {
  const id = action.payload;
  const cubejsApi = getCubejsApi();
  yield put({ type: SET_RP_LOADER, payload: true });
  try {
    const response = yield cubejsApi.load(getRiskSummaryDetails(id));
    if (response && response.loadResponse?.results.length > 0) {
      const data = response.loadResponse?.results[0]?.data[0];
      yield put({ type: SET_RISK_SUMMARY_DETAILS, payload: data });
      yield put({ type: SET_RP_LOADER, payload: false });
    } else {
      yield put({ type: SET_RP_LOADER, payload: false });
      yield put({
        type: RISK_SNACKBAR_MESSAGE,
        payload: RISK_SUMMARY_DETAILS_FETCH_ERROR,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({ type: SET_RP_LOADER, payload: false });
    yield put({
      type: RISK_SNACKBAR_MESSAGE,
      payload: RISK_SUMMARY_DETAILS_FETCH_ERROR,
    });
  }
}

function* riskPilotSaga() {
  yield takeEvery(CREATE_RISK_PILOT, createRiskRegister);
  yield takeEvery(GET_ALL_RISK_PILOT_FILES, getAllRiskRegisterFiles);
  yield takeEvery(GET_RISK_PILOT_ROW_BY_ID, fetchRiskRegisterRow);
  yield takeEvery(UPDATE_RISK_PILOT_ROW_BY_ID, updateRiskRegisterRow);
  yield takeEvery(DELETE_RP_ROW, deleteRiskRegisterRow);
  yield takeEvery(FILTER_RISK_PILOT, filterRiskRegister);
  yield takeEvery(GET_RISK_SUMMARY_DETAILS, getRiskSummaryData);
}

export default riskPilotSaga;
