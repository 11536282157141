import React from 'react';
import { GET_SECURITY_ALERTS, desiredOrderAlerts, sensitivityMap } from "./NetSpaceConstant";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { Grid } from 'material-ui-core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { StyledCardHeader } from "./NetSpaceUtils";
import RadialChart from './RadialChart';
import { sensitivityColor } from './NetSpaceUtils';

function HostWithAlerts() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: GET_SECURITY_ALERTS,
        });
    }, []);
    const securityAlerts = useSelector(
        (state) => state.NetspaceReducer.securityAlerts
    );
    const loader = useSelector(
        (state) => state.NetspaceReducer.componentLoader[GET_SECURITY_ALERTS]
    );
    const totalAlerts = securityAlerts ? Object.values(securityAlerts).reduce((acc, curr) => acc + curr, 0) : 0;
    const renderContent = () => {
        if (securityAlerts) {
            let renderEachAlert = [];
            let radialData = [];
            desiredOrderAlerts.forEach((alert) => {
                if(alert in securityAlerts){
                    renderEachAlert.push(
                        <>
                            <Grid item xs={12}>
                                <div className={alert}>{securityAlerts[alert]} {sensitivityMap[alert]}</div>
                            </Grid>
                        </>
                    );
                    radialData.push({name: alert, value: securityAlerts[alert], fill: sensitivityColor(alert)});
                } else {
                    renderEachAlert.push(
                        <>
                            <Grid item xs={12}>
                                <div className={alert}>0 {sensitivityMap[alert]}</div>
                            </Grid>
                        </>
                    );
                    radialData.push({name: alert, value: 0, fill: sensitivityColor(alert)});
                }
            })
            radialData.push({name: '', value: totalAlerts, fill: '#ffffff' });
            return (
                <a className="a-tag-none" href='https://nodezero.druvstar.net/investigate/hosts' target="_blank" rel="noopener noreferrer">
                    <Grid container spacing={2}>
                        <Grid item xs={7} className='grid-top-pad-0'>
                            <Grid container spacing={2} className='grid-margin-top-0'>
                                {renderEachAlert.map((item, i) => (
                                    <React.Fragment key={i}>
                                        {item}
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <div className='radial-chart-container'>
                                <RadialChart radialData={radialData} totalAlerts={totalAlerts}/>
                            </div>
                        </Grid>
                    </Grid>
                </a>
            );
        }
    }
    return (
        <>
            <Grid item xs={2}>
                <Card>
                    <StyledCardHeader
                        title={`Security Alerts ${totalAlerts}`}
                    />
                    <CardContent className='card-content'>
                        {loader ? (
                            <img
                                src={Page_Loader}
                                alt="loading"
                                className="loader-img"
                            ></img>
                        ) : renderContent()}
                    </CardContent>
                </Card>
            </Grid >

        </>
    );
}

export default HostWithAlerts;