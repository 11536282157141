import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DynamicFormField from "./DynamicFormField";
import platformNTFSFieldData from "./platformNTFSFieldData";
import "./ntfs.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  S3DETAILS,
  S3Verified,
  SET_NTFS_SNACKBAR_MESSAGE,
  VERIFYS3DATA,
} from "../TenantSignUp/tenantSignUpConstants";
import verifiedButton from "../../../assets/icons/verifiedButton.svg";
import { Button, makeStyles, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { isEmpty, isNull } from "lodash";

const useStyles = makeStyles(() => ({
  customVerificationButton: {
    textTransform: "none",
    height: "32px",
    marginTop: "10px",
  },
  customVerifiedButton: {
    marginTop: "10px",
  },
  disableVerifyButton: {
    cursor: "not-allowed",
  },
  activeVerifyButton: {
    cursor: "pointer",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NTFSPlatformChanges = ({ errorObj, setErrorObj, s3ResponseData }) => {
  const classes = useStyles();
  const s3Details = useSelector((state) => state.tenantSignUpReducer.s3Details);
  const isS3Verified = useSelector(
    (state) => state.tenantSignUpReducer.isS3Verified
  );
  const ntfsSnackBarMessage = useSelector(
    (state) => state.tenantSignUpReducer.ntfsSnackBarMessage
  );
  const dispatch = useDispatch();
  const { s3BucketName, accessKey, secretKey, isNTFSSelected } = s3Details;
  const [showSharedKey, setShowSharedKey] = useState(false);
  const [showAccessKey, setShowAccessKey] = useState(false);

  const toggleAccessKeyVisibility = () => {
    setShowAccessKey(!showAccessKey);
  };

  const toggleSharedKeyVisibility = () => {
    setShowSharedKey(!showSharedKey);
  };

  const fieldConfig = {
    "S3 Bucket": {
      value: s3BucketName,
      onChange: (event) => {
        dispatch({
          type: S3DETAILS,
          payload: { ...s3Details, s3BucketName: event.target.value },
        });
        dispatch({ type: S3Verified, payload: false });
        setErrorObj({
          ...errorObj,
          s3BucketName: "",
        });
      },
      errorMessage: errorObj.s3BucketName,
    },
    "Access Key": {
      value: accessKey,
      onChange: (event) => {
        dispatch({
          type: S3DETAILS,
          payload: { ...s3Details, accessKey: event.target.value },
        });
        dispatch({ type: S3Verified, payload: false });
        setErrorObj({
          ...errorObj,
          accessKey: "",
        });
      },
      showPassword: showAccessKey,
      togglePasswordVisibility: toggleAccessKeyVisibility,
      errorMessage: errorObj.accessKey,
    },
    "Secret Key": {
      value: secretKey,
      onChange: (event) => {
        dispatch({
          type: S3DETAILS,
          payload: { ...s3Details, secretKey: event.target.value },
        });
        dispatch({ type: S3Verified, payload: false });
        setErrorObj({
          ...errorObj,
          secretKey: "",
        });
      },
      showPassword: showSharedKey,
      togglePasswordVisibility: toggleSharedKeyVisibility,
      errorMessage: errorObj.secretKey,
    },
  };

  const renderFields = () => {
    return platformNTFSFieldData.map((field, index) => {
      const { label, type, placeholder, autoComplete } = field;
      const config = fieldConfig[label];

      return (
        <DynamicFormField
          key={index}
          label={label}
          value={config.value}
          type={type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={config.onChange}
          showPassword={config.showPassword}
          togglePasswordVisibility={config.togglePasswordVisibility}
          error={config.errorMessage}
        />
      );
    });
  };

  const handleS3Verification = () => {
    dispatch({ type: VERIFYS3DATA });
  };
  const handleS3ButtonDisable = () => {
    return isEmpty(s3BucketName) || isEmpty(accessKey) || isEmpty(secretKey);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({type: SET_NTFS_SNACKBAR_MESSAGE, payload: ""});
  };

  // Function to render verification button
  const renderVerification = () => {
    if (isS3Verified) {
      return (
        <div className="logSourceVerificationContainer">
          <img
            src={verifiedButton}
            alt="verified"
            className={classes.customVerifiedButton}
          />
        </div>
      );
    }

    const isButtonDisabled = handleS3ButtonDisable();
    const buttonTitle = isButtonDisabled
      ? "Please enter all the details of S3"
      : "";

    return (
      <div title={buttonTitle}>
        <Button
        variant="contained"
        color="primary"
        onClick={handleS3Verification}
        disabled={isButtonDisabled}
        className={classes.customVerificationButton}
      >
        Verification
      </Button>
      </div>
    );
  };

  return (
    <div className="ntfsMainContainer">
      <div className="selectiveUsers platformNTFS">
        <span className="selectiveUserText platformNTFS">NTFS</span>
        <FormControlLabel
          control={
            <Switch
              checked={isNTFSSelected}
              onChange={() =>
                dispatch({
                  type: S3DETAILS,
                  payload: { ...s3Details, isNTFSSelected: !isNTFSSelected },
                })
              }
              color="primary"
            />
          }
        />
      </div>
      {isNTFSSelected && (
        <div>
          <div className="ntfsS3fieldContainer">{renderFields()}</div>
          <div
            className={
              handleS3ButtonDisable()
                ? classes.disableVerifyButton
                : classes.activeVerifyButton
            }
          >
            {renderVerification()}
          </div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!isEmpty(ntfsSnackBarMessage)}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {ntfsSnackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NTFSPlatformChanges;
