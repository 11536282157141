import { 
  UPDATE_DATASOURCE_URL, 
  UPDATE_DATASOURCE, 
  GET_DATASOURCE, 
  GET_DATASOURCE_DATA, 
  UPDATE_DATASOURCE_SUCCESS, 
  UPDATE_DROPBOX_CONNECTION_STATUS, 
  RESET_DROPBOX_CONNECTION_STATUS, 
  TRIGGER_TOKEN, 
  TRIGGER_TOKEN_URL, 
  DROPBOX_TRIGGER_URL, 
  DROPBOX_TRIGGER,
  DATASOURCE_SAVE_ALIAS_NAME, 
  DATASOURCE_NAME_EDIT_SUCCESS, 
  GET_ALL_TYPES_OF_DATASOURCE,
  SET_SOURCE_ERROR_MESSAGE,
  SAVE_API_ERROR,
  GROUPS_SAVE_API,
  GET_ALL_CLOUD_DRIVE_GROUPS,
  getAllGroups,
  SET_CLOUD_SOURCE_FIELDS_DATA,
  GROUPS_SAVE_SUCCESSFUL,
  RESET_GROUPS_SAVE_SUCCESS
} from '../constants/constants';
import { getService, getAxiosHeaders } from '../../library/RestAPI';
import { call, put, select, takeEvery } from "redux-saga/effects";
import { saveAliasName } from '../actions/saveAliasNameAction';
import { DOWNLOAD_NTFS_CONFIGURATION, getNTFSConfiguration } from '../../components/views/manageDataSource/dataSourceConstants';

function* updateDatasource(action) {
  let options;
  try {
    options = {
      method: "PUT",
      url: UPDATE_DATASOURCE_URL(action.payload.datasource.application.id, action.payload.datasource.id),
      headers: getAxiosHeaders(true),
      data: {consentType: action.payload.consentType, ...action.payload.datasource}
    };
    try {
      const res = yield getService(options);
      if (res) {
        yield put({ type: UPDATE_DATASOURCE_SUCCESS, payload: res.data });
      }
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    yield put({ type: "UPDATE_DATASOURCE_FAILED" });
  }
};

function* dropBoxTrigger(action) {
  let options;
  try {
    options = {
      method: "PUT",
      url: DROPBOX_TRIGGER_URL(action.payload.datasource.application.id, action.payload.datasource.id, action.payload.data.code),
      headers: getAxiosHeaders(true),
    };try {
      const res = yield getService(options);
      if (res) {
        yield put({ type: UPDATE_DATASOURCE_SUCCESS, payload: res.data });
        yield put({ type: UPDATE_DROPBOX_CONNECTION_STATUS});
      }
    } catch (e) {
      console.log(e);
      yield put({type: RESET_DROPBOX_CONNECTION_STATUS});
    }
  } catch (e) {
    yield put({ type: "UPDATE_DATASOURCE_FAILED" });
  }
}

function* triggerToken(action) {
  let options;
  try {
    options = {
      method: "PUT",
      url: TRIGGER_TOKEN_URL(action.payload.datasource.application.id, action.payload.datasource.id, action.payload.consentType),
      headers: getAxiosHeaders(true),
    };
    try {
      const res = yield getService(options);
      if (res) {
        yield put({ type: UPDATE_DATASOURCE_SUCCESS, payload: res.data });
      }
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    yield put({ type: "UPDATE_DATASOURCE_FAILED" });
  }
};

function* getDatasource(action) {
  yield put({ type: "UPDATE_DATASOURCE_FAILED" });
  try {
    const res = yield getService({
      method: "GET",
      url: `${GET_DATASOURCE_DATA(action.payload.dataSourceID)}`,
      headers: getAxiosHeaders(true),
    })
    if (res) {
      yield put({ type: UPDATE_DATASOURCE_SUCCESS, payload: res.data });
      if (action.payload.consentType) {
        yield put({ type: TRIGGER_TOKEN, payload: {
          datasource: res.data,
          consentType: action.payload.consentType,
        }});
      } else if (action.payload.dropBoxData) {
        yield put({ type: DROPBOX_TRIGGER, payload: {
          datasource: res.data,
          data: action.payload.dropBoxData,
        }});
      }
    }
  } catch (e) {
    console.log(e);
    yield put({ type: "UPDATE_DATASOURCE_FAILED" });
  }
};

// Saga worker function for saving alias
function* saveDataSourceAliasName(action) {
  const { name, applicationId, dataSourceId } = action.payload;
  try {
    const response = yield call(saveAliasName, name, applicationId, dataSourceId);
    
    if (response.status === 200) {
      yield put({
        type: UPDATE_DATASOURCE_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: DATASOURCE_NAME_EDIT_SUCCESS,
        payload: true
      });
    } else {
      yield put({type: SET_SOURCE_ERROR_MESSAGE, payload: SAVE_API_ERROR});
    }
  } catch (error) {
    yield put({type: SET_SOURCE_ERROR_MESSAGE, payload: SAVE_API_ERROR});
  }
}

function* getAllCloudDriveGroups (action) {
  const cloudSourceData = yield select(state => state.CreateSourceReducer.cloudSourceData)
  const dsId = action.payload;
  const options = {
    method: "GET",
    url: getAllGroups(dsId),
    headers: getAxiosHeaders(true),
  }
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({type: SET_CLOUD_SOURCE_FIELDS_DATA, payload: {
        ...cloudSourceData,
        allGroups: res.data
      }})
    } else {
      const errorMessage = res && res.response && res.response.data.error ? res.response.data.error : "Failed to fetch groups"
      yield put({type: SET_CLOUD_SOURCE_FIELDS_DATA, payload: {
        ...cloudSourceData,
        allGroups: []
      }})
      yield put({ type: SET_SOURCE_ERROR_MESSAGE, payload: errorMessage })
    }

  } catch (error) {
    yield put({ type: SET_SOURCE_ERROR_MESSAGE, payload: error })
  }
}

function* groupsSaveAPI (action) {
  const {id, data} = action.payload
  const options = {
    method: "PUT",
    url: getAllGroups(id),
    headers: getAxiosHeaders(true),
    data: data
  }
  try {
    const res = yield getService(options);
    if (res && res.status === 204) {
      yield put({ type: GROUPS_SAVE_SUCCESSFUL});
    } else {
      const errorMessage = "Failed to save groups" 
      yield put({ type: SET_SOURCE_ERROR_MESSAGE, payload: errorMessage })
      yield put({ type: RESET_GROUPS_SAVE_SUCCESS });
    } 
  } catch (error) {
    yield put({ type: RESET_GROUPS_SAVE_SUCCESS });
    yield put({ type: SET_SOURCE_ERROR_MESSAGE, payload: error })
  }
}

function* getAllTypesOfDataSource(action) {
  yield put({ type: "UPDATE_SEARCH_VALUE", payload: action.payload });
  yield put({ type: "GET_NORMAL_DATA_SOURCE_INFO_DATA" });
  yield put({ type: "GET_FLAGGED_DATA_SOURCE_INFO_DATA" });
} 

function* downloadNTFSConfiguration(action) {
  const { id, name } = action.payload;
  const options = {
    method: "GET",
    url: getNTFSConfiguration(id),
    headers: getAxiosHeaders(true),
    responseType: "blob", // Important: tells axios to handle binary data
  };

  try {
    const res = yield getService(options);

    if (res && res.status === 200) {
      // Create a blob from the response data
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `configuration_${name}.zip`;
      link.click();
      window.URL.revokeObjectURL(url);

      console.log("File downloaded successfully");
    } else {
      console.log("Something went wrong, Please try later");
    }
  } catch (err) {
    console.log(err);
  }
}

function* datasourceSaga() {
  yield takeEvery(UPDATE_DATASOURCE, updateDatasource);
  yield takeEvery(TRIGGER_TOKEN, triggerToken);
  yield takeEvery(DROPBOX_TRIGGER, dropBoxTrigger);
  yield takeEvery(GET_DATASOURCE, getDatasource);
  yield takeEvery(DATASOURCE_SAVE_ALIAS_NAME, saveDataSourceAliasName);
  yield takeEvery(GET_ALL_TYPES_OF_DATASOURCE, getAllTypesOfDataSource);
  yield takeEvery(GET_ALL_CLOUD_DRIVE_GROUPS, getAllCloudDriveGroups);
  yield takeEvery(GROUPS_SAVE_API, groupsSaveAPI);
  
  yield takeEvery(DOWNLOAD_NTFS_CONFIGURATION, downloadNTFSConfiguration);
}

export default datasourceSaga;