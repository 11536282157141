import { CLEAR_ALL_FOLDER_DATA, SET_ALL_FOLDERS_DATA, SET_FOLDER_CURRENT_PAGE, SET_FOLDER_CURRENT_SIZE, SET_NTFS_LOADER, SET_TOTAL_FOLDER_COUNT } from "./ntfsConstants";

const initialState = {
  currentPage: 0,
  pageSize: 20,
  allFoldersData: {
    apiSuccess: false,
    data:null
  },
  totalFolderCount: 0,
  loader: false,
};

export const ntfsReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_FOLDER_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      }
    }
    case SET_FOLDER_CURRENT_SIZE: {
      return {
        ...state,
        pageSize: action.payload
      }
    }
    case SET_ALL_FOLDERS_DATA: {
      return {
        ...state,
        allFoldersData: {
          apiSuccess: true,
          data: action.payload
        },
      }
    }
    case SET_NTFS_LOADER: {
      return {
        ...state,
        loader: action.payload
      }
    }
    case SET_TOTAL_FOLDER_COUNT: {
      return {
        ...state,
        totalFolderCount: action.payload
      }
    }
    case CLEAR_ALL_FOLDER_DATA: {
      return {
        ...state,
        allFoldersData: {
          apiSuccess: false,
          data: null
        },
        currentPage: 0,
        pageSize: 20
      }
    }
    default:
      return state;
  }
}
