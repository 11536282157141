import React from 'react';
import { SET_SITEMAP_EXPAND_ACCORDION, GET_GROUP_BY_DATA, UNGROUPED, OPEN_NETSPACE_DRAWER, SET_PAGINATION_MODEL, defaultPaginationModel, LOADING_DATA } from './NetSpaceConstant';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataOwnerContentTable from '../../components/shared/DataOwnerContentTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import NetSpaceUserTag from './NetSpaceUserTag';
import { startCase, toLower } from 'lodash';
import {
    renderHostIPFnWrapper,
    NetSpaceTableColumns,
} from './NetSpaceUtils';
import { de } from 'date-fns/locale';

const AccordionUseStyles = makeStyles({
    accordionDetails: {
        height: '500px',
        overflowY: 'auto',
    },
});

const NetSpaceTable = ({ group }) => {
    const dispatch = useDispatch();
    const renderHostIP = renderHostIPFnWrapper(dispatch, group);
    const selectedGroup = useSelector((state) => state.NetspaceReducer.selectedGroup);
    const expandAccordionState = useSelector((state) => state.NetspaceReducer.expandAccordion);
    const loader = useSelector((state) => state.NetspaceReducer.loader);
    const accordionData = useSelector((state) => state.NetspaceReducer.accordionData[group]) || {};
    const data = accordionData.data;
    const paginationModel = (accordionData && accordionData.paginationModel) ? accordionData.paginationModel : defaultPaginationModel;
    const handleAccordionExpand = (accordionName) => {
        let expandAccordionUpdate = { ...expandAccordionState };
        expandAccordionUpdate[accordionName] = !expandAccordionUpdate[accordionName];
        dispatch({
            type: SET_SITEMAP_EXPAND_ACCORDION,
            payload: expandAccordionUpdate
        });
    }
    const loadContent = (selectedGroup, paginationModel) => {
        dispatch({
            type: GET_GROUP_BY_DATA,
            payload: { key: selectedGroup, value: group, pageNo: paginationModel.page, pageSize: paginationModel.pageSize }
        });
    }
    useEffect(() => {
        loadContent(selectedGroup, paginationModel);
    }, [selectedGroup, paginationModel.page, paginationModel.pageSize]);
    const useStyles = makeStyles({
        accordionSummary: {
            flexDirection: 'row-reverse',
            backgroundColor: '#F1E9F6'
        }
    });
    const handlePaginationChange = (params) => {
        const newPagination = {
            pageSize: params.pageSize,
            page: params.page,
        };
        if (paginationModel.pageSize !== newPagination.pageSize) {
            newPagination.page = 0;
        }
        dispatch({
            type: SET_PAGINATION_MODEL,
            payload: { newModel: newPagination, key: group }
        });
    };
    const classes = useStyles();

    function getRowId(row) {
        return row.nodeId;
    }

    const handleRowClickEvent = (params) => {
        dispatch({
            type: OPEN_NETSPACE_DRAWER,
            payload: { selectedHost: params.row, updateTagGroup: group }
        });
    }
    const Accordionclasses = AccordionUseStyles();
    const columns = NetSpaceTableColumns(renderHostIP);
    const defaultName = (selectedGroup === UNGROUPED) ? UNGROUPED : 'NA'
    const gotData = data && data.content && data.content.length > 0;
    return (
        <>
            <div className="site-map-wrapper">
                <Accordion expanded={expandAccordionState[group] || false} onClick={() => handleAccordionExpand(group)} key={group}>
                    <AccordionSummary
                        key={group}
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{group ? startCase(toLower(group)) : defaultName}
                            {gotData ? ' (' + (data.totalElements || 0) + ')' : null}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={Accordionclasses.accordionDetails} key={group}>
                        {gotData ?
                            <div className="netspace-tbl" onClick={(e) => e.stopPropagation()}>
                                <DataGrid
                                    key={group}
                                    className="custom-data-grid"
                                    rows={data.content}
                                    columns={columns}
                                    rowCount={data.totalElements || 0}
                                    rowHeight={90}
                                    showCellRightBorder={false}
                                    showColumnRightBorder={false}
                                    showCellVerticalBorder={true}
                                    disableColumnFilter={true}
                                    disableColumnSelector
                                    disableColumnMenu
                                    paginationMode="server"
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={handlePaginationChange}
                                    pageSizeOptions={[5, 10, 20, 50, 100]}
                                    getRowId={getRowId}
                                    onRowClick={handleRowClickEvent}
                                    hideFooterSelectedRowCount={true}
                                    getRowClassName={(params) => {
                                        return `site-map-table-row`;
                                    }}
                                    getRowSpacing={(params) => ({
                                        top: 10,
                                        bottom: 5,
                                    })}
                                />
                            </div>
                            : LOADING_DATA}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
                <NetSpaceUserTag/>
            </div>
        </>
    );
}

export default NetSpaceTable;