import React, { useEffect, useState } from "react";
import Toggler from "../shared/Toggler";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_TOTAL_ASSETS_COUNT, CLEAR_ALL_ASSETS_TOTAL_COUNT } from "../../redux/constants/constants";



export default function TotalAssetsCount({ onTabChange }) {
  const dispatch = useDispatch();
  const totalAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.totalAssetsCount
  );
  const totalOneDriveAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.oneDriveAssetsCount
  );
  const dropBoxAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.dropBoxAssetsCount
  );
  const googleDriveAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.googleDriveAssetsCount
  );
  const ntfsAssetsCount = useSelector(
    (state) => state.TotalAssetsCountReducer.ntfsAssetsCount
  );
  const [togglers, setTogglers] = useState([
    { name: "OneDrive", state: "active", count: 0 },
    { name: "Dropbox", state: "disable", count: 0 },
    { name: "GoogleDrive", state: "disable", count: 0 },
    { name: "NTFS", state: "disable", count: 0 },
  ]);
  
  useEffect(() => {
    dispatch({
      type: FETCH_TOTAL_ASSETS_COUNT
    });
    return(() => {
      dispatch({
        type: CLEAR_ALL_ASSETS_TOTAL_COUNT
      });
    });
  },[]);
  
  useEffect(() => {
    const activeToggle = [{state: "active"}, {state: "disable"}, {state: "disable"}, {state: "disable"}];
    if (dropBoxAssetsCount>0 && totalOneDriveAssetsCount==0) {
      activeToggle[0].state = "disable";
      activeToggle[1].state = "active";
      activeToggle[2].state = "disable";
      activeToggle[3].state = "disable";
      handleTabChange(togglers[1].name);
    } else if (totalOneDriveAssetsCount==0 && dropBoxAssetsCount==0 && googleDriveAssetsCount>0) {
      activeToggle[0].state = "disable";
      activeToggle[1].state = "disable";
      activeToggle[2].state = "active";
      activeToggle[3].state = "disable";
      handleTabChange(togglers[2].name);
    } else if (totalOneDriveAssetsCount==0 && dropBoxAssetsCount==0 && googleDriveAssetsCount==0 && ntfsAssetsCount>0) {
      activeToggle[0].state = "disable";
      activeToggle[1].state = "disable";
      activeToggle[2].state = "disable";
      activeToggle[3].state = "active";
      handleTabChange(togglers[3].name);
    }
    setTogglers(prevTogglers => [
      { ...prevTogglers[0], count: totalOneDriveAssetsCount, ...activeToggle[0] },
      { ...prevTogglers[1], count: dropBoxAssetsCount, ...activeToggle[1] },
      { ...prevTogglers[2], count: googleDriveAssetsCount, ...activeToggle[2] },
      { ...prevTogglers[3], count: ntfsAssetsCount, ...activeToggle[3] }
    ]);
  }, [totalOneDriveAssetsCount, dropBoxAssetsCount, googleDriveAssetsCount, ntfsAssetsCount]);

  const handleTabChange = (activeTab) => {
    onTabChange(activeTab);
  };


  return (
    <div className="tabSwitch">
      <div className="tab_switch_heading">
      <div className="tab_switch_heading_name">Total Assets</div>
      <div className="tab_switch_heading_count">{totalAssetsCount}</div>
      </div>
      <div className="togglerandSearch_Conatiner">
        <Toggler
          togglers={togglers}
          setTogglers={setTogglers}
          handleToggle={handleTabChange}
        ></Toggler>
      </div>
    </div>
  );
}