import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const DynamicFormField = ({
  label,
  value,
  type,
  placeholder,
  autoComplete,
  onChange,
  showPassword,
  togglePasswordVisibility,
  error,
}) => {
  return (
    <div className="platformNTFSFieldParentContainer">
      <div className="platformFieldHeadingsContainer">
        <div className="platformFieldHeadings">{label}</div>
        <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        value={value}
        variant="outlined"
        fullWidth
        type={type === "password" && showPassword ? "text" : type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        InputProps={{
          style: { color: "black" },
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      />
      {error ? <div className="errorMessageContainer">{error}</div> : ""}
    </div>
  );
};

export default DynamicFormField;
