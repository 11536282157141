import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ColoredActionButton, ConnectButton, SaveButton } from "../common/buttons";
import { Button, Chip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  GET_DATABASE_ENGINES_URL,
  DATASOURCE_URL2,
  ONEDRIVE,
  DROPBOX,
  CREATE_DATA_SOURCE,
  SET_DATA_SOURCE_DETAILS,
  OTHER,
  SET_CLOUD_SOURCE_FIELDS_DATA,
  CLEAR_CLOUD_SOURCE_FIELDS_DATA,
  AWS,
  AZURE,
  ON_PREMISE,
  CLEAR_DATASOURCE_NAME_EDIT_SUCCESS,
  GOOGLEDRIVE,
  POSTGRESQL,
  GROUPS_SAVE_API,
  RESET_GROUPS_SAVE_SUCCESS,
  MS_CLOUD_FILE_SYSTEM,
  SET_NTFS_HOST,
  SET_NTFS_DIRECTORIES,
  SET_NTFS_PROPOSED,
} from "../../redux/constants/constants";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import OneDriveLogo from "../../assets/icons/OneDriveLogo.svg";
import SharePointLogo from "../../assets/icons/SharePointLogo.svg";
import { getService, getAxiosHeaders } from "../../library/RestAPI";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull } from "lodash";
import { useLocation } from "react-router-dom";
import { DropBoxConnection } from "./DropBoxConnection";
import { isCloudFileSystem, isMSSQLDatabaseOnCloud, isCloudSQLSystem } from "../common/utilities";
import DynamicFormFields from "./manageDataSource/DynamicFormFields";
import LogSourceSelection from "./manageDataSource/logSourceSelection";
import { renderDataSourceIcons } from "./manageDataSource/DatasourceIcons";
import { validateAliasName, validateApplicationName, validateDBName, validateDomainName, validateHostAndPort, validateInstanceAndHost, validateNTFSDirectories, validateNTFSHost, validateNTFSProcessNames, validateOneDriveUserCount, validateUsernameAndPassword } from "./manageDataSource/dataSourceErrorChecks";
import OneDriveDataSourceForm from "./manageDataSource/oneDriveDataSourceForm";
import DropboxDataSourceForm from "./manageDataSource/DropboxDataSourceForm";
import { cloudDataSourceSave } from "./manageDataSource/cloudDataSourceSave";
import { VerifyAndSaveConnection, saveAliasName } from "./manageDataSource/dataSourceConnections";
import GoogleDriveDataSourceForm from "./manageDataSource/GoogleDriveDataSourceForm";
import { generateIconMap, renderDataSourceData } from "./manageDataSource/dataSourceUtilities";
import { dataSourceButtons } from "./manageDataSource/uitilitiesComp";
import { NTFS } from "./manageDataSource/dataSourceConstants";
import NTFSDataSource from "./manageDataSource/NTFSDataSource/NTFSDataSource";


export const CreateDataSource = (props) => {
  const currentRoute = useLocation();
  const dataSourceErrorMessage = useSelector(
    (state) => state.CreateSourceReducer.errorMessage
  );
  const dataSourceDetails = useSelector(
    (state) => state.CreateSourceReducer.dataSourceData
  );
  const isAliasNameEdit = useSelector(
    (state) => state.CreateSourceReducer.isAliasNameEdit
  );
  const ntfsHost = useSelector((state) => state.CreateSourceReducer.ntfsHost);
  const ntfsDirectories = useSelector((state) => state.CreateSourceReducer.ntfsDirectories);
  const ntfsProcessNames = useSelector((state) => state.CreateSourceReducer.ntfsProcessNames);
  const cloudSourceData = useSelector(state => state.CreateSourceReducer.cloudSourceData);
  const { domainName, groupName, limitUsers, cloudSourceUsers, noOfUsersError, oneDriveSelected, sharePointSelected } = cloudSourceData;
  const [iconMap, setIconMap] = useState({});
  const [isConfigurationDone, setIsConfigurationDone] = useState(false);
  const [selectedLogMode, setSelectedLogMode] = useState("Push");
  const {
    isEditMode,
    isAliasEdit,
    toggleDrawer,
    staticInstructionsOpen,
    setSourceType,
    logSourceOpen,
    loadDataSourceData,
    sourceType
  } = props;
  const [aliasName, setAliasName] = useState("");
  //To close the side panel after successful edit storing the event. 
  const [saveAliasNameEvent, setSaveAliasNameEvent] = useState(null);
  const [engine, setDesiredEngine] = useState("");
  const [selectedLogSourceId, setSelectedLogSourceId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [connectionProperties, setConnectionProperties] = useState("");
  const [schema, setSchema] = useState(null);
  const [connectionMsg, setConnectionMsg] = useState("");
  const [desiredHost, setDesiredHost] = useState("");
  const [DBName, setDBName] = useState("");
  const [isDSSaved, setIsDSSaved ] = useState(false);
  const [desiredPort, setDesiredPort] = useState("");
  const [types, setTypes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [applications, setApplications] = useState([]);
  const [applicationName, setApplicationName] = useState("");
  const [port, setPortName] = useState("");
  const [address, setIpAddress] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [instance, setInstance] = useState("");
  const [newAppName, setNewAppName] = useState("");
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [error, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const dataSourceData = useSelector((state) => state.datasourceData);
  const [cloudDSSaved, setCloudDSSaved] = useState(false);

  const getDataService = (url) => {
    return getService({
      method: "GET",
      url: `${url}`,
      headers: getAxiosHeaders(true),
    });
  };

  useEffect(() => {
    setSourceType(engine);
    setIconMap(generateIconMap(engine))
  }, [engine]);

  useEffect(() => {
    getAPICall();
  }, []);

  useEffect(() => {
    sourceType(engine)
  },[engine])


  //When there is an error description after re-routing to app from MS365 consent page
  useEffect(() => {
    let error_message = "";
    const urlParam = new URLSearchParams(currentRoute.search);
    const error_description = urlParam.get("error_description");
    if (!isEmpty(error_description)) {
      error_message = error_description.split(":")[1].split(".")[0];
      setOpen(true);
      setSnackBarMessage(error_message);
    }
  }, [currentRoute]);

  useEffect(() => {
    dispatch({ type: RESET_GROUPS_SAVE_SUCCESS })
    return () => {
      dispatch({ type: CLEAR_CLOUD_SOURCE_FIELDS_DATA });
      dispatch({type: CLEAR_DATASOURCE_NAME_EDIT_SUCCESS});
    };
  }, []);

  useEffect(() => {
    if (isEditMode && !isEmpty(dataSourceData)) {
      setDBName(dataSourceData.databaseName);
      setIpAddress(dataSourceData.host);
      setPortName(dataSourceData.port);
      setDesiredPort(dataSourceData.port);
      setInstance(dataSourceData.instance);
      setAliasName(dataSourceData.aliasName);
      setDesiredEngine(dataSourceData.type.code);
      setApplicationName(dataSourceData.application.name);
      dispatch({
        type: SET_CLOUD_SOURCE_FIELDS_DATA,
        payload: {
          ...cloudSourceData,
          domainName: dataSourceData.domainName,
          groupName: dataSourceData.groupName || "",
          cloudSourceUsers: dataSourceData.noOfUsers || ""
        },
      });
      dispatch({type: SET_NTFS_HOST, payload: dataSourceData.domainName});
      dispatch({type: SET_NTFS_DIRECTORIES, payload: dataSourceData.directoryNames});
      dispatch({type: SET_NTFS_PROPOSED, payload: dataSourceData.processNames});
    }
  }, [dataSourceData]);

  useEffect(() => {
    // Check if alias name is being edited
    if (isAliasNameEdit) {
        setLoader(false); // If alias name is being edited, set loader to false
        props.isAliasEdit(true); // Inform parent component that alias is being edited
        props.toggleDrawer(false, saveAliasNameEvent);
    } else if (!isEmpty(dataSourceErrorMessage)) {
        // If there is an error message for data source
        setLoader(false); // Set loader to false
        setSnackBarMessage(dataSourceErrorMessage); // Set snack bar message to data source error message
        setOpen(true); // Open snack bar
    }
}, [isAliasNameEdit, saveAliasNameEvent]);



  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getAPICall = async () => {
    await Promise.all([
      getDataService(GET_DATABASE_ENGINES_URL),
      getDataService(DATASOURCE_URL2),
    ]).then((data) => {
      if (data && data[0].status === 200) {
        //Excluding SQLSERVER as we have MSSQL.
        const dataTypes = data[0].data.content.filter((name) => name.code !== "SQLSERVER" ? name.value : "")
        setTypes(dataTypes);
        if (!loadDataSourceData) {
          if (!isEmpty(dataSourceData) && currentRoute.search !== "") {
            setDesiredEngine(dataSourceData.type.code);
          } else {
            setDesiredEngine(data[0].data.content[0].code);
          }
          if (data && data[1].status === 200) {
            const temp = data[1].data.content.map((app) => app.name);
            setApplications(temp);
            setApplicationName(temp[0]);
            setLoader(false);
          } else {
            setLoader(false);
          }
        } else {
          setAliasName(dataSourceDetails.aliasName);
          setDesiredEngine(dataSourceDetails.engine);
          setPortName(dataSourceDetails.port);
          setDBName(dataSourceDetails.databaseName);
          setIpAddress(dataSourceDetails.host);
          setInstance(dataSourceDetails.instance);
          setApplicationName(dataSourceDetails.applicationName);
          dispatch({type: SET_CLOUD_SOURCE_FIELDS_DATA, payload: {domainName: dataSourceDetails.domainName}});
        }
        setLoader(false);
      }
    });
  };

const handleValidation = () => {
  let existingValue = false;
  // Check if the new app name already exists
  applications.forEach((e) => {
    if (e.name === newAppName) {
      existingValue = true;
    }
  });

  const errors = {};

  // If it's in edit mode
  if (isEditMode) {
    // If the engine is not "OTHER"
    if (engine !== OTHER) {
      Object.assign(errors, validateUsernameAndPassword(engine, username, password));
      Object.assign(errors, validateInstanceAndHost(engine, instance, desiredPort, address, desiredHost));
      Object.assign(errors, validateDBName(engine, DBName));
      Object.assign(errors, validateNTFSHost(engine, ntfsHost));
      Object.assign(errors, validateNTFSDirectories(engine, ntfsDirectories));
      Object.assign(errors, validateNTFSProcessNames(engine, ntfsProcessNames));
    }
  } else {
    // If it's not in edit mode
    if (engine !== OTHER) {
      // Validation for adding a new application
      Object.assign(errors, validateApplicationName(applicationName));
      Object.assign(errors, validateAliasName(aliasName));
      Object.assign(errors, validateDomainName(engine, domainName));
      Object.assign(errors, validateOneDriveUserCount(engine, limitUsers, cloudSourceUsers));
      Object.assign(errors, validateHostAndPort(engine, address, port));
      Object.assign(errors, validateDBName(engine, DBName));
      Object.assign(errors, validateNTFSHost(engine, ntfsHost));
      Object.assign(errors, validateNTFSDirectories(engine, ntfsDirectories));
      Object.assign(errors, validateNTFSProcessNames(engine, ntfsProcessNames));
    }
  }
  
  return errors;
};

const handleSave = (event) => {
  const errors = handleValidation();
  setErrors(errors);

  if (Object.keys(errors).length > 0) {
    return;
  }

  const isCloudSystem = isCloudSQLSystem(engine);
  const hostType = isCloudSystem
    ? iconMap.AzureServer.selected
      ? AZURE
      : iconMap.AWSServer.selected
      ? AWS
      : ON_PREMISE
    : "";

  const request =
    engine === NTFS
      ? {
          aliasName,
          engine,
          applicationName,
          domainName: ntfsHost,
          directoryNames: ntfsDirectories,
          processNames: ntfsProcessNames
        }
      : {
          aliasName,
          engine,
          port,
          databaseName: DBName,
          host: address,
          hostType,
          instance,
          applicationName,
          username,
          password,
          domainName,
          connectionProperties,
          logConfigMode: isCloudSystem ? selectedLogMode.toUpperCase() : "",
          logSourceId: selectedLogSourceId,
        };

  dispatch({
    type: CREATE_DATA_SOURCE,
    payload: {
      data: request,
      toggleDrawer: props.toggleDrawer(false, event),
      allDataSources: props,
    },
  });

  setSnackBarMessage(dataSourceErrorMessage);
  setOpen(true);
};


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "CLOSE_SNACKBAR" });
  };

  const sourceChange = (event) => {
    setDesiredEngine(event.target.value);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  //To remove individual field errors
  const removeErrorByKey = (key) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (key in updatedErrors) {
        delete updatedErrors[key];
      }
      return updatedErrors;
    });
  };

  const handleConnect = (event, type, dispatch) => {
    let request = {
      aliasName,
      engine,
      applicationName,
      domainName,
      groupName,
      noOfUsers: limitUsers ? cloudSourceUsers : 0,
    };
    if(engine === ONEDRIVE) {
      request.hostType = MS_CLOUD_FILE_SYSTEM;
      request.oneDrive =  oneDriveSelected;
      request.sharePoint = sharePointSelected;
    }
    cloudDataSourceSave(
      event,
      type,
      dispatch,
      setErrors,
      setLoader,
      setSnackBarMessage,
      setOpen,
      handleValidation,
      isEditMode,
      dataSourceData,
      request,
      engine,
      currentRoute,
      cloudSourceData,
      open,
      setCloudDSSaved
    );
  };

  const handleNumberInput = (value, loc) => {
    // Use parseInt to check for valid numeric values
    const numericValue = parseInt(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      const result = Math.abs(numericValue);
      if (loc === "users") {
        dispatch({
          type: SET_CLOUD_SOURCE_FIELDS_DATA,
          payload: {
            ...cloudSourceData,
            cloudSourceUsers: result,
            noOfUsersError: "",
          },
        });
      } else {
        setPortName(result);
      }
    } else {
      if (loc === "users") {
        dispatch({
          type: SET_CLOUD_SOURCE_FIELDS_DATA,
          payload: { ...cloudSourceData, cloudSourceUsers: "", noOfUsersError: "" },
        });
      } else {
        setPortName(""); // Clear the input field if the entered value is invalid
      }
    }
  };

  const saveDataSourceFieldData  = () => {
    const request = {
      aliasName,
      engine,
      port,
      databaseName: DBName,
      host: address,
      instance,
      applicationName,
      domainName,
    };
    dispatch({ type: SET_DATA_SOURCE_DETAILS, payload: request });
  };

  const handleSelectionChange = (altText, isIconClick) => {
    const updatedIconMap = {};
    for (const key in iconMap) {
      updatedIconMap[key] = {
        ...iconMap[key],
        selected: key === altText && isIconClick,
      };
    }
    setIconMap(updatedIconMap);
  };

  const generateSetFunctions = () => {
    const defaultSetFunctions = {
      address: setIpAddress,
      port: handleNumberInput,
      instance: setInstance,
      DBName: setDBName,
    };

  
    if (isEditMode) {
      return {
        ...defaultSetFunctions,
        username: setUsername,
        password: setPassword,
        desiredPort: setDesiredPort,
        desiredHost: setDesiredHost,
        schema: setSchema,
        connectionProperties: setConnectionProperties,
      };
    } else if (isMSSQLDatabaseOnCloud(engine, iconMap)) {
      return {
        ...defaultSetFunctions,
        username: setUsername,
        password: setPassword,
        connectionProperties: setConnectionProperties,
      };
    } else {
      return defaultSetFunctions;
    }
  };

  const setFunctions = Object.fromEntries(
    Object.entries(generateSetFunctions()).map(([fieldName, setter]) => [
      fieldName,
      (value) => setter(value),
    ])
  );

  //To render data source form
  const dataSourceForm = () => {
    // Define fieldValues object
    let fieldValues = { address, port, instance, DBName };
  
    if (isEditMode) {
      fieldValues = {
        ...fieldValues,
        username,
        password,
        desiredPort,
        desiredHost,
        schema,
        connectionProperties,
      };
    } else  if (isMSSQLDatabaseOnCloud(engine, iconMap)) {
       fieldValues = {
        ...fieldValues,
        username,
        password,
        connectionProperties,
       };
    }
  
    const disabledValues = () => {
      let disabledValues = {
        address: false,
        port: false,
        schema: false,
      };
      if (isEditMode) {
        disabledValues = {
          address: true,
          port: port && !isEmpty(port.toString()),
          schema: isCloudSQLSystem(engine),
        };
      }
      return disabledValues;
    };
  
    return (
      <>
        {isCloudSQLSystem(engine) && !isEditMode
          ? renderDataSourceIcons(
              isEditMode,
              iconMap,
              handleSelectionChange,
              engine
            )
          : null}
        <DynamicFormFields
          data={renderDataSourceData(engine, iconMap)}
          setFunctions={setFunctions}
          fieldValues={fieldValues} // Pass the modified fieldValues object
          applications={applications}
          isEditMode={isEditMode}
          disabledValues={disabledValues()}
          errors={error}
          removeErrorByKey={removeErrorByKey}
        />
      </>
    );
  };

  const resetFormFields = () => {
    setUsername("");
    setPassword("");
    setIpAddress("");
    setDesiredHost("");
    setPortName("");
    setDesiredPort("");
    setSchema(null);
    setDesiredEngine("");
    setConnectionProperties("");
    setInstance("");
    setAliasName("");
  }
  

  const getConnectionData = (choice) => {
    const data = {
      username,
      password,
      host: address,
      port,
      schema,
      databaseName: DBName,
      engine,
      connectionProperties,
      discoveredHost: address,
      desiredHost,
      desiredPort: parseInt(desiredPort)
    };
    if ( engine === NTFS) {
      data.domainName = ntfsHost;
      data.directoryNames = ntfsDirectories;
      data.processNames= ntfsProcessNames
    }
    if(choice !== 'test') {
      data.aliasName = aliasName;
    }
  
    if (connectionProperties !== "" && instance !== "") {
      data.connectionProperties = connectionProperties;
      data.instance = instance;
    }
  
    return data;
  }
  
  function connection(event, choice) {
    VerifyAndSaveConnection(
      event,
      choice,
      setLoader,
      handleValidation,
      setErrors,
      setConnectionMsg,
      dataSourceData,
      props,
      resetFormFields,
      setIsDSSaved,
      getConnectionData,
      engine
    );
  }

  const handleDisableSaveButton = () => {
    if (isCloudSQLSystem(engine) && !isConfigurationDone && !isEditMode) {
      return true;
    } else if (isEditMode && !isDSSaved && !isCloudFileSystem(engine)) {
      return true;
    } else if (
      isCloudFileSystem(engine) &&
      dataSourceData &&
      engine !== ONEDRIVE &&
      engine !== NTFS &&
      isEmpty(dataSourceData.secret1) &&
      cloudSourceData &&
      !isNull(cloudSourceData.group) &&
      cloudSourceData.group.length === 0
    ) {
      return true;
    } else if (
      engine === ONEDRIVE &&
      (isEmpty(dataSourceData.secret1) || isEmpty(dataSourceData.secret3))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloudFileSave = (event) => {
    const dsId = dataSourceData.id;
    const selectedGroups = cloudSourceData.group;
    const oneDrive = cloudSourceData.oneDriveSelected;
    const sharePoint = cloudSourceData.sharePointSelected;
    const groupIds = selectedGroups.map((group) => group.id);
    const payloadObj = {
      groupIds,
      noOfUsers: cloudSourceUsers ? cloudSourceUsers : 0,
    };

    if (engine === ONEDRIVE) {
      payloadObj.oneDrive = oneDrive;
      payloadObj.sharePoint = sharePoint;
    }

    dispatch({ type: GROUPS_SAVE_API, payload: {id: dsId, data: payloadObj} });
    props.toggleDrawer(false, event);
  };

  const handleSaveActions = (event) => {
    if (isEditMode) {
      if (!isCloudFileSystem(engine) || engine === NTFS) {
        connection("save")
      } else {
        handleCloudFileSave(event)
      }
    } else {
      handleSave(event);
    }
  }

  //To change the forms based on the source type
  const handleChangeForm = () => {
    if (engine === ONEDRIVE) {
      return (
        <OneDriveDataSourceForm
          isEditMode={isEditMode}
          engine={engine}
          error={error}
          removeErrorByKey={removeErrorByKey}
          handleNumberInput={handleNumberInput}
          handleConnect={handleConnect}
          dataSourceData={dataSourceData}
          toggleDrawer={toggleDrawer}
        />
      );
    } else if (engine === DROPBOX) {
      return (
        <DropboxDataSourceForm
          isEditMode={isEditMode}
          error={error}
          removeErrorByKey={removeErrorByKey}
        />
      );
    } else if (engine === GOOGLEDRIVE) {
      return (
        <GoogleDriveDataSourceForm
          isEditMode={isEditMode}
          error={error}
          removeErrorByKey={removeErrorByKey}
          handleNumberInput={handleNumberInput}
          handleConnect={handleConnect}
          dataSourceData={dataSourceData}
          toggleDrawer={toggleDrawer}
          saveDataSourceFieldData={saveDataSourceFieldData}
          staticInstructionsOpen={staticInstructionsOpen}
        />
      );
    } else if (engine === NTFS){
      return (
        <NTFSDataSource
        isEditMode={isEditMode}
        error={error}
        removeErrorByKey={removeErrorByKey}
        dataSourceData={dataSourceData}
        toggleDrawer={toggleDrawer}
        saveDataSourceFieldData={saveDataSourceFieldData}
        staticInstructionsOpen={staticInstructionsOpen}
        handleSaveActions={handleSaveActions}
        />
      )
    }else {
      return dataSourceForm();
    }
  };
  

  //To change the buttons based on the source type
  const handleButtons = () => {
    const connectionComponents = {
      DROPBOX: DropBoxConnection,
    };
  
    const Component = connectionComponents[engine]; // Get the component
  
    // Handle GOOGLEDRIVE and NTFS case
    if (engine === GOOGLEDRIVE && isEmpty(dataSourceData.secret1) || engine === NTFS) {
      return null;
    }
  
    // Return component or fallback
    return Component ? (
      <Component
        onHandleConnect={handleConnect}
        dataSourceData={dataSourceData}
        toggleDrawer={toggleDrawer}
      />
    ) : dataSourceButtons(props, isEditMode, engine, connection, handleDisableSaveButton, handleSaveActions);
  };

  const renderApplicationName = () => {
    return (
      <div style={{ padding: "10px 0px" }}>
        <div>Application Name {<span style={{ color: "red" }}>*</span>} </div>
        <FormControl variant="outlined" fullWidth={true}>
          <Autocomplete
            freeSolo
            options={applications}
            value={applicationName}
            onChange={(event, newValue) => {
              setApplicationName(newValue);
              setErrors({});
            }}
            disabled={isEditMode}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                onChange={(event) => {
                  setApplicationName(event.target.value);
                  removeErrorByKey("applicationName");
                }}
              />
            )}
          />
        </FormControl>
        {error && error.applicationName ? (
          <div style={{ color: "red" }}>{error.applicationName}</div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {loader && (
        <img
          src={Page_Loader}
          alt="loading"
          className="loader-data-source"
        ></img>
      )}
      <div className="container">
        <div style={{ padding: "15px 0px" }}>
          <div>Alias Name {<span style={{ color: "red" }}>*</span>}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              required
              value={aliasName}
              onChange={(event) => {
                setAliasName(event.target.value);
                removeErrorByKey("aliasName");
              }}
              id="outlined-required"
              variant="outlined"
              fullWidth={true}
              placeholder="Alias Name"
            />
            {isEditMode ? (
              <SaveButton
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: "20px" }}
                className="min-margin"
                onClick={(event) => {
                  saveAliasName(aliasName, dataSourceData, dispatch, setLoader);
                  setSaveAliasNameEvent(event);
                }}
              >
                Save
              </SaveButton>
            ) : null}
          </div>
          {error && error.aliasName ? (
            <div style={{ color: "red" }}>{error.aliasName}</div>
          ) : null}
        </div>
        {isEditMode ? (
          // Render the component only when isEditMode is true
          isCloudFileSystem(engine) && renderApplicationName()
        ) : (
          // Render the component for all when isEditMode is false
          renderApplicationName()
        )}
        <div style={{ padding: "10px 0px" }}>
          <div>Type{<span style={{ color: "red" }}>*</span>}</div>
          <FormControl variant="outlined" fullWidth={true}>
            <Select
              value={engine}
              onChange={sourceChange}
              disabled={isEditMode}
            >
              {types.map((data) => {
                return (
                  <MenuItem value={data.code}>
                    {isCloudSQLSystem(data.value) ? data.code : data.value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {handleChangeForm()}
        {isCloudSQLSystem(engine) && !isEditMode ? (
          <>
            <LogSourceSelection
              staticInstructionsOpen={staticInstructionsOpen}
              logSourceOpen={logSourceOpen}
              setSelectedLogSourceId={setSelectedLogSourceId}
              selectedLogMode={selectedLogMode}
              setSelectedLogMode={setSelectedLogMode}
              engine={engine}
              iconMap={iconMap}
              isConfigurationDone={isConfigurationDone}
              setIsConfigurationDone={setIsConfigurationDone}
              saveDataSourceFieldData ={saveDataSourceFieldData }
            />
          </>
        ) : null}
      </div>
      <div>
        {handleButtons()}
        {connectionMsg && isEditMode ? (
          <h4
            style={{
              color:
                connectionMsg.indexOf("Successful") > -1 ? "green" : "#ff2251",
              marginTop: "30px",
              whiteSpace: "pre-line",
            }}
          >
            {connectionMsg}
          </h4>
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          onExited={handleExited}
        >
          <Alert onClose={handleClose} severity={cloudDSSaved ? "success" : "error"}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
