import React from 'react';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';

const RadialChart = ({radialData, totalAlerts}) => (
  <ResponsiveContainer width={200} height={180} marginTop={-75} marginLeft={-45}>
    <RadialBarChart 
      cx="50%" 
      cy="50%" 
      innerRadius="50%" 
      outerRadius="80%" 
      barSize={10} 
      data={radialData}
      startAngle={90} 
      endAngle={-270} 
    >
      <RadialBar
        minAngle={15}
        background
        clockWise
        dataKey="value"
      />
      <text 
        x="50%" 
        y="50%" 
        textAnchor="middle" 
        dominantBaseline="middle" 
        className="progress-label"
      >
        {totalAlerts}
      </text>
    </RadialBarChart>
  </ResponsiveContainer>
);

export default RadialChart;
