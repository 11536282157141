import React from 'react';
import Grid from "@material-ui/core/Grid";
import NTFSPlatformChanges from '../NTFS/NTFSPlatformChanges';

const FeaturesAndDetails = ({ classes, tenantSignUpObj, setErrorObj, errorObj, renderFeatures, s3ResponseData }) => {
  return (
    <Grid item md={4} sm={4} className="tenantSignUpGridContainer">
      <div className="tenantSignUpGridChildContainer">
        <h1 className="gridHeaders tenantSignUp" style={{ width: "15rem" }}>
          Select Features & Licenses
        </h1>
        <hr className="pageSeparator" />
        <div className="tenantSignUpContainer">
          <div className={classes.tenenantsignup_select_container}>
            {tenantSignUpObj.featuresData.map((feature, index) =>
              renderFeatures(feature, index)
            )}
          </div>
          <NTFSPlatformChanges
            setErrorObj={setErrorObj}
            errorObj={errorObj}
            s3ResponseData={s3ResponseData}
          />
        </div>
      </div>
    </Grid>
  );
};

export default FeaturesAndDetails;
