import React from 'react';
import HostWithAlerts from './HostWithAlerts';
import SecurityAlerts from './SecurityAlerts';
import { Grid } from 'material-ui-core';
import Visibility from './Visibility';
import Criticality from './Criticality';

const NetSpaceSummary = () => {
    return (
        <div className="summary-parent">
            <div className="netspace-summary-header">Summary</div>
            <div className="summary-grid-container">
                <Grid container spacing={2} xs={12} alignItems="stretch">
                    <HostWithAlerts />
                    <SecurityAlerts />
                    <Visibility />
                    <Criticality />
                </Grid>
            </div>
        </div>
    );
};

export default NetSpaceSummary;