import {
  CLEAR_DATA_SOURCE_DETAILS,
  SET_DATA_SOURCE_DETAILS,
  SET_SOURCE_ERROR_MESSAGE,
  SET_LOG_SOURCE_DETAILS,
  SET_LOG_SOURCE_DATA,
  CLEAR_LOG_SOURCE_DATA,
  SET_SOURCES_LOADER,
  SET_LOG_SOURCE_VERIFIED,
  SET_DATA_SOURCE_SAVED,
  DATA_SOURCE_CONNECTION_MESSAGE,
  CLEAR_SOURCE_SAVED,
  DATASOURCE_NAME_EDIT_SUCCESS,
  SET_LOGSOURCE_SAVED,
  CLEAR_SOURCE_ERROR_MESSAGE,
  CLEAR_CLOUD_SOURCE_FIELDS_DATA,
  SET_CLOUD_SOURCE_FIELDS_DATA,
  CLEAR_DATASOURCE_NAME_EDIT_SUCCESS,
  RESET_GROUPS_SAVE_SUCCESS,
  GROUPS_SAVE_SUCCESSFUL,
  SET_NTFS_HOST,
  SET_NTFS_DIRECTORIES,
  SET_NTFS_PROPOSED,
} from "../constants/constants";

const initialState = {
  dataSourceData: {
    aliasName: "",
    engine: "",
    port: "",
    databaseName: "",
    host: "",
    instance: "",
    applicationName: "",
    domainName: "",
  },
  errorMessage: "",
  logSourceDetails: [],
  logSourceData: {
    engine: "",
    allRegions: [],
    types: [],
    applications: [],
    applicationName: ""
  },
  cloudSourceData: {
    domainName: "",
    group: [],
    allGroups: [],
    limitUsers: false,
    cloudSourceUsers: "",
    noOfUsersError: "",
    oneDriveSelected: false,
    sharePointSelected: true,
  },
  loader: false,
  logSourceVerified: false,
  isLogSourceSaved: false,
  isDataSourceSaved: false,
  dataSourceConnectionMessage: "",
  isAliasNameEdit: false,
  groupSaveSuccessful: false,
  ntfsHost: "",
  ntfsDirectories: [],
  ntfsProcessNames: [],
};

export const CreateSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceData: {
          ...state.dataSourceData,
          aliasName: action.payload.aliasName,
          engine: action.payload.engine,
          port: action.payload.port,
          databaseName: action.payload.databaseName,
          host: action.payload.host,
          instance: action.payload.instance,
          applicationName: action.payload.applicationName,
          domainName: action.payload.domainName,
        },
      };
    }
    case SET_SOURCE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case SET_LOG_SOURCE_DATA: {
      return {
        ...state,
        logSourceData: {
          ...state.logSourceData,
          engine: action.payload.engine,
          allRegions: action.payload.allRegions,
          types: action.payload.types,
          applications: action.payload.applications,
          applicationName: action.payload.applicationName,
        },
      };
    }
    case SET_NTFS_HOST: {
      return {
        ...state,
        ntfsHost: action.payload
      }
    }
    case SET_NTFS_DIRECTORIES: {
      return {
        ...state,
        ntfsDirectories: action.payload
      }
    }
    case SET_NTFS_PROPOSED: {
      return {
        ...state,
        ntfsProcessNames: action.payload
      }
    }
    case SET_CLOUD_SOURCE_FIELDS_DATA: {
      return {
        ...state,
        cloudSourceData: {
          ...state.cloudSourceData,
          domainName: action.payload.domainName,
          group: action.payload.group,
          allGroups: action.payload.allGroups,
          limitUsers: action.payload.limitUsers,
          cloudSourceUsers: action.payload.cloudSourceUsers,
          noOfUsersError: action.payload.noOfUsersError,
          oneDriveSelected: action.payload.oneDriveSelected,
          sharePointSelected: action.payload.sharePointSelected
        },
      };
    }
    case SET_LOG_SOURCE_DETAILS: {
      return {
        ...state,
        logSourceDetails: action.payload,
      };
    }
    case SET_SOURCES_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_LOG_SOURCE_VERIFIED: {
      return {
        ...state,
        logSourceVerified: action.payload,
      };
    }
    case SET_DATA_SOURCE_SAVED: {
      return {
        ...state,
        isDataSourceSaved: action.payload,
      };
    }
    case DATASOURCE_NAME_EDIT_SUCCESS: {
      return {
        ...state,
        isAliasNameEdit: action.payload,
      };
    }
    case DATA_SOURCE_CONNECTION_MESSAGE: {
      return {
        ...state,
        dataSourceConnectionMessage: action.payload,
      };
    }
    case SET_LOGSOURCE_SAVED: {
      return {
        ...state,
        isLogSourceSaved: action.payload,
      };
    }
    case GROUPS_SAVE_SUCCESSFUL: {
      return {
        ...state,
        groupSaveSuccessful: true
      }
    }
    case CLEAR_DATA_SOURCE_DETAILS: {
      return {
        ...state,
        dataSourceData: {
          aliasName: "",
          engine: "",
          port: "",
          databaseName: "",
          host: "",
          instance: "",
          applicationName: "",
          domainName: "",
        },
        errorMessage: "",
        dataSourceConnectionMessage: "",
        ntfsHost: "",
        ntfsDirectories: [],
        ntfsProcessNames: []
      };
    }
    case CLEAR_CLOUD_SOURCE_FIELDS_DATA: {
      return {
        ...state,
        cloudSourceData: {
          domainName: "",
          group: [],
          allGroups: [],
          limitUsers: false,
          cloudSourceUsers: "",
          noOfUsersError: "",
          oneDriveSelected: false,
          sharePointSelected: true,
        },
      };
    }
    case CLEAR_SOURCE_SAVED: {
      return {
        ...state,
        isDataSourceSaved: false,
      };
    }
    case RESET_GROUPS_SAVE_SUCCESS: {
      return {
        ...state,
        groupSaveSuccessful: false
      }
    }
    case CLEAR_LOG_SOURCE_DATA: {
      return {
        ...state,
        logSourceData: {
          engine: "",
          region: "",
          allRegions: [],
          types: [],
          applications: [],
          applicationName: "",
        },
        loader: false,
        errorMessage: "",
        logSourceVerified: false,
        isLogSourceSaved: false,
      };
    }
    case CLEAR_SOURCE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: "",
      };
    }
    case CLEAR_DATASOURCE_NAME_EDIT_SUCCESS: {
      return {
        ...state,
        isAliasNameEdit: false,
      };
    }
    default:
      return state;
  }
};
