import {
  CLEAR_ALL_FIELDS_DATA,
  CLEAR_INSPECT_FILTER_DATA,
  CLEAR_SENSITIVE_DATA_FIELDS,
  INSPECT_CONTENT_ORDER_BY_VALUE,
  INSPECT_CONTENT_SORT_BY_VALUE,
  SET_ALL_FIELDS_DATA,
  SET_CATEGORIES_FILTER_DATA,
  SET_INSPECT_CONTENT_FILTERS,
  SET_INSPECT_CURRENT_PAGE,
  SET_INSPECT_FILTER_DATA,
  SET_INSPECT_PAGE_SIZE,
  SET_LOADER,
  SET_SENSITIVE_DATA_FIELDS,
  SET_SEVERITY_FILTER_DATA,
  SET_SOURCE_NAME,
  SET_TOTAL_FILE_COUNT,
  SET_TOTAL_SENSITIVE_COUNT,
  SET_ALL_NODE_DETAILS,
  SET_TOOL_TIP_DATA,
  CLEAR_TOOL_TIP_DATA,
  CLEAR_ALL_NODE_ID,
  SET_NODES_RESPONSE,
  SET_TAB_VIEW,
  SET_ALL_CATEGORIES,
  SET_FINAL_FILE_TYPE,
} from '../constants/constants';

const initialState = {
  currentPage: 0,
  pageSize: 20,
  sourceName: null,
  sensitiveDataFields: {
    apiSuccess: false,
    data: [],
  },
  allFieldsData: {
    apiSuccess: false,
    data: [],
  },
  totalFileCount: 0,
  sensitiveTotalCount: 0,
  tabView: 0,
  loader: false,
  sortByValue: "",
  orderByValue: "",
  finalFileType: "",
  severityFilter: [],
  categories: [],
  filters:[],
  selectedNodeDetails: {},
  toolTipData: null,
  nodesResponseData : [],
  allCategories: [],
};

export const InspectContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INSPECT_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload
      };
    }
    case SET_INSPECT_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload
      };
    }
    case SET_SENSITIVE_DATA_FIELDS: {
      return {
        ...state,
        sensitiveDataFields: {
          apiSuccess: true,
          data: action.payload,
        },
      };
    }
    case SET_ALL_CATEGORIES: {
      return {
        ...state,
        allCategories: action.payload
      }
    }
    case SET_ALL_FIELDS_DATA: {
      return {
        ...state,
        allFieldsData: {
          apiSuccess: true,
          data: action.payload
        }
      };
    }
    case SET_SOURCE_NAME: {
      return {
        ...state,
        sourceName: action.payload,
      };
    }
    case SET_TOTAL_FILE_COUNT: {
      return {
        ...state,
        totalFileCount: action.payload,
      };
    }
    case SET_TAB_VIEW: {
      return {
        ...state,
        tabView: action.payload
      }
    }
    case SET_TOTAL_SENSITIVE_COUNT: {
      return {
        ...state,
        sensitiveTotalCount: action.payload,
      };
    }
    case SET_FINAL_FILE_TYPE: {
      return {
        ...state,
        finalFileType: action.payload,
      }
    }
    case SET_ALL_NODE_DETAILS: {
      return {
        ...state,
        selectedNodeDetails: action.payload,
      };
    }
    case SET_TOOL_TIP_DATA: {
      return {
        ...state,
        toolTipData: action.payload,
      };
    }
    case INSPECT_CONTENT_SORT_BY_VALUE: {
      return {
        ...state,
        sortByValue: action.payload,
      };
    }
    case INSPECT_CONTENT_ORDER_BY_VALUE: {
      return {
      ...state,
        orderByValue: action.payload,
      };
    }
    case SET_SEVERITY_FILTER_DATA: {
      return {
        ...state,
        severityFilter: action.payload
      };
    }
    case SET_CATEGORIES_FILTER_DATA: {
      return {
        ...state,
        categories: action.payload
      };
    }
    case SET_INSPECT_CONTENT_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case SET_INSPECT_FILTER_DATA: {
      return {
        ...state,
        currentPage: 0,
      };
    }
    case SET_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case CLEAR_SENSITIVE_DATA_FIELDS: {
      return {
        ...state,
        sensitiveDataFields: {
          apiSuccess: false,
          data: [],
        },
      };
    }
    case CLEAR_ALL_NODE_ID: {
      return {
        ...state,
        selectedNodeDetails: {},
      };
    }
    case CLEAR_TOOL_TIP_DATA: {
      return {
        ...state,
        toolTipData: null,
      };
    }
    case CLEAR_ALL_FIELDS_DATA: {
      return {
        ...state,
        allFieldsData: {
          apiSuccess: false,
          data: []
        }
      };
    }
    case CLEAR_INSPECT_FILTER_DATA: {
      return {
        ...state,
        currentPage: 0,
        sortByValue: "",
        orderByValue: "",
        severityFilter: [],
        categories: [],
        filters: [],
      };
    } 
    case SET_NODES_RESPONSE: {
      return {
        ...state,
        nodesResponseData: action.payload
      };
    }
    default:
      return state;
  }
};
