import React from 'react';
import Grid from "@material-ui/core/Grid";
import Input from "../Input";
import SendLink from "@material-ui/core/Button";
import { FIRST_NAME, LAST_NAME, ADMIN_EMAIL } from './tenantSignUpConstants';
import { useDispatch } from 'react-redux';

const TenantAdmin = ({
  classes,
  tenantSignUpObj,
  errorObj,
  setErrorObj,
  resetPasswordIcon,
  resetPasswordHandler,
  passwordToggler,
  resetPasswordMail,
  setresetPasswordMail,
  resetPassword
}) => {
    const dispatch = useDispatch();
  return (
    <Grid item md={4} sm={4} className="tenantSignUpGridContainer">
      <div className="tenantSignUpGridChildContainer">
        <h1 className="gridHeaders tenantSignUp" style={{ width: "12rem" }}>
          Admin Details
        </h1>
        <hr className="pageSeparator" />
        <div className="tenantSignUpContainer">
          <Input
            type="text"
            labelName="First Name"
            labelClassName={classes.label}
            containerName="signUpconatainer platFormSignUp"
            value={tenantSignUpObj.firstName}
            classname={classes.input}
            eventHandler={(value) => dispatch({ type: FIRST_NAME, payload: value })}
          />
          <Input
            type="text"
            labelName="Last Name"
            labelClassName={classes.label}
            value={tenantSignUpObj.lastName}
            containerName="signUpconatainer platFormSignUp"
            classname={classes.input}
            eventHandler={(value) => dispatch({ type: LAST_NAME, payload: value })}
          />
          <Input
            type="text"
            labelName="Email"
            required
            labelClassName={classes.label}
            value={tenantSignUpObj.adminEmail}
            containerName="signUpconatainer platFormSignUp"
            classname={classes.input}
            eventHandler={(value) => {
              dispatch({ type: ADMIN_EMAIL, payload: value });
              setErrorObj((prevError) => ({ ...prevError, email: "" }));
            }}
          />
          {errorObj.email && (
            <div style={{ color: "red", marginTop: "15px", display: "flex", fontSize: "12px" }}>
              {errorObj.email}
            </div>
          )}
          {tenantSignUpObj.platformSubdomainName !== "" && (
            <>
              <div className="editUser_resetPasswordFlex">
                <h3 style={{ marginRight: "4rem" }}>Reset Password</h3>
                <img
                  src={resetPasswordIcon}
                  alt="ResetPassword"
                  onClick={resetPasswordHandler}
                />
              </div>
              <Grid item xs={12} xl={12} className={passwordToggler ? `` : "hide"} style={{ marginTop: "20px" }}>
                <div>Tenant Admin Email:</div>
                <input
                  type="text"
                  value={resetPasswordMail}
                  onChange={(event) => setresetPasswordMail(event.target.value)}
                  className="randomStyle"
                />
                <br />
                <br />
                <SendLink
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={resetPassword}
                  className="min-margin"
                >
                  Send Link
                </SendLink>
              </Grid>
            </>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default TenantAdmin;
