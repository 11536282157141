import React, { useState } from 'react';
import {UPLOAD_NMAP_FILE} from './NetSpaceConstant';
import { useDispatch } from "react-redux";
import { isEmpty } from 'lodash';

const NmapFileUpload = ({runName, networkName}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'text/xml') {
            setError(null);
            handleUpload(file);
        } else {
            setError('Please select a valid XML file.');
        }
    };

    const handleUpload = async (selectedFile) => {
        if (selectedFile) {
            try {
                setUploading(true);
                const runObj = {
                    runName: runName,
                    networkName: networkName,
                  };
                  const formData = new FormData();
                  const nmapFile = selectedFile ? selectedFile : "";
                  formData.append("payload", JSON.stringify(runObj));
                  formData.append("file", nmapFile);
                  dispatch({
                    type: UPLOAD_NMAP_FILE,
                    payload: {
                      formData: formData,
                    },
                  });
            } catch (error) {
                // Handle upload error
                console.error(error);
            } finally {
                setUploading(false);
            }
        }
    };

    return (
        <>
            <input type="file" accept=".xml" onChange={handleFileChange} disabled={isEmpty(runName)}/>
            {error && <p>{error}</p>}
        </>
    );
};

export default NmapFileUpload;