import React, { useEffect, useState } from "react";
import "./RiskGovernance.scss";
import { Button, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ImageTooltip from "../../common/HelpIconWithTooltip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from '@material-ui/lab/Alert';
import {
  RISK_TABLE_VIEW_OPTIONS,
  CLEAR_THREATS,
  CLEAR_VULNERABILITIES,
  CLEAR_MITIGATION,
  RISK_SUCCESS_MESSAGE,
  RISK_SAVE_UNSUCCESSFUL_MESSAGE,
  SET_IS_RISK_CREATED,
} from "./riskPilotConstant";
import RiskPilotBadges from "./RiskPilotBadges";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function RiskPilotTableHeader({
  showRiskPilotForm,
  tableView,
  setTableView,
  data,
}) {
  const [statusData, setStatusData] = useState({});
  const dispatch = useDispatch();
  const totalElements = useSelector(
    (state) => state.riskPilotReducer.totalRowCount
  );
  const isRiskCreated = useSelector((state) => state.riskPilotReducer.isRiskCreated);
  const snackbarMessage =  useSelector(
    (state) => state.riskPilotReducer.snackbarMessage
  );

  const handleCreateNew = () => {
    dispatch({ type: CLEAR_THREATS });
    dispatch({ type: CLEAR_VULNERABILITIES });
    dispatch({ type: CLEAR_MITIGATION });
    showRiskPilotForm(true);
  };

  const riskRegisterFilters = () => (
    <div style={{ width: "30%" }}>
      <FormControl variant="outlined" fullWidth>
        <Select
          value={tableView}
          onChange={(event) => setTableView(event.target.value)}
          className="InputSelectField file-pop-up impact"
          style={{ width: "100%" }}
        >
          {RISK_TABLE_VIEW_OPTIONS.map((option, index) => (
            <MenuItem key={index} value={option.code}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  const TotalCountHeader = () => (
    <div className="riskRegisterFilterContainer">
      <div className="riskRegisterTotalContainer">
        <Typography
          className="riskRegisterTitle"
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Risk List
        </Typography>
        <p style={{ minWidth: "45px" }}>Total :</p>
        <div>{totalElements || 0}</div>
        <ImageTooltip
          description="Total Count of Risk Register List"
          loc="riskPilot"
        />
      </div>
      {riskRegisterFilters()}
    </div>
  );

  const renderCreateRegisterButton = () => (
    <div className="riskRegisterMiniContainer">
      <div className="RRContainer riskPilotTable">
        <Button
          className="createRRButton"
          onClick={handleCreateNew}
        >
          Create
        </Button>
      </div>
    </div>
  );

  const renderCreateRiskRegister = () => (
    <div className="riskPilotHeaderContainer">
      <div className="riskPilotChildContainer">
        <RiskPilotBadges
          tableView={tableView}
        />
      </div>
      <div className="riskRegisterTableSearchContainer">
        {TotalCountHeader()}
        {renderCreateRegisterButton()}
      </div>
    </div>
  );

  const handleSnackBarStatus = () => {
    if (snackbarMessage === RISK_SUCCESS_MESSAGE) {
      return 'success';
    } else if (snackbarMessage === RISK_SAVE_UNSUCCESSFUL_MESSAGE) {
      return 'error';
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: SET_IS_RISK_CREATED, payload: "" });
  };

  return (
    <>
      {renderCreateRiskRegister()}
      <div className="riskSnackBarContainer">
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isRiskCreated === 'success'}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={handleSnackBarStatus()}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
