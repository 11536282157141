// NTFSButtons.js
import React from "react";
import { Button } from "@material-ui/core";
import { ColoredActionButton } from "../../../common/buttons";

function NTFSButtons({
  classes,
  toggleDrawer,
  handleSaveActions,
  handleDownloadActions,
  isEditMode,
  ntfsConfigurationCheck,
}) {
  return (
    <div className="dataSourceButtonsContainer">
      <ColoredActionButton
        className={classes.cancelButtonCustomStyles}
        onClick={(event) => toggleDrawer(false, event)}
      >
        Cancel
      </ColoredActionButton>
      <Button
        type="submit"
        variant="contained"
        disabled={!ntfsConfigurationCheck && !isEditMode}
        color="primary"
        className={classes.saveButtonCustomStyles}
        onClick={(event) => handleSaveActions(event)}
      >
        Save
      </Button>
      {isEditMode && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.downloadButtonCustomStyles}
          onClick={(event) => handleDownloadActions(event)}
        >
          Download Configuration
        </Button>
      )}
    </div>
  );
}

export default NTFSButtons;
